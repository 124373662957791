import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { authActions } from '../redux/slices/auth.slice';
import { RootState, store } from '../redux/store';
import { IInterceptorError } from '../types/@interceptor';
import { EHttpStatus, IResponseError } from '../types/@shared';

class InterceptorError implements IInterceptorError {
  public error: number | string | undefined;
  public message: (string | undefined)[];
  public statusCode?: number | undefined;
  constructor(
    error: number | string | undefined,
    message: (string | undefined)[],
    statusCode: number | undefined
  ) {
    this.error = error;
    this.message = message;
    this.statusCode = statusCode;
  }
}

export const responseInterceptors = (): void => {
  axios.interceptors.response.use(
    (config: AxiosResponse) => {
      return config.data;
    },
    (error: AxiosError<IResponseError>) => {
      if (error.response && error.response.status === EHttpStatus.UNAUTHORIZED) {
        store.dispatch(authActions.logout());
      }
      if (error.response) {
        if (typeof error.response.data === 'object') {
          throw error.response.data;
        }
        throw new InterceptorError(error.response.status, [error.response.statusText], error.response.status);
      }
      throw new InterceptorError('Bad Request', [`${error}`], EHttpStatus.BAD_REQUEST);
    }
  );
};

export const requestInterceptors = () => {
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const {
      auth: { token },
    }: RootState = store.getState();
    token && config.headers && (config.headers['Authorization'] = `Bearer ${token}`);
    return config;
  });
};
