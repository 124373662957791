import { ReactElement, SyntheticEvent, useState, useEffect } from 'react';
import { Box, Button, Typography, FormControl, FormLabel, InputBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../helpers/constans';
import { useDispatch, useSelector } from 'react-redux';
import { associationActions } from '../../redux/slices/association.slice';
import ButtonWithLoading from '../../components/button-with-loading';
import { RootState } from '../../redux/store';

import { styles } from './associations-create.styles';

interface IFormValue {
  name: string;
  twitterId: string;
  instagramId: string;
}

const AssociationsCreate = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState<IFormValue>({
    name: '',
    twitterId: '',
    instagramId: '',
  });
  const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false);
  const isCreated = useSelector((state: RootState) => state.associations.isCreated);

  const handleBack = () => {
    navigate(ROUTES.associations);
  };
  const handleSave = () => {
    setIsLoadingBtn(() => true);
    dispatch(associationActions.putAssociation(formValues));
  };

  const handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = event.target as typeof event.target & {
      name: string;
      value: string;
    };
    setFormValues((prevState) => {
      return {
        ...prevState,
        [target.name]: target.value,
      };
    });
  };
  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isCreated) {
      dispatch(associationActions.isCreated(false));
      setIsLoadingBtn(() => false);
      setFormValues({
        name: '',
        twitterId: '',
        instagramId: '',
      });
      navigate(ROUTES.associations);
    }
  }, [isCreated, navigate, dispatch]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.mainBox}>
        <Box sx={styles.mainTitleBox}>
          <Box>
            <Typography sx={styles.mainTitle}>New Association</Typography>
            <Typography sx={styles.mainSubTitle}>
              Add the actual information about your association
            </Typography>
          </Box>
          <Box sx={styles.buttonBox}>
            <Button sx={styles.backButton} onClick={handleBack}>
              back
            </Button>
            <ButtonWithLoading
              buttonName="save"
              styles={styles.addButton}
              isLoading={isLoadingBtn}
              handler={handleSave}
            />
          </Box>
        </Box>
        <Box sx={styles.mainContentBox}>
          <Box component="form" sx={styles.contentForms} onChange={handleChange} onSubmit={handleSubmit}>
            <Typography sx={styles.contentTitle2}>Association Details</Typography>
            <FormControl>
              <FormLabel sx={styles.formLabel}>Name</FormLabel>
              <InputBase sx={styles.input} name="name" value={formValues.name} />
            </FormControl>
            <FormControl>
              <FormLabel sx={styles.formLabel}>Twitter ID</FormLabel>
              <InputBase sx={styles.input} name="twitterId" value={formValues.twitterId} />
            </FormControl>
            <FormControl>
              <FormLabel sx={styles.formLabel}>Instagram ID</FormLabel>
              <InputBase sx={styles.input} name="instagramId" value={formValues.instagramId} />
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AssociationsCreate;
