import { Fragment, ReactElement, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ROUTES } from '../../helpers/constans';
import { Box, Button, Typography } from '@mui/material';

import { styles } from '../terms/terms.styles';

const Privacy = (): ReactElement => {
  const navigate = useNavigate();

  const handleHome = ({ currentTarget }: SyntheticEvent<HTMLElement>): void => {
    navigate(`${currentTarget.dataset.route?.toLowerCase()}`, { replace: true });
  };

  return (
    <Fragment>
      <Box sx={styles.container}>
        <Button sx={styles.homeLink} data-route={ROUTES.home} onClick={handleHome}>
          <ArrowBackIosIcon /> home
        </Button>

        <Box sx={styles.mainBox}>
          <Box sx={styles.mainTitleBox}>
            <Box>
              <Typography sx={styles.mainTitle}>Privacy policy</Typography>
            </Box>
          </Box>
          <Box sx={styles.contentBox}>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>1.</Typography></Box>
              <Typography sx={styles.mainContentText}>Introduction</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box>
                <Typography sx={styles.number}>1.1</Typography>
              </Box>
              <Typography sx={styles.paragraphNormal}>We are committed to safeguarding the privacy of mintfinity QDOS and XDOS services, hereinafter mintfinity, visitors and service users; in this policy, we explain how we will handle your personal data.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box>
                <Typography sx={styles.number}>1.2</Typography>
              </Box>
              <Typography sx={styles.paragraphNormal}>By using mintfinity and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy. </Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>2.</Typography></Box>
              <Typography sx={styles.mainContentText}>How we use your personal data</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>2.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>In this Section 2 we have set out:</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(a)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>the general categories of personal data that we may process;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(b)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>the purposes for which we may process personal data; and</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(c)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>the legal bases of the processing.</Typography>
              </Box>
            </Box>
            
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>2.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We may process data about your use of mintfinity and services ("usage data"). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our analytics tracking system. This usage data may be processed for the purposes of analysing the use of the website and services. The legal basis for this processing is our legitimate interests, namely monitoring and improving mintfinity and services.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>2.3</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We may process your account data ("account data"). The account data may include your name and email address.  The account data may be processed for the purposes of operating mintfinity, providing our services, ensuring the security of mintfinity and services, maintaining back-ups of our databases and communicating with you. The legal basis for this processing is our legitimate interests, namely the proper administration of mintfinity and QDOS mintfinity limited business.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>2.4</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We may process your information included in your personal profile on mintfinity ("profile data"). The profile data may include your name, address, telephone number, email address, profile pictures, gender, date of birth, relationship status, interests and hobbies, educational details and employment details. The profile data may be processed for the purposes of enabling and monitoring your use of mintfinity and services. The legal basis for this processing is our legitimate interests, namely the proper administration of mintfinity and QDOS mintfinity limited business.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>2.5</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We may process your personal data that are provided in the course of the use of our services ("service data"). The service data may include history of reward currency transactions, history of rewards purchases, transactions where currency is bought or sold, or offers made to buy and sell currency which were not completed, images associated with reading of qr codes. The source of the service data is you. The service data may be processed for the purposes of operating mintfinity.com, providing our services, ensuring the security of mintfinity and services, maintaining back-ups of our databases and communicating with you. The legal basis for this processing is our legitimate interests, namely the proper administration of mintfinity and QDOS mintfinity limited business.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>2.6</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We may process information that you post for publication on mintfinity or through our services ("publication data"). The publication data may be processed for the purposes of enabling such publication and administering mintfinity.com and services. The legal basis for this processing is our legitimate interests, namely the proper administration of mintfinity and QDOS mintfinity limited business.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>2.7</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We may process information relating to transactions, including purchases of goods and services, that you enter into with us and/or through mintfinity ("transaction data"). The transaction data may include your contact details and the transaction details. The transaction data may be processed for the purpose of supplying the purchased goods and services and keeping proper records of those transactions. The legal basis for this processing is our interest in the proper administration of mintfinity and QDOS mintfinity limited business.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>2.8</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We may process information that you provide to us for the purpose of subscribing to our email notifications and/or newsletters ("notification data"). The notification data may be processed for the purposes of sending you the relevant notifications and/or newsletters. The legal basis for this processing is your consent. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>2.9</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We may process information contained in or relating to any communication that you send to us ("correspondence data"). The correspondence data may include the communication content and metadata associated with the communication. Mintfinity will generate the metadata associated with communications made using the website contact forms. The correspondence data may be processed for the purposes of communicating with you and record-keeping. The legal basis for this processing is our legitimate interests, namely the proper administration of mintfinity and QDOS mintfinity limited business.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>2.10</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We may process any of your personal data identified in this policy where necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>2.11</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We may process any of your personal data identified in this policy where necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, or obtaining professional advice. The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risks.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>2.12</Typography></Box>
              <Typography sx={styles.paragraphNormal}>In addition to the specific purposes for which we may process your personal data set out in this Section 2, we may also process any of your personal data where such processing is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>2.13</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Please do not supply any other person's personal data to us, unless we prompt you to do so.</Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>3.</Typography></Box>
              <Typography sx={styles.mainContentText}>Providing your personal data to others</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>3.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We may disclose your personal data to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes, and on the legal bases, set out in this policy.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>3.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We may disclose your personal data to our insurers and/or professional advisers insofar as reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice, or the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>3.3</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Financial transactions relating to mintfinity and services may be handled by our payment services providers, https://stripe.com/gb. We will share transaction data with our payment services providers only to the extent necessary for the purposes of [processing your payments, refunding such payments and dealing with complaints and queries relating to such payments and refunds]. You can find information about the payment services providers' privacy policies and practices at https://stripe.com/gb/privacy.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>3.4</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We may disclose your enquiry data to one or more of those selected third party suppliers of goods and services identified on mintfinity for the purpose of enabling them to contact you so that they can offer, market and sell to you relevant goods and/or services. Each such third party will act as a data controller in relation to the enquiry data that we supply to it; and upon contacting you, each such third party will supply to you a copy of its own privacy policy, which will govern that third party's use of your personal data.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>3.5</Typography></Box>
              <Typography sx={styles.paragraphNormal}>In addition to the specific disclosures of personal data set out in this Section 4, we may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>4.</Typography></Box>
              <Typography sx={styles.mainContentText}>International transfers of your personal data</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>In this Section 4, we provide information about the circumstances in which your personal data may be transferred to countries outside the European Economic Area (EEA).</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We have offices in the UK. The European Commission has made an "adequacy decision" with respect to the data protection laws of each of these countries. Transfers to countries within the EEA will be protected by appropriate safeguards, namely the use of standard data protection clauses adopted or approved by the European Commission, a copy of which can be obtained from https://ico.org.uk/media/1571/model_contract_clauses_international_transfers_of_personal_data.pdf</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.3</Typography></Box>
              <Typography sx={styles.paragraphNormal}>The hosting facilities for mintfinity are situated in the UK. The European Commission has made an "adequacy decision" with respect to the data protection laws of each of these countries. Transfers countries within the EEA will be protected by appropriate safeguards, namely [the use of standard data protection clauses adopted or approved by the European Commission, a copy of which you can obtain from http://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&from=EN</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.4</Typography></Box>
              <Typography sx={styles.paragraphNormal}>You acknowledge that personal data that you submit for publication through mintfinity.com or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others.</Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>5.</Typography></Box>
              <Typography sx={styles.mainContentText}>Retaining and deleting personal data</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>5.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>This Section 5 sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>5.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>5.3</Typography></Box>
              <Typography sx={styles.paragraphNormal}>In some cases it is not possible for us to specify in advance the periods for which your personal data will be retained. In such cases, we will determine the period of retention based on the following criteria:</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Typography sx={styles.paragraphNormal}>the period of retention of your name, email address, postal address, postcode, telephone number will be determined based on consent we have obtained from you when this information as acquired from you. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>5.4</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Notwithstanding the other provisions of this Section 5, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>6.</Typography></Box>
              <Typography sx={styles.mainContentText}>Amendments</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>6.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We may update this policy from time to time by publishing a new version on mintfinity.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>6.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>You should check this page occasionally to ensure you are happy with any changes to this policy.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>6.3</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We may notify you of changes to this policy by email or through the private messaging system on mintfinity.</Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>7.</Typography></Box>
              <Typography sx={styles.mainContentText}>Your rights</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>7.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>In this Section 7, we have summarised the rights that you have under data protection law. Some of the rights are complex, and not all of the details have been included in our summaries. Accordingly, you should read the relevant laws and guidance from the regulatory authorities for a full explanation of these rights.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>7.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Your principal rights under data protection law are:</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(a)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>(a)	the right to access;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(b)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>the right to rectification;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(c)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>the right to erasure;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(d)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>the right to restrict processing;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(e)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>the right to object to processing;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(f)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>the right to data portability;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(g)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>the right to complain to a supervisory authority; and</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(h)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>the right to withdraw consent.</Typography>
              </Box>
            </Box>


            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>7.3</Typography></Box>
              <Typography sx={styles.paragraphNormal}>You have the right to confirmation as to whether or not we process your personal data and, where we do, access to the personal data, together with certain additional information. That additional information includes details of the purposes of the processing, the categories of personal data concerned and the recipients of the personal data. Providing the rights and freedoms of others are not affected, we will supply to you a copy of your personal data. The first copy will be provided free of charge, but additional copies may be subject to a reasonable fee. You can access your personal data by visiting https://xdos.uk/account when logged into mintfinity.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>7.4</Typography></Box>
              <Typography sx={styles.paragraphNormal}>You have the right to have any inaccurate personal data about you rectified and, taking into account the purposes of the processing, to have any incomplete personal data about you completed.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>7.5</Typography></Box>
              <Typography sx={styles.paragraphNormal}>In some circumstances you have the right to the erasure of your personal data without undue delay. Those circumstances include: the personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed; you withdraw consent to consent-based processing; you object to the processing under certain rules of applicable data protection law; the processing is for direct marketing purposes; and the personal data have been unlawfully processed. However, there are exclusions of the right to erasure. The general exclusions include where processing is necessary: for exercising the right of freedom of expression and information; for compliance with a legal obligation; or for the establishment, exercise or defence of legal claims.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>7.6</Typography></Box>
              <Typography sx={styles.paragraphNormal}>In some circumstances you have the right to restrict the processing of your personal data. Those circumstances are: you contest the accuracy of the personal data; processing is unlawful but you oppose erasure; we no longer need the personal data for the purposes of our processing, but you require personal data for the establishment, exercise or defence of legal claims; and you have objected to processing, pending the verification of that objection. Where processing has been restricted on this basis, we may continue to store your personal data. However, we will only otherwise process it: with your consent; for the establishment, exercise or defence of legal claims; for the protection of the rights of another natural or legal person; or for reasons of important public interest.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>7.7</Typography></Box>
              <Typography sx={styles.paragraphNormal}>You have the right to object to our processing of your personal data on grounds relating to your particular situation, but only to the extent that the legal basis for the processing is that the processing is necessary for: the performance of a task carried out in the public interest or in the exercise of any official authority vested in us; or the purposes of the legitimate interests pursued by us or by a third party. If you make such an objection, we will cease to process the personal information unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing is for the establishment, exercise or defence of legal claims.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>7.8</Typography></Box>
              <Typography sx={styles.paragraphNormal}>You have the right to object to our processing of your personal data for direct marketing purposes (including profiling for direct marketing purposes). If you make such an objection, we will cease to process your personal data for this purpose.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>7.9</Typography></Box>
              <Typography sx={styles.paragraphNormal}>You have the right to object to our processing of your personal data for scientific or historical research purposes or statistical purposes on grounds relating to your particular situation, unless the processing is necessary for the performance of a task carried out for reasons of public interest.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>7.10</Typography></Box>
              <Typography sx={styles.paragraphNormal}>To the extent that the legal basis for our processing of your personal data is:</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(a)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>consent; or</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(b)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>that the processing is necessary for the performance of a contract to which you are party or in order to take steps at your request prior to entering into a contract,</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Typography sx={styles.paragraphNormal}>and such processing is carried out by automated means, you have the right to receive your personal data from us in a structured, commonly used and machine-readable format. However, this right does not apply where it would adversely affect the rights and freedoms of others.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>7.11</Typography></Box>
              <Typography sx={styles.paragraphNormal}>If you consider that our processing of your personal information infringes data protection laws, you have a legal right to lodge a complaint with a supervisory authority responsible for data protection. You may do so in the EU member state of your habitual residence, your place of work or the place of the alleged infringement.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>7.12</Typography></Box>
              <Typography sx={styles.paragraphNormal}>To the extent that the legal basis for our processing of your personal information is consent, you have the right to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the withdrawal.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>7.13</Typography></Box>
              <Typography sx={styles.paragraphNormal}>You may exercise any of your rights in relation to your personal data by written notice to us. </Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>8.</Typography></Box>
              <Typography sx={styles.mainContentText}>About cookies</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>8.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>8.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>8.3</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.</Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>9.</Typography></Box>
              <Typography sx={styles.mainContentText}>Cookies that we use</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>9.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We use cookies for the following purposes:</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(a)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>authentication - we use cookies to identify you when you visit mintfinity and as you navigate mintfinity;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(b)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>status - we use cookies to help us to determine if you are logged into mintfinity;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(c)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>personalisation - we use cookies to store information about your preferences and to personalise the website for you;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(d)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>security - we use cookies as an element of the security measures used to protect user accounts, including preventing fraudulent use of login credentials, and to protect mintfinity and services generally;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(e)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>advertising - we use cookies to help us to display advertisements that will be relevant to you;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(f)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>analysis - we use cookies to help us to analyse the use and performance of mintfinity and services;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(g)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>cookie consent - we use cookies to store your preferences in relation to the use of cookies more generally;</Typography>
              </Box>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>10.</Typography></Box>
              <Typography sx={styles.mainContentText}>Cookies used by our service providers</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>10.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Our service providers use cookies and those cookies may be stored on your computer when you visit mintfinity.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>10.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We use Google Analytics to analyse the use of mintfinity. Google Analytics gathers information about website use by means of cookies. The information gathered relating to mintfinity is used to create reports about the use of mintfinity. Google's privacy policy is available at: https://www.google.com/policies/privacy/.</Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>11.</Typography></Box>
              <Typography sx={styles.mainContentText}>Managing cookies</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>11.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(a)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>https://support.google.com/chrome/answer/95647?hl=en (Chrome);</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(b)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences (Firefox);</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(c)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>http://www.opera.com/help/tutorials/security/cookies/ (Opera);</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(d)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies (Internet Explorer);</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(e)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>https://support.apple.com/kb/PH21411 (Safari); and</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(f)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy (Edge).</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>11.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Blocking all cookies will have a negative impact upon the usability of many websites.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>11.3</Typography></Box>
              <Typography sx={styles.paragraphNormal}>If you block cookies, you will not be able to use all the features on mintfinity.com.</Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>12.</Typography></Box>
              <Typography sx={styles.mainContentText}>Our details</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>12.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>This website is owned and operated by QDOS mintfinity limited.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>12.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We are based in England and our office is at 22B Nightingale Road, Southsea, PO5 3JL</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>12.3</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Our principal place of business is at 22B Nightingale Road, Southsea, PO5 3JL</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>12.4</Typography></Box>
              <Typography sx={styles.paragraphNormal}>You can contact us:</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(a)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>by post, to the postal address given above;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(b)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>using xdos.uk contact form;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.number}>(c)</Typography></Box>
                <Typography sx={styles.paragraphNormal}>by email, using the email address published on xdos.uk from time to time.</Typography>
              </Box>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>13.</Typography></Box>
              <Typography sx={styles.mainContentText}>Data protection officer</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>13.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Our data protection officer's contact details are: David Thomas, 22B Nightingale Road, Southsea, PO5 3JL</Typography>
            </Box>
          </Box>
        </Box>
      </Box >
    </Fragment >
  );
};

export default Privacy;
