import {
  sharedFonts,
  sharedColors,
  sharedButton,
  sharedSizes,
  sharedShadow,
} from '../../assets/shared.styles';

export const styles = {
  container: {
    pt: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
  },
  mainBox: {
    padding: '1.3rem 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainTitleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: {
      xs: '0 1.3rem 1.3rem 1.3rem',
      md: '0 1.3rem 0',
    },
    minHeight: '6.25rem',
  },
  mainTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '1.5rem',
    lineHeight: '1.4',
    fontWeight: 500,
    letterSpacing: '0.05rem',
    color: sharedColors.black,
    mb: '0.5rem',
  },
  mainSubTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.6',
    letterSpacing: '0.05rem',
    color: sharedColors.grey,
    mb: '1rem',
  },
  buttonBox: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr',
    },
    gridTemplateRows: '1fr 1fr',
    rowGap: '1rem',
    columnGap: '1rem',
  },
  backButton: { ...sharedButton.back, maxHeight: '2.6rem' },
  addButton: { ...sharedButton.blue, maxHeight: '2.6rem' },

  mainContentBox: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: 'repeat(3, 1fr)',
    },
    gap: '1.5rem',
  },

  contentImage: {
    padding: '1.3rem',
    width: '100%',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: sharedShadow.box,
  },
  contentTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '1.25rem',
    lineHeight: '1.7',
    fontWeight: 700,
    letterSpacing: '0.05rem',
    color: sharedColors.black,
    mb: '1.5rem',
  },
  dropzone: (currentImg: string | undefined, draggableImg: string | undefined, isDragActive: boolean) => {
    return {
      width: '100%',
      height: '14rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.9rem',
      color: sharedColors.softBlack,
      border: `0.1rem dashed ${!isDragActive ? sharedColors.inputBorder : '#2152ff'}`,
      borderRadius: '0.5rem',
      cursor: 'pointer',
      boxShadow: isDragActive ? sharedShadow.dropzone : 'none',
      backgroundImage: currentImg && !draggableImg ? `url(${currentImg})` : `url(${draggableImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    };
  },
  dropzoneText: { maxWidth: '70%' },
  informationBox: {
    gridColumn: { xs: 'auto', md: '2 / span 2' },
    padding: '1.3rem',
    width: '100%',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: sharedShadow.box,
  },
  informationTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '1.15rem',
    lineHeight: '1.375',
    fontWeight: 500,
    color: sharedColors.black,
    mb: '1.5rem',
  },
  informationItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: '0.5rem 0.5rem 0.5rem 1rem',
    mb: '2rem',
    borderRadius: sharedSizes.borderRadiusImg,
    background: 'rgb(248, 249, 250) none repeat scroll 0% 0%',
  },
  informationItemText: {
    fontSize: '0.875rem',
    lineHeight: '1.5',
    letterSpacing: '0.03rem',
    fontWeight: 500,
    color: sharedColors.softBlack,
  },
  downloadQrButton: {
    textDecoration: 'unset',
    padding: '0.5rem 1.6rem',
    borderRadius: sharedSizes.borderRadiusButtonSmall,
    backgroundImage: sharedColors.gradientSuccess,
    color: 'white',
    fontSize: '0.75rem',
    lineHeight: '1.4',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
};
