import { ReactElement, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { organisationActions } from '../../redux/slices/organisation.slice';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../helpers/constans';
import { IFiles } from '../../types/@shared';
import { templateActions } from '../../redux/slices/template.slice';
import TemplateCreateForm from '../../components/template-crate-form';
import { Box, Typography, Button, Divider } from '@mui/material';
import { RootState } from '../../redux/store';
import { ETemplateType } from '../../types/@template';
import { eventActions } from '../../redux/slices/event.slice';
import ButtonWithLoading from '../button-with-loading';

import { styles } from './template-create-section.styles';

interface IProps {
  type: ETemplateType;
}

const TemplateCreateSection = ({ type }: IProps): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState<IFiles>({
    image: '',
  });
  const isCreated = useSelector((state: RootState) => state.template.isCreated);
  const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false);

  useEffect(() => {
    type === ETemplateType.singleQR
      ? dispatch(organisationActions.getOrganisations())
      : dispatch(eventActions.getEvents());
  }, [dispatch, type]);

  useEffect(() => {
    if (isCreated) {
      dispatch(templateActions.isCreated(false));
      setIsLoadingBtn(() => false);
      navigate(ROUTES.templates);
    }
  }, [isCreated, navigate, dispatch]);

  const handleDrop = useCallback((acceptedFiles: any) => {
    setFile((state: any) => {
      return {
        image: acceptedFiles[0],
      };
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive, open, acceptedFiles } = useDropzone({
    noClick: true,
    onDrop: handleDrop,
  });

  const handleCreate = (): void => {
    setIsLoadingBtn(() => true);
    const formData = new FormData();
    for (const key in file) {
      formData.append(key, file[`${key}`]);
    }
    dispatch(templateActions.putTemplate(formData));
  };

  const handleCancel = (): void => {
    navigate(ROUTES.templates);
  };

  return (
    <Box sx={styles.mainBox}>
      <Box sx={styles.mainTitleBox}>
        <Box>
          <Typography sx={styles.mainTitle}>New Template</Typography>
          <Typography sx={styles.mainSubTitle}>Create new template</Typography>
        </Box>
      </Box>
      <Divider sx={styles.divider} />
      <TemplateCreateForm type={type} />
      <Box sx={styles.dropzoneBox}>
        <Typography sx={styles.formLabel}>Images</Typography>
        <Box
          sx={styles.dropzone(acceptedFiles[0] && URL.createObjectURL(acceptedFiles[0]), isDragActive)}
          {...getRootProps()}
          onClick={open}
        >
          <input {...getInputProps()} />
          {!acceptedFiles[0] && (
            <Typography sx={styles.dropzoneText}>
              Drag 'n' drop some files here, or click to select files
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={styles.footerBox}>
        <Button sx={styles.backButton} onClick={handleCancel}>
          cancel
        </Button>
        <ButtonWithLoading
          buttonName="create template"
          styles={styles.addButton}
          isLoading={isLoadingBtn}
          handler={handleCreate}
        />
      </Box>
    </Box>
  );
};

export default TemplateCreateSection;
