import { sharedColors, sharedShadow } from '../../assets/shared.styles';

export const styles = {
  alert: { mb: '1rem' },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(2, 1fr)',
      sm: 'repeat(3, 1fr)',
      lg: 'repeat(4, 1fr)',
    },
    gap: '1.5rem',
  },
  cardBox: {
    p: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: sharedShadow.box,
    cursor: 'pointer',
  },
  cardIcon: {
    fontSize: '2.5rem',
    backgroundClip: 'text',
    backgroundImage: sharedColors.gradientInfo,
    WebkitTextFillColor: 'transparent',
    mb: '0.5rem',
  },
  cardTitle: {
    fontSize: '1rem',
    lineHeight: '1.8rem',
    letterSpacing: '0.02rem',
    textTransform: 'capitalize',
    fontWeight: '700',
    color: sharedColors.black,
  },
  cardSubTitle: {
    fontSize: '0.875rem',
    lineHeight: '1.7rem',
    letterSpacing: '0.02rem',
    textTransform: 'capitalize',
    opacity: 0.8,
    color: sharedColors.black,
  },
};
