import { sharedButton, sharedColors, sharedFonts, sharedInputs } from '../../assets/shared.styles';

export const styles = {
  form: { mb: '2rem', width: '20rem' },
  formControl: { width: '100%' },
  formLabel: {
    mb: '0.5rem',
    ml: '0.3rem',
    fontFamily: sharedFonts.main,
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '1.2',
    letterSpacing: '0.05rem',
    color: sharedColors.black,
  },
  input: [...sharedInputs.main, { marginBottom: '0' }],
  inputErrorLabel: {
    width: '100%',
    position: 'absolute',
    bottom: '-1.3rem',
    right: '0',
    textAlign: 'right',
    fontSize: '0.75rem',
    lineHeight: '1.25',
    letterSpacing: '0.01rem',
    fontWeight: 400,
    color: sharedColors.inputErrorLabel,
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  button: sharedButton.blue,
  footer: {
    display: 'flex',
    justifyContent: 'end',
  },
};
