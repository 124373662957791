import { ReactElement, Fragment, useState } from 'react';
import ThingCreateInformation from '../../components/thing-create-information';
import ThingCreateQr from '../../components/thing-create-qr';
import ThingCreateSave from '../../components/thing-create-save';
import { Box, Stepper, Step, StepLabel } from '@mui/material';

import { styles } from './things-create.styles';
import { IFiles } from '../../types/@shared';

const steps = ['Informations', 'External Key', 'Save'];

const ThingControl = (): ReactElement => {
  const [step, setStep] = useState<number>(0);
  const [thingBuffer, setThingBuffer] = useState<IFiles | undefined>();
  const handleSetThingBuffer = (data: any): void => {
    setThingBuffer((state) => data);
  };

  return (
    <Fragment>
      <Box sx={styles.container}>
        <Box sx={styles.stepperBox}>
          <Stepper alternativeLabel activeStep={step}>
            {steps.map((label) => (
              <Step key={label} sx={styles.step}>
                <StepLabel sx={styles.label}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {step === 0 && (
          <ThingCreateInformation
            setStep={setStep}
            thingBuffer={thingBuffer}
            setThingBuffer={handleSetThingBuffer}
          />
        )}
        {step === 1 && (
          <ThingCreateQr setStep={setStep} thingBuffer={thingBuffer} setThingBuffer={handleSetThingBuffer} />
        )}
        {step === 2 && <ThingCreateSave />}
      </Box>
    </Fragment>
  );
};
export default ThingControl;
