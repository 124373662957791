import { put, call, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { authActions } from '../slices/auth.slice';
import {
  ILoginPayload,
  ILoginResponseOk,
  IRegistrationPayload,
  IRegistrationResponseOk,
} from '../../types/@auth';
import { RootState } from '../store';
import { fetchLogin, fetchRegistration } from '../../services/auth.service';
import { notificationActions } from '../slices/notification.slice';
import { globalActions } from '../slices/global.slice';

function* authLogin({ payload }: PayloadAction<ILoginPayload>) {
  const pendingThingId: string | undefined = yield select((state: RootState) => state.global.pendingThingId);
  if (pendingThingId) payload.pendingThingId = pendingThingId;
  try {
    const fetchResult: ILoginResponseOk = yield call(fetchLogin, payload);
    yield put({ type: authActions['login/ok'].type, payload: fetchResult });
    yield put({ type: globalActions.setPendingThingId.type, payload: undefined });
  } catch (err) {
    yield put({ type: authActions['login/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* authRegistration({ payload }: PayloadAction<IRegistrationPayload>) {
  const pendingThingId: string | undefined = yield select((state: RootState) => state.global.pendingThingId);
  if (pendingThingId) payload.pendingThingId = pendingThingId;
  try {
    const fetchResult: IRegistrationResponseOk = yield call(fetchRegistration, payload);
    yield put({ type: authActions['registration/ok'].type, payload: fetchResult });
    yield put({ type: globalActions.setPendingThingId.type, payload: undefined });
  } catch (err) {
    yield put({ type: authActions['registration/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

export function* watchAuth() {
  yield takeLatest(authActions.login, authLogin);
  yield takeLatest(authActions.registration, authRegistration);
}
