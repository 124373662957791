import { TTemplateGetAllPayload } from './../../types/@template';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInterceptorError } from '../../types/@interceptor';
import {
  ITemplateControl,
  ITemplateGetAllResponseOk,
  ITemplateGetResponseOk,
  ITemplatePutExternalKeyPayload,
  ITemplatePutExternalKeyResponseOk,
  ITemplateState,
  TTemplateGetPayload,
} from '../../types/@template';

const initialState: ITemplateState = {
  all: [],
  control: {
    organisationId: '',
    name: '',
    description: '',
    externalKeyAmount: 1,
  },
  current: undefined,
  isCreated: false,
  isUpdated: false,
  isUpdatedExternalKey: false,
  pages: undefined,
};

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    templateControl: (state: ITemplateState, { payload }: PayloadAction<ITemplateControl>) => {
      return {
        ...state,
        control: payload,
      };
    },
    putTemplate: (state: ITemplateState, action: PayloadAction<FormData>) => {},
    'putTemplate/Ok': (state: ITemplateState, action: PayloadAction) => {},
    'putTemplate/Err': (state: ITemplateState, action: PayloadAction<IInterceptorError>) => {},
    getAllTemplate: (state: ITemplateState, action: PayloadAction<TTemplateGetAllPayload>) => {},
    'getAllTemplate/Ok': (state: ITemplateState, { payload }: PayloadAction<ITemplateGetAllResponseOk>) => {
      return {
        ...state,
        all: payload.data,
        pages: payload.pages,
      };
    },
    'getAllTemplate/Err': (state: ITemplateState, action: PayloadAction<IInterceptorError>) => {},
    getTemplate: (state: ITemplateState, action: PayloadAction<TTemplateGetPayload>) => {},
    'getTemplate/Ok': (state: ITemplateState, { payload }: PayloadAction<ITemplateGetResponseOk>) => {
      return {
        ...state,
        current: payload,
      };
    },
    'getTemplate/Err': (state: ITemplateState, action: PayloadAction<IInterceptorError>) => {},
    putTemplateExternalKey: (
      state: ITemplateState,
      action: PayloadAction<ITemplatePutExternalKeyPayload>
    ) => {},
    'putTemplateExternalKey/Ok': (
      state: ITemplateState,
      action: PayloadAction<ITemplatePutExternalKeyResponseOk>
    ) => {},
    'putTemplateExternalKey/Err': (state: ITemplateState, action: PayloadAction<IInterceptorError>) => {},
    isCreated: (state: ITemplateState, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        isCreated: payload,
      };
    },
    isUpdated: (state: ITemplateState, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        isUpdated: payload,
      };
    },
    isUpdatedExternalKey: (state: ITemplateState, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        isUpdatedExternalKey: payload,
      };
    },
  },
});

export const templateActions = templateSlice.actions;
export const templateReducer = templateSlice.reducer;
