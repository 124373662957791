import { sharedTitle } from '../../assets/shared.styles';

export const styles = {
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: sharedTitle.categoryTitle,
};
