import { sharedSizes, sharedList } from '../../assets/shared.styles';

export const styles = {
  tableContainer: sharedList.tableContainer,
  tableHead: sharedList.tableHead,
  tableBody: sharedList.tableBody,
  tableRow: {
    cursor: 'pointer',
  },
  thingImage: (img: string | ArrayBuffer | null) => {
    return {
      width: '3.7rem',
      height: '3.1rem',
      backgroundImage: `url(${img})`,
      backgroundSize: 'cover',
      borderRadius: sharedSizes.borderRadiusImgTiny,
    };
  },
  paginationBox: sharedList.paginationBox,
};
