import { ReactElement } from 'react';
import { Container, Box, Typography, Button } from '@mui/material';

import { styles } from './error.styles';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../helpers/constans';

const Error = (): ReactElement => {
  const navigate = useNavigate();
  const handleGoHome = (): void => {
    navigate(ROUTES.login);
  };

  return (
    <Container>
      <Box sx={styles.container}>
        <Typography sx={styles.error}>Error 404</Typography>
        <Typography sx={styles.title}>Page not found</Typography>
        <Typography sx={styles.subTitle}>
          We suggest you to go to the homepage while we solve this issue.
        </Typography>
        <Button sx={styles.button} onClick={handleGoHome}>
          go to homepage
        </Button>
      </Box>
    </Container>
  );
};
export default Error;
