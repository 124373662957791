import { ReactElement, SyntheticEvent, useEffect } from 'react';
import { Box, Button, Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { ROUTES } from '../../helpers/constans';
import { useNavigate } from 'react-router-dom';
import { EEventCreateSteps } from '../../types/@event';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { associationActions } from '../../redux/slices/association.slice';
import { eventActions } from '../../redux/slices/event.slice';
import { IAssociation } from '../../types/@association';

import { styles } from './event-create-association.styles';

interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const EventCreateAssociation = ({ setStep }: IProps): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const associationsList: IAssociation[] = useSelector((state: RootState) => state.associations.all);
  const eventAssociation = useSelector((state: RootState) => state.events.eventCreate.association);

  const handleNext = (): void => {
    setStep(() => {
      return EEventCreateSteps.organisation;
    });
  };
  const handleBack = (): void => {
    navigate(ROUTES.events);
  };

  const handleClick = (ev: SyntheticEvent<HTMLElement>) => {
    const {
      dataset: { idx },
    } = ev.currentTarget as typeof ev.currentTarget & {
      dataset: { idx: string };
    };
    dispatch(eventActions.selectedAssociation(associationsList[+idx]));
  };

  useEffect(() => {
    dispatch(associationActions.getAssociation());
  }, [dispatch]);

  return (
    <Box sx={styles.mainBox}>
      <Box sx={styles.titleBox}>Select Association</Box>
      <Box sx={styles.contentBox}>
        <List sx={styles.listBox}>
          {associationsList.map((el, idx) => {
            return (
              <ListItem disablePadding key={el._id}>
                <ListItemButton onClick={handleClick} data-idx={idx}>
                  <ListItemText>{el.name}</ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <Divider orientation="vertical" sx={styles.divider} />
        <Box sx={styles.reflectBox}>{eventAssociation.name}</Box>
      </Box>
      <Box sx={styles.buttonsBox}>
        <Button sx={styles.backButton} onClick={handleBack}>
          back
        </Button>
        <Button sx={styles.buttonNext} onClick={handleNext}>
          next
        </Button>
      </Box>
    </Box>
  );
};

export default EventCreateAssociation;
