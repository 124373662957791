import { ReactElement, useState, SyntheticEvent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography, Divider, FormControl, FormLabel, InputBase } from '@mui/material';
import { eventActions } from '../../redux/slices/event.slice';
import { useDropzone } from 'react-dropzone';
import { EEventCreateSteps } from '../../types/@event';
import { IFiles } from '../../types/@shared';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';

import { styles } from './event-create-form.styles';
import { ROUTES } from '../../helpers/constans';
import ButtonWithLoading from '../button-with-loading';

interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const EventCreateForm = ({ setStep }: IProps): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formValues = useSelector((state: RootState) => state.events.eventCreate.eventForm);
  const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false);
  const isCreated = useSelector((state: RootState) => state.events.isCreated);

  const [fileLogo, setFileLogo] = useState<IFiles>({
    image: '',
  });
  const [fileSponsorLogo, setFileSponsorLogo] = useState<IFiles>({
    image: '',
  });

  const handleDropLogo = useCallback((acceptedFiles: any) => {
    setFileLogo((state: any) => {
      return {
        image: acceptedFiles[0],
      };
    });
  }, []);
  const handleDropSponsorLogo = useCallback((acceptedFiles: any) => {
    setFileSponsorLogo((state: any) => {
      return {
        image: acceptedFiles[0],
      };
    });
  }, []);

  const {
    getRootProps: getRootPropsLogo,
    getInputProps: getInputPropsLogo,
    isDragActive: isDragActiveLogo,
    open: openLogo,
    acceptedFiles: acceptedFilesLogo,
  } = useDropzone({
    noClick: true,
    onDrop: handleDropLogo,
  });

  const {
    getRootProps: getRootPropsSponsorLogo,
    getInputProps: getInputPropsSponsorLogo,
    isDragActive: isDragActiveSponsorLogo,
    open: openSponsorLogo,
    acceptedFiles: acceptedFilesSponsorLogo,
  } = useDropzone({
    noClick: true,
    onDrop: handleDropSponsorLogo,
  });

  const handleChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    const target = event.target as typeof event.target & {
      name: string;
      value: string;
    };
    dispatch(
      eventActions.eventCreateForm({
        ...formValues,
        [target.name]: target.value,
      })
    );
  };

  const handleBack = (): void => {
    setStep(() => {
      return EEventCreateSteps.team;
    });
  };
  const handleSave = (): void => {
    setIsLoadingBtn(() => true);
    const formData = new FormData();
    fileLogo.image && formData.append('images', fileLogo.image, 'logo');
    fileSponsorLogo.image && formData.append('images', fileSponsorLogo.image, 'sponsorLogo');
    dispatch(eventActions.putEvent(formData));
  };

  useEffect(() => {
    if (isCreated) {
      dispatch(eventActions.isCreated(false));
      setIsLoadingBtn(() => false);
      navigate(ROUTES.events);
    }
  }, [isCreated, navigate, dispatch]);

  return (
    <Box sx={styles.mainBox}>
      <Box sx={styles.titleBox}>Add the actual information about your event</Box>
      <Box sx={styles.contentBox}>
        <Box sx={styles.contentImage}>
          <Typography sx={styles.contentTitle}>Logo</Typography>
          <Box
            sx={styles.dropzone(
              acceptedFilesLogo[0] && URL.createObjectURL(acceptedFilesLogo[0]),
              isDragActiveLogo
            )}
            {...getRootPropsLogo()}
            onClick={openLogo}
          >
            <input {...getInputPropsLogo()} />
            {!acceptedFilesLogo[0] && (
              <Typography sx={styles.dropzoneText}>
                Drag 'n' drop some files here, or click to select files
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={styles.contentImage}>
          <Typography sx={styles.contentTitle}>Sponsor logo</Typography>
          <Box
            sx={styles.dropzone(
              acceptedFilesSponsorLogo[0] && URL.createObjectURL(acceptedFilesSponsorLogo[0]),
              isDragActiveSponsorLogo
            )}
            {...getRootPropsSponsorLogo()}
            onClick={openSponsorLogo}
          >
            <input {...getInputPropsSponsorLogo()} />
            {!acceptedFilesSponsorLogo[0] && (
              <Typography sx={styles.dropzoneText}>
                Drag 'n' drop some files here, or click to select files
              </Typography>
            )}
          </Box>
        </Box>
        <Divider orientation="vertical" sx={styles.divider} />
        <Box sx={styles.formBox} component="form" onChange={handleChange}>
          <FormControl sx={styles.form}>
            <FormLabel sx={styles.formLabel}>Name</FormLabel>
            <InputBase sx={styles.input} name="name" value={formValues.name} />
          </FormControl>
          <FormControl sx={styles.form}>
            <FormLabel sx={styles.formLabel}>Slogan</FormLabel>
            <InputBase sx={styles.input} name="slogan" value={formValues.slogan} />
          </FormControl>
          <FormControl sx={styles.form}>
            <FormLabel sx={styles.formLabel}>Twitter ID</FormLabel>
            <InputBase sx={styles.input} name="twitterId" value={formValues.twitterId} />
          </FormControl>
          <FormControl sx={styles.form}>
            <FormLabel sx={styles.formLabel}>Instagram ID</FormLabel>
            <InputBase sx={styles.input} name="instagramId" value={formValues.instagramId} />
          </FormControl>
        </Box>
      </Box>
      <Box sx={styles.buttonsBox}>
        <Button sx={styles.backButton} onClick={handleBack}>
          back
        </Button>
        <ButtonWithLoading
          buttonName="save"
          styles={styles.button}
          isLoading={isLoadingBtn}
          handler={handleSave}
        />
      </Box>
    </Box>
  );
};
export default EventCreateForm;
