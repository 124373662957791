import { Fragment, ReactElement, useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Spinner from '../../components/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { thingActions } from '../../redux/slices/thing.slice';
import { ROUTES, URI_TARGET_PAGE } from '../../helpers/constans';
import { RootState } from '../../redux/store';
import { IImage } from '../../types/@shared';
import ThingControlForm from '../../components/thing-control-form';
import { useDropzone } from 'react-dropzone';
import { IFiles } from '../../types/@shared';
import QRCode from 'qrcode';

import { styles } from './things-control.styles';

const ThingsControl = (): ReactElement => {
  const { id: thingId } = useParams<{ id: string }>();
  const isCurrentThing: string | undefined = useSelector((state: RootState) => state.things.current?._id);
  const images: IImage[] | undefined = useSelector((state: RootState) => state.things.current?.images);
  const isUpdated: boolean = useSelector((state: RootState) => state.things.isUpdated);
  const externalKey: string | undefined = useSelector(
    (state: RootState) => state.things.current?.externalKey
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [addFiles, setAddFiles] = useState<IFiles>({
    image: undefined,
  });
  const [removeFiles, setRemoveFiles] = useState<string[]>([]);
  const [qrDataURL, setQrDataURL] = useState<string>('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    thingId && dispatch(thingActions.getThing(thingId));
  }, [thingId, dispatch]);

  useEffect(() => {
    isUpdated && navigate(ROUTES.things);
    return () => {
      dispatch(thingActions.isUpdated(false));
      dispatch(thingActions.thingControlForm(undefined));
    };
  }, [isUpdated, navigate, dispatch]);

  const generateQr = async (data: string) => {
    const src = await QRCode.toDataURL(`${URI_TARGET_PAGE}/${data}`);
    setQrDataURL(src);
  };
  useEffect(() => {
    externalKey && generateQr(externalKey);
  }, [externalKey]);

  const handleDrop = useCallback(
    (acceptedFiles: any) => {
      setAddFiles((state: any) => {
        return {
          image: acceptedFiles[0],
        };
      });
      images?.[0]?.key && setRemoveFiles(() => [images[0].key]);
    },
    [images]
  );

  const { getRootProps, getInputProps, isDragActive, open, acceptedFiles } = useDropzone({
    noClick: true,
    onDrop: handleDrop,
  });

  const handleBack = (): void => {
    navigate(ROUTES.things);
  };

  const handleSave = (): void => {
    const formData = new FormData();
    addFiles.image && formData.append('image', addFiles.image);
    removeFiles.length && formData.append('removeFiles', JSON.stringify(removeFiles));
    dispatch(thingActions.updateThing(formData));
    setLoading(() => true);
  };

  return (
    <Fragment>
      <Box sx={styles.container}>
        {!isCurrentThing ? (
          <Spinner mt="2rem" />
        ) : (
          <Box sx={styles.mainBox}>
            <Box sx={styles.mainTitleBox}>
              <Box>
                <Typography sx={styles.mainTitle}>Make the changes below</Typography>
                <Typography sx={styles.mainSubTitle}>
                  Add or fix some informations about your thing
                </Typography>
              </Box>
              <Box sx={styles.buttonBox}>
                <Button sx={styles.backButton} onClick={handleBack}>
                  back
                </Button>
                <LoadingButton sx={styles.addButton} onClick={handleSave} loading={isLoading}>
                  save
                </LoadingButton>
              </Box>
            </Box>
            <Box sx={styles.mainContentBox}>
              <Box sx={styles.contentImage}>
                <Typography sx={styles.contentTitle}>Object's image</Typography>
                <Box
                  sx={styles.dropzone(
                    images?.[0]?.path,
                    acceptedFiles[0] && URL.createObjectURL(acceptedFiles[0]),
                    isDragActive
                  )}
                  {...getRootProps()}
                  onClick={open}
                >
                  <input {...getInputProps()} />
                </Box>
              </Box>
              <ThingControlForm />
              <Box sx={styles.informationBox}>
                <Typography sx={styles.informationTitle}>Object's information</Typography>
                <Box sx={styles.informationItem}>
                  <Typography sx={styles.informationItemText}>Download QR Code</Typography>
                  <Box sx={styles.downloadQrButton} component="a" href={qrDataURL} download="qr.png">
                    download
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Fragment>
  );
};
export default ThingsControl;
