import { PDFDocument, PDFImage } from 'pdf-lib';
import QRCode from 'qrcode';
import { IDownloadPDFWhithQRofExternalKeyPayload } from '../types/@pdf';
import { URI_TARGET_PAGE, URI_QDOS } from '../helpers/constans';

export const downloadPDFWhithQRofExternalKey = async (
  items: IDownloadPDFWhithQRofExternalKeyPayload[]
): Promise<void> => {
  const POINTS_PER_MILLIMETER = 2.835;
  const pageSize = 54; //always square
  const marginSize = 2;
  const fileName = 'qr-codes.pdf';

  const pdfDoc = await PDFDocument.create();

  async function* embedQR(arr: IDownloadPDFWhithQRofExternalKeyPayload[]) {
    let i = 0;
    while (i < arr.length) {
      const result: PDFImage[] = [];
      if (arr[i].qdosItemAlternativeId) {
        const qrQdosItemAlternativeId = await QRCode.toDataURL(
          `${URI_QDOS}/${arr[i].qdosItemAlternativeId}`,
          {
            margin: 0,
            width: (pageSize - marginSize * 2) * POINTS_PER_MILLIMETER,
          }
        );
        const imageQdosItemAlternativeId = await pdfDoc.embedPng(qrQdosItemAlternativeId);
        result.push(imageQdosItemAlternativeId);
      }
      const qrExternalKey = await QRCode.toDataURL(`${URI_TARGET_PAGE}/${arr[i].externalKey}`, {
        margin: 0,
        width: (pageSize - marginSize * 2) * POINTS_PER_MILLIMETER,
      });
      const imageExternalKey = await pdfDoc.embedPng(qrExternalKey);
      result.push(imageExternalKey);
      i++;
      yield result;
    }
  }

  for await (let arrQR of embedQR(items)) {
    const [qr1, qr2] = arrQR;
    const pageWidth = qr2
      ? (pageSize * 2 + marginSize) * POINTS_PER_MILLIMETER
      : pageSize * POINTS_PER_MILLIMETER;
    const pageHeight = pageSize * POINTS_PER_MILLIMETER;
    const page = pdfDoc.addPage([pageWidth, pageHeight]);
    page.drawImage(qr1, {
      x: marginSize * POINTS_PER_MILLIMETER,
      y: marginSize * POINTS_PER_MILLIMETER,
    });
    if (qr2) {
      page.drawImage(qr2, {
        x: (pageSize + marginSize) * POINTS_PER_MILLIMETER,
        y: marginSize * POINTS_PER_MILLIMETER,
      });
    }
  }

  const pdfAsBase64 = await pdfDoc.saveAsBase64({ dataUri: true });
  const fakeElement = document.createElement('a');
  fakeElement.setAttribute('href', pdfAsBase64);
  fakeElement.setAttribute('download', fileName);
  fakeElement.style.display = 'none';
  document.body.appendChild(fakeElement);
  fakeElement.click();
  document.body.removeChild(fakeElement);
};
