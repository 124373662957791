import { Fragment, ReactElement, useEffect } from 'react';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Pagination,
  Button,
} from '@mui/material';

import { styles } from './system-event-list.styles';
import Spinner from '../spinner';
import { systemEventActions } from '../../redux/slices/system-event.slice';

const SystemEventList = (): ReactElement => {
  const isLoaded = useSelector((state: RootState) => state.systemEvents.isLoaded);
  const systemEvents = useSelector((state: RootState) => state.systemEvents.all);
  const pages = useSelector((state: RootState) => state.systemEvents.pages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(systemEventActions.getAll());
  }, [dispatch]);

  const handlePagination = (event: any, page: number) => {
    dispatch(systemEventActions.getAll({ page }));
  };

  const handleCheckAll = () => {
    dispatch(systemEventActions.checkAll());
  };
  const handleDeleteAll = () => {
    dispatch(systemEventActions.deleteAll());
  };

  return (
    <Fragment>
      <Box sx={styles.container}>
        {!isLoaded ? (
          <Box sx={styles.spinnerBox}>
            <Spinner />
          </Box>
        ) : (
          <Box sx={styles.mainBox}>
            <Box sx={styles.mainTitleBox}>
              <Box>
                <Typography sx={styles.mainTitle}>System events list</Typography>
              </Box>
              <Box>
                <Button sx={styles.checkAll} onClick={handleCheckAll}>
                  check all
                </Button>
                <Button sx={styles.deleteAll} onClick={handleDeleteAll}>
                  delete all
                </Button>
              </Box>
            </Box>
            <TableContainer sx={styles.tableContainer}>
              <Table>
                <TableHead sx={styles.tableHead}>
                  <TableRow>
                    <TableCell>№</TableCell>
                    <TableCell>type</TableCell>
                    <TableCell>title</TableCell>
                    <TableCell>createdAt</TableCell>
                    <TableCell>isChecked</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={styles.tableBody}>
                  {systemEvents.map((el, idx) => {
                    return (
                      <TableRow key={el._id} data-id={el._id}>
                        <TableCell>{idx + 1}</TableCell>
                        <TableCell>{el.type}</TableCell>
                        <TableCell>{el.title}</TableCell>
                        <TableCell>{new Date(el.createdAt).toLocaleDateString()}</TableCell>
                        <TableCell>{el.isChecked ? 'true' : 'false'}</TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow></TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={styles.paginationBox}>
              <Pagination count={pages} onChange={handlePagination} />
            </Box>
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

export default SystemEventList;
