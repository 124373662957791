import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInterceptorError } from '../../types/@interceptor';
import {
  IOrganisationPayload,
  IOrganisationGetResponseOk,
  IOrganisationPutResponseOk,
  IOrganisationControl,
  TOrganisationGetAllPayload,
  IOrganisationState,
} from '../../types/@organisation';

const initialState: IOrganisationState = {
  all: [],
  control: {
    name: '',
    description: '',
    twitterId: '',
    instagramId: '',

  },
  pages: undefined,
};

const organisationSlice = createSlice({
  name: 'organisations',
  initialState,
  reducers: {
    organisationControl: (state: IOrganisationState, { payload }: PayloadAction<IOrganisationControl>) => {
      return {
        ...state,
        control: payload,
      };
    },
    getOrganisations: (
      state: IOrganisationState, 
      { payload }: PayloadAction<TOrganisationGetAllPayload>,
    ) => {},
    'getOrganisations/ok': (
      state: IOrganisationState,
      { payload }: PayloadAction<IOrganisationGetResponseOk>,
    ): IOrganisationState => {
      return {
        ...state,
        all: payload.data,
        pages: payload.pages,
      };
    },
    'getOrganisations/err': (state: IOrganisationState, action: PayloadAction<IInterceptorError>) => {},
    putOrganisations: (state: IOrganisationState, { payload }: PayloadAction<IOrganisationPayload>) => {},
    'putOrganisations/ok': (
      state: IOrganisationState,
      { payload }: PayloadAction<IOrganisationPutResponseOk>
    ) => {},
    'putOrganisations/err': (state: IOrganisationState, { payload }: PayloadAction<IInterceptorError>) => {},
  },
});

export const organisationActions = organisationSlice.actions;
export const organisationReducer = organisationSlice.reducer;
