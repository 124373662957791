import { ReactElement, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Pagination,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../helpers/constans';
import { associationActions } from '../../redux/slices/association.slice';
import { useDispatch, useSelector } from 'react-redux';

import { styles } from './associations-list.styles';
import { RootState } from '../../redux/store';

const AssociationsList = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { 
    all: associationsList, 
    pages,
  } = useSelector((state: RootState) => state.associations);

  const handleCreateAssociation = () => {
    navigate(ROUTES.associationsCreate);
  };

  useEffect(() => {
    dispatch(associationActions.getAssociation());
  }, [dispatch]);

  const handlePagination = (event: any, page: number) => {
    dispatch(associationActions.getAssociation(page));
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.mainBox}>
        <Box sx={styles.mainTitleBox}>
          <Box>
            <Typography sx={styles.mainTitle}>Association</Typography>
            <Typography sx={styles.mainSubTitle}>Create new Associations</Typography>
          </Box>
          <Button sx={styles.addButton} onClick={handleCreateAssociation}>
            + create association
          </Button>
        </Box>
        <TableContainer sx={styles.tableContainer}>
          <Table>
            <TableHead sx={styles.tableHead}>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>name</TableCell>
                <TableCell>twitter id</TableCell>
                <TableCell>instagram id</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={styles.tableBody}>
              {associationsList.map((el, idx) => {
                return (
                  <TableRow key={el._id} data-id={el._id}>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{el.name}</TableCell>
                    <TableCell>{el.twitterId}</TableCell>
                    <TableCell>{el.instagramId}</TableCell>
                  </TableRow>
                );
              })}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={styles.paginationBox}>
          <Pagination count={pages} onChange={handlePagination} />
        </Box>
      </Box>
    </Box>
  );
};

export default AssociationsList;
