import { Fragment, ReactElement, useRef, useEffect, useState } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { IconButton, Badge } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { systemEventActions } from '../../redux/slices/system-event.slice';
import { RootState } from '../../redux/store';
import { SystemEventSocket } from '../../sockets/system-event.socket';
import { ESystemEventName } from '../../types/@system-event';
import { notificationActions } from '../../redux/slices/notification.slice';

const SystemEventBadge = (): ReactElement => {
  const authToken = useSelector((state: RootState) => state.auth.token);
  const dispatch = useDispatch();
  const [systemEventCount, setSystemEventCount] = useState<undefined | number>();
  const { current: socket } = useRef(new SystemEventSocket());

  useEffect(() => {
    if (authToken) {
      socket.init(authToken);
      socket.addListener(ESystemEventName.systemEventInit, (data: number) => {
        data && setSystemEventCount(() => data);
      });
      socket.addListener(ESystemEventName.systemEventUpdate, (data: any) => {
        data.count && setSystemEventCount(() => data.count);
        dispatch(notificationActions.info({ message: 'New system event!' }));
      });
    }
    return () => {
      socket.disconnect();
    };
  }, [socket, authToken, dispatch]);

  const handleSystemEventCardOpen = () => {
    dispatch(systemEventActions.setSystemEventBadgeCardOpen(true));
  };

  return (
    <Fragment>
      <IconButton size="large" aria-label="system events" color="inherit" onClick={handleSystemEventCardOpen}>
        <Badge badgeContent={systemEventCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
    </Fragment>
  );
};

export default SystemEventBadge;
