import { Fragment, ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../helpers/constans';
import { useDispatch, useSelector } from 'react-redux';
import { templateActions } from '../../redux/slices/template.slice';
import Modal from '../../components/modal';
import TemplateExternalkeyMore from '../../components/template-exteranalkey-more';
import {
  Box,
  Button,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';

import { styles } from './templates-control.styles';
import { RootState } from '../../redux/store';
import Spinner from '../../components/spinner';
import { downloadPDFWhithQRofExternalKey } from '../../services/pdf.service';
import { notificationActions } from '../../redux/slices/notification.slice';
import { LoadingButton } from '@mui/lab';

const TemplateControl = (): ReactElement => {
  const { id: templateId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentTemplate = useSelector((state: RootState) => state.template.current);
  const isUpdatedExternalKey = useSelector((state: RootState) => state.template.isUpdatedExternalKey);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false);

  useEffect(() => {
    templateId && dispatch(templateActions.getTemplate(templateId));
  }, [templateId, dispatch]);

  useEffect(() => {
    if (isUpdatedExternalKey) {
      templateId && dispatch(templateActions.getTemplate(templateId));
      dispatch(templateActions.isUpdatedExternalKey(false));
      setIsLoadingBtn(() => false);
      return;
    }
  }, [isUpdatedExternalKey, templateId, dispatch]);

  const handleBack = (): void => {
    navigate(ROUTES.templates);
  };

  const handleClose = (isLoading?: boolean) => {
    isLoading && setIsLoadingBtn(() => true);
    setIsModalOpen(() => false);
  };

  const handleGenerateMoreExternalKey = () => {
    setIsModalOpen(() => true);
  };

  const handleDownloadQr = async (): Promise<void> => {
    if (currentTemplate) return await downloadPDFWhithQRofExternalKey(currentTemplate.things);
    dispatch(notificationActions.warning({ message: 'currentTemplate is empty' }));
  };

  return (
    <Fragment>
      {!currentTemplate?._id ? (
        <Box sx={styles.spinnerBox}>
          <Spinner />
        </Box>
      ) : (
        <Fragment>
          <Box sx={styles.container}>
            <Box sx={styles.mainBox}>
              <Box sx={styles.mainTitleBox}>
                <Box>
                  <Typography sx={styles.mainTitle}>Make the changes below</Typography>
                  <Typography sx={styles.mainSubTitle}>
                    Add or fix some informations about your thing
                  </Typography>
                </Box>
                <Box sx={styles.buttonBox}>
                  <Button sx={styles.backButton} onClick={handleBack}>
                    back
                  </Button>
                </Box>
              </Box>
              <Box sx={styles.mainContentBox}>
                <Box sx={styles.contentImage}>
                  <Box sx={styles.mainImage(currentTemplate?.image?.path)} />
                </Box>
                <Box sx={styles.contentInfo}>
                  <Typography sx={styles.contentInfoTitle}>{currentTemplate?.name}</Typography>
                  <Typography sx={styles.contentInfoSubTitleSmall}>Description</Typography>
                  <Typography sx={styles.contentInfoDescription}>{currentTemplate?.description}</Typography>
                  <Box sx={styles.informationItem}>
                    <Typography sx={styles.informationItemText}>Download QR Codes</Typography>
                    <Button sx={styles.downloadQrButton} onClick={handleDownloadQr}>
                      download
                    </Button>
                  </Box>
                  <Box sx={styles.informationItem}>
                    <Typography sx={styles.informationItemText}>Generate more externalKey</Typography>
                    {isLoadingBtn ? (
                      <LoadingButton sx={styles.generateExternalKeyButton} loading={isLoadingBtn}>
                        loading
                      </LoadingButton>
                    ) : (
                      <Button sx={styles.generateExternalKeyButton} onClick={handleGenerateMoreExternalKey}>
                        Generate
                      </Button>
                    )}
                  </Box>
                </Box>
                <TableContainer sx={styles.tableContainer}>
                  <Table>
                    <TableHead sx={styles.tableHead}>
                      <TableRow>
                        <TableCell>№</TableCell>
                        <TableCell>status</TableCell>
                        <TableCell>externalKey</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={styles.tableBody}>
                      {currentTemplate.things.map((el, idx) => {
                        return (
                          <TableRow key={el._id} sx={styles.tableRow} data-id={el._id}>
                            <TableCell>{idx + 1}</TableCell>
                            <TableCell>{el.status}</TableCell>
                            <TableCell>{el.externalKey}</TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow></TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
          <Modal
            name="Enter how many external keys you need to add:"
            isOpen={isModalOpen}
            handleClose={() => handleClose()}
          >
            <TemplateExternalkeyMore
              handleClose={(isLoading?: boolean) => handleClose(isLoading)}
              templateId={currentTemplate._id}
            />
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};
export default TemplateControl;
