import axios from 'axios';
import { URI_THING, URI_THING_EXTERNAL_KEY } from '../helpers/constans';
import {
  IThingGetExternalKeyResponseOk,
  IThingPutPayload,
  IThingPutResponseOk,
    IThingGetResponseOk,
  IThingInformation,
  IThingResolvePendingPayload,
  IThingGetAllResponseOk,
  TThingGetAllPayload,
} from '../types/@thing';

export const fetchThingGetExternalKey = (amount: number): Promise<IThingGetExternalKeyResponseOk> => {
  return axios.get(`${URI_THING_EXTERNAL_KEY}?amount=${amount}`);
};

export const fetchThingPut = (payload: IThingPutPayload): Promise<IThingPutResponseOk> => {
  return axios.post(URI_THING, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const fetchThingGetAll = (page: TThingGetAllPayload): Promise<IThingGetAllResponseOk> => {
  return axios.get(`${URI_THING}?page=${page}`);
};

export const fetchThingGet = (thingId: string): Promise<IThingGetResponseOk> => {
  return axios.get(`${URI_THING}?id=${thingId}`);
};

export const fetchThingUpdate = (payload: IThingInformation | FormData): Promise<any> => {
  return axios.put(`${URI_THING}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const fetchThingResolvePending = ({ pendingThingId }: IThingResolvePendingPayload) => {
  return axios.put(`${URI_THING}/${pendingThingId}`);
};
