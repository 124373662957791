import { sharedColors, sharedShadow } from '../../assets/shared.styles';

export const styles = {
  body: {
    p: '4.5rem',
  },
  popupButton: {
    padding: '1rem',
    width: '10rem',
    height: '10rem',
    fontSize: '1.75rem',
    lineHeight: '1.1rem',
    fontWeight: 700,
    border: `1px solid ${sharedColors.black}`,
    borderRadius: '0.6rem',
    boxShadow: sharedShadow.button,
    color: sharedColors.black,
    '&:first-child': {
      marginRight: '2.5rem',
    },
  },
};
