import {
  sharedSizes,
  sharedColors,
  sharedFonts,
  sharedShadow,
  sharedButton,
} from '../../assets/shared.styles';
export const styles = {
  mainBox: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 2fr',
    },
    columnGap: '1rem',
    rowGap: '2rem',
    padding: '1.3rem',
    width: '80%',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    boxShadow: sharedShadow.box,
  },
  imageBox: {
    justifySelf: 'center',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  qr: {
    marginBottom: '0.5rem',
  },
  downloadButton: {
    border: '1px solid dodgerblue',
    textDecoration: 'unset',
    padding: '0.3rem 1.6rem',
    borderRadius: sharedSizes.borderRadius,
    borderColor: sharedColors.grey,
    color: sharedColors.softBlack,
  },
  contentTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '0.875rem',
    lineHeight: '1.7',
    fontWeight: 500,
    letterSpacing: '0.05rem',
    color: sharedColors.softBlack,
    mb: '1.5rem',
  },
  copyBox: {
    display: 'grid',
    gridTemplateColumns: '2fr 0.5fr',
    columnGap: '1rem',
  },
  copyLink: {
    fontSize: '0.8rem',
    padding: '0.2rem 0.5rem',
    backgroundColor: 'rgb(233, 236, 239)',
    border: '0.0625rem solid rgb(210, 214, 218)',
    borderRadius: '0.5rem',
  },
  copyButton: {},

  buttonsBox: {
    gridColumn: {
      xs: '1 / 2',
      md: '1 / 3',
    },
    display: 'flex',
    justifyContent: 'end',
  },
  backButton: { ...sharedButton.back, mr: '1rem' },
  buttonNext: sharedButton.blue,
};
