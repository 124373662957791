import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAssociationPutResponseOk,
  IAssociationState,
  IAssociationPutPayload,
  IAssociationGetResponseOk,
  TAssociationGetAllPayload,
} from '../../types/@association';
import { IInterceptorError } from '../../types/@interceptor';

const initialState: IAssociationState = {
  all: [],
  isLoading: false,
  isCreated: false,
  pages: undefined,
};

const associationSlice = createSlice({
  name: 'associations',
  initialState,
  reducers: {
    getAssociation: (
      state: IAssociationState,
      { payload }: PayloadAction<TAssociationGetAllPayload>,
    ) => {},
    'getAssociation/ok': (
      state: IAssociationState,
      { payload }: PayloadAction<IAssociationGetResponseOk>
    ) => {
      return {
        ...state,
        all: payload.data,
        pages: payload.pages,
      };
    },
    'getAssociation/err': (state: IAssociationState, action: PayloadAction<IInterceptorError>) => {},
    putAssociation: (state: IAssociationState, { payload }: PayloadAction<IAssociationPutPayload>) => {},
    'putAssociation/ok': (
      state: IAssociationState,
      { payload }: PayloadAction<IAssociationPutResponseOk>
    ) => {},
    'putAssociation/err': (state: IAssociationState, { payload }: PayloadAction<IInterceptorError>) => {},
    isCreated: (state: IAssociationState, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        isCreated: payload,
      };
    },
  },
});

export const associationActions = associationSlice.actions;
export const associationReducer = associationSlice.reducer;
