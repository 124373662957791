import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { createPortal } from 'react-dom';

import { styles } from './portal-wrapper.styles';

interface IProps {
  children: ReactElement;
  onClose: () => void;
}

const PortalWrapper = ({ children, onClose }: IProps): ReactElement => {
  return createPortal(
    <Box sx={styles.wrapper} onClick={onClose}>
      {children}
    </Box>,
    document.body
  );
};
export default PortalWrapper;
