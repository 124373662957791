import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchContactGet, fetchContactPut } from '../../services/contact.service';
import { IContactGetResponseOk, IContactPutResponseOk, IContactPayload } from '../../types/@contact';
import { contactActions } from '../slices/contact.slice';
import { notificationActions } from '../slices/notification.slice';

function* contactGetData() {
  try {
    const fetchResult: IContactGetResponseOk = yield call(fetchContactGet);
    if (fetchResult) {
      yield put({ type: contactActions['getContacts/ok'].type, payload: fetchResult });
    }
  } catch (err) {
    yield put({ type: contactActions['getContacts/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}
function* contactPutData({ payload }: PayloadAction<IContactPayload>) {
  try {
    const fetchResult: IContactPutResponseOk = yield call(fetchContactPut, payload);
    yield put({ type: contactActions['putContacts/ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: contactActions['putContacts/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

export function* watchContact() {
  yield takeLatest(contactActions.getContacts, contactGetData);
  yield takeLatest(contactActions.putContacts, contactPutData);
}
