import { ReactElement, Fragment, useEffect, SyntheticEvent } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { thingActions } from '../../redux/slices/thing.slice';
import { ROUTES } from '../../helpers/constans';
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Pagination,
} from '@mui/material';

import { styles } from './thing-table-xdos.styles'

const ThingTableXdos = (): ReactElement => {
  const { all: things, pages } = useSelector((state: RootState) => state.things);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(thingActions.getAllThings());
  }, [dispatch]);

  const handleClick = ({ currentTarget }: SyntheticEvent<HTMLTableRowElement>): void => {
    navigate(`${ROUTES.things}/${currentTarget.dataset.id}`);
  };

   const handlePagination = (event: any, page: number) => {
    dispatch(thingActions.getAllThings(page));
  };

  return (
    <Fragment>
      <TableContainer sx={styles.tableContainer}>
        <Table>
          <TableHead sx={styles.tableHead}>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>object</TableCell>
              <TableCell>name</TableCell>
              <TableCell>status</TableCell>
              <TableCell>Id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={styles.tableBody}>
            {things.map((el, idx) => {
              return (
                <TableRow
                  key={el._id}
                  sx={styles.tableRow}
                  data-id={el._id}
                  hover={true}
                  onClickCapture={handleClick}
                >
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>
                    <Box sx={styles.thingImage(el.images[0]?.path)} />
                  </TableCell>
                  <TableCell>{el.name}</TableCell>
                  <TableCell>{el.status}</TableCell>
                  <TableCell>{el.externalKey}</TableCell>
                </TableRow>
              );
            })}
            <TableRow></TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={styles.paginationBox}>
        <Pagination count={pages} onChange={handlePagination} />
      </Box>
    </Fragment>
  )
}

export default ThingTableXdos