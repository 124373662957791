import { ReactElement, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, Button } from '@mui/material';
import { IFiles } from '../../types/@shared';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../helpers/constans';
import ThingCreateForm from '../thing-create-form';

import { styles } from './thing-create-information.styles';

interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  thingBuffer: IFiles | undefined;
  setThingBuffer: (data: any) => void;
}

const ThingCreateInformation = ({ setStep, thingBuffer, setThingBuffer }: IProps): ReactElement => {
  const navigate = useNavigate();
  const [file, setFile] = useState<IFiles>({
    image: '',
  });

  const handleDrop = useCallback((acceptedFiles: any) => {
    setFile((state: any) => {
      return {
        image: acceptedFiles[0],
      };
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive, open, acceptedFiles } = useDropzone({
    noClick: true,
    onDrop: handleDrop,
  });

  const handleNext = (): void => {
    setThingBuffer(file);
    setStep(() => {
      return 1;
    });
  };
  const handleBack = (): void => {
    navigate(ROUTES.things);
  };

  return (
    <Box sx={styles.mainBox}>
      <Box sx={styles.imageBox}>
        <Box
          sx={styles.dropzone(acceptedFiles[0] && URL.createObjectURL(acceptedFiles[0]), isDragActive)}
          {...getRootProps()}
          onClick={open}
        >
          <input {...getInputProps()} />
          {!acceptedFiles[0] && (
            <Typography sx={styles.dropzoneText}>
              Drag 'n' drop some files here, or click to select files
            </Typography>
          )}
        </Box>
      </Box>
      <ThingCreateForm />
      <Box sx={styles.buttonsBox}>
        <Button sx={styles.backButton} onClick={handleBack}>
          back
        </Button>
        <Button sx={styles.buttonNext} onClick={handleNext}>
          next
        </Button>
      </Box>
    </Box>
  );
};

export default ThingCreateInformation;
