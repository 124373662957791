import { ReactElement, SyntheticEvent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { persistor, RootState } from '../../../redux/store';
import { useFormik } from 'formik';

import { Box, Typography, InputBase, Button, Switch, FormGroup, FormControlLabel } from '@mui/material';
import { styles } from './login.styles';
import { authActions } from '../../../redux/slices/auth.slice';
import { ROUTES } from '../../../helpers/constans';
import { loginSchema } from '../../../helpers/formik-shemas/auth.schema';

interface IFormValues {
  email: string;
  password: string;
}

const Login = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPersist, setIsPersist] = useState<boolean>(true);
  const authToken: string | undefined = useSelector((state: RootState) => state.auth.token);
  const formik = useFormik<IFormValues>({
    initialValues: { email: '', password: '' },
    validationSchema: loginSchema,
    validateOnChange: false,
    onSubmit: () => {},
  });

  useEffect((): void => {
    if (authToken) {
      navigate(ROUTES.home);
    }
  }, [authToken, navigate]);

  const handleChangeIsPersist = (event: SyntheticEvent<HTMLInputElement>): void => {
    const target = event.target as typeof event.target & {
      checked: boolean;
    };
    setIsPersist((state: boolean) => target.checked);
  };

  const handleSignIn = (event: any): void => {
    event.preventDefault();
    !isPersist && persistor.pause();
    dispatch(authActions.login(formik.values));
    formik.resetForm();
  };
  const handleSignUp = () => {
    navigate(ROUTES.registration);
  };

  return (
    <Box sx={styles.wrapper}>
      <Box
        component="form"
        sx={styles.container}
        onSubmit={handleSignIn}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      >
        <Typography variant="h4" sx={styles.singIn}>
          Sing In
        </Typography>
        <Typography sx={styles.title}>Enter your email and password to sign in</Typography>
        <Box sx={styles.inputBox}>
          <Typography sx={styles.inputLabe}>Email</Typography>
          <InputBase
            sx={styles.input}
            name="email"
            placeholder="Email"
            value={formik.values.email}
            error={Boolean(formik.values.email) && Boolean(formik.errors.email)}
          />
          <Typography sx={styles.inputErrorLabel}>{formik.errors.email}</Typography>
        </Box>
        <Box sx={styles.inputBox}>
          <Typography sx={styles.inputLabe}>Password</Typography>
          <InputBase
            sx={styles.input}
            name="password"
            type="password"
            placeholder="Password"
            value={formik.values.password}
            error={Boolean(formik.values.password) && Boolean(formik.errors.password)}
          />
          <Typography sx={styles.inputErrorLabel}>{formik.errors.password}</Typography>
        </Box>
        <FormGroup sx={styles.switchBox} onChange={handleChangeIsPersist}>
          <FormControlLabel
            sx={styles.switchLabel}
            control={<Switch name="isPersist" checked={isPersist} />}
            label="Remember me"
          />
        </FormGroup>
        <Button sx={styles.button} onClick={handleSignIn}>
          sign in
        </Button>
        <Box sx={styles.signUpBlock}>
          <Typography sx={styles.signUpTitle}>Don't have an account?</Typography>
          <Box component="span" sx={styles.link} onClick={handleSignUp}>
            Sign up
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Login;
