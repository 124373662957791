import axios from 'axios';
import { URI_THING_QDOS } from '../helpers/constans';
import {
  IThingQdosGetAllResponseOk,
  TThingQdosGetAllPostsByItemIdResponseOk,
} from '../types/@thingQdos';

export const fetchThingQdosGetAll = (
  page: number | undefined
): Promise<IThingQdosGetAllResponseOk> => {
  return axios.get(`${URI_THING_QDOS}?page=${page}`);
};

export const fetchPostsByItemId = (
  itemId: string
): Promise<TThingQdosGetAllPostsByItemIdResponseOk> => {
  return axios.get(`${URI_THING_QDOS}/${itemId}`);
};
