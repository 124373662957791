import { sharedList } from '../../assets/shared.styles';

export const styles = {
  tableContainer: sharedList.tableContainer,
  tableHead: sharedList.tableHead,
  tableBody: sharedList.tableBody,
  tableRow: {
    cursor: 'pointer',
  },
  paginationBox: sharedList.paginationBox,
};
