import { call, put, takeLatest, select } from 'redux-saga/effects';
import { RootState } from '../store';
import { PayloadAction } from '@reduxjs/toolkit';
import { fetchEventGet, fetchEventPut } from '../../services/event.service';
import {
  IEventCreate,
  IEventGetResponseOk,
  IEventPutPayload,
  IEventPutResponseOk,
  TEventGetAllPayload,
} from '../../types/@event';
import { eventActions } from '../slices/event.slice';
import { notificationActions } from '../slices/notification.slice';

function* getEvent({ payload }: PayloadAction<TEventGetAllPayload>) {
  try {
    const fetchResult: IEventGetResponseOk = yield call(fetchEventGet, payload);
    yield put({ type: eventActions['getEvents/ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: eventActions['getEvents/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* putEvent({ payload }: PayloadAction<IEventPutPayload>) {
  const eventCreate: IEventCreate = yield select((state: RootState) => state.events.eventCreate);
  if (payload && payload instanceof FormData) {
    payload.append('name', eventCreate.eventForm.name);
    payload.append('slogan', eventCreate.eventForm.slogan);
    payload.append('twitterId', eventCreate.eventForm.twitterId);
    payload.append('instagramId', eventCreate.eventForm.instagramId);
    payload.append('association', JSON.stringify(eventCreate.association));
    payload.append('organisation', JSON.stringify(eventCreate.organisation));
    payload.append('team', JSON.stringify(eventCreate.team));
  }
  try {
    const fetchResult: IEventPutResponseOk = yield call(fetchEventPut, payload);
    yield put({ type: eventActions['putEvent/ok'].type, payload: fetchResult });
    yield put({ type: eventActions.isCreated.type, payload: true });
  } catch (err) {
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

export function* watchEvent() {
  yield takeLatest(eventActions.getEvents, getEvent);
  yield takeLatest(eventActions.putEvent, putEvent);
}
