import axios from 'axios';
import { URI_ORGANISATIONS } from '../helpers/constans';
import {
  IOrganisationGetResponseOk,
  IOrganisationPayload,
  IOrganisationPutResponseOk,
  TOrganisationGetAllPayload,
} from '../types/@organisation';

export const fetchOrganisationGet = (
  page: TOrganisationGetAllPayload
): Promise<IOrganisationGetResponseOk> => {
  return axios.get(`${URI_ORGANISATIONS}?page=${page}`);
};

export const fetchOrganisationPut = (payload: IOrganisationPayload): Promise<IOrganisationPutResponseOk> => {
  return axios.post(URI_ORGANISATIONS, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
