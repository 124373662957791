import {
  sharedTitle,
  sharedInputs,
  sharedColors,
  sharedSizes,
  sharedFonts,
  sharedShadow,
  sharedButton,
  sharedPage,
} from '../../assets/shared.styles';

export const styles = {
  container: {
    ...sharedPage.container,
  },
  mainBox: (isEditMode: boolean) => {
    const style = {
      padding: '1rem',
      width: { xs: '100%', md: '66.6667%' },
      borderRadius: sharedSizes.borderRadius,
      display: 'grid',
      gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
      columnGap: '1rem',
      rowGap: '1rem',
      bgcolor: sharedColors.bgSecondary,
    };
    return isEditMode ? { ...style, boxShadow: sharedShadow.box, bgcolor: sharedColors.bgWhite } : style;
  },
  mainTitleBox: {
    gridColumnStart: { xs: 'auto', sm: 1 },
    gridColumnEnd: { xs: 'auto', sm: 3 },
    pb: '1.5rem',
  },
  mainTitle: {
    ...sharedTitle.categoryTitle,
  },
  mainSubTitle: {
    ...sharedTitle.categorySubTitle,
  },
  formLabel: {
    mb: '0.5rem',
    ml: '0.3rem',
    fontFamily: sharedFonts.main,
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '1.2',
    letterSpacing: '0.05rem',
    color: sharedColors.grey,
  },
  input: [
    ...sharedInputs.main,
    {
      '.MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: sharedColors.black,
        color: sharedColors.black,
      },
    },
  ],
  buttonsBox: {
    gridColumnStart: { xs: 'auto', sm: 1 },
    gridColumnEnd: { xs: 'auto', sm: 3 },
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonEditSave: (isEditMode: boolean) => {
    if (isEditMode) {
      return sharedButton.blue;
    } else {
      return { ...sharedButton.dark };
    }
  },
  switchBox: {},
  switchLabel: {
    '&.MuiFormControlLabel-root': {
      m: 0,
    },
    '& .MuiFormControlLabel-label': {
      fontFamily: sharedFonts.main,
      fontSize: '0.85rem',
      fontWeight: '500',
      letterSpacing: 0.5,
    },
  },
};
