import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IContactGetResponseOk,
  IContactPayload,
  IContactPutResponseOk,
  IContactState,
} from '../../types/@contact';
import { IInterceptorError } from '../../types/@interceptor';

const initialState: IContactState = {
  name: '',
  phone: '',
  twitterId: '',
  instagramId: '',
  isPrivate: true,
};

const contactSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    getContacts: (state: IContactState) => {},
    'getContacts/ok': (
      state: IContactState,
      { payload }: PayloadAction<IContactGetResponseOk>
    ): IContactState => {
      return payload;
    },
    'getContacts/err': (state: IContactState, action: PayloadAction<IInterceptorError>) => {},
    putContacts: (state: IContactState, { payload }: PayloadAction<IContactPayload>) => {},
    'putContacts/ok': (state: IContactState, { payload }: PayloadAction<IContactPutResponseOk>) => {},
    'putContacts/err': (state: IContactState, { payload }: PayloadAction<IInterceptorError>) => {},
  },
});

export const contactActions = contactSlice.actions;
export const contactReducer = contactSlice.reducer;
