import { sharedColors } from '../../assets/shared.styles';
import { ISpinnerProps } from '../../types/@spinner';

export const styles = {
  spinner: (addParams: ISpinnerProps | undefined | {} = {}) => {
    return [
      {
        color: sharedColors.grey,
      },
      addParams && { ...addParams },
    ];
  },
};
