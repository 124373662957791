import {
  sharedColors,
  sharedShadow,
  sharedSizes,
  sharedButton,
  sharedInputs,
  sharedFonts,
} from '../../assets/shared.styles';

export const styles = {
  mainBox: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 2fr',
    },
    gridTemplateRows: 'auto',
    columnGap: '1rem',
    rowGap: '2rem',
    padding: '1.3rem',
    width: '80%',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    boxShadow: sharedShadow.box,
  },
  stepperBox: { width: '100%', mb: '2rem' },

  imageBox: {
    justifySelf: 'center',
  },
  dropzone: (img: string, isDragActive: boolean) => {
    return {
      width: '14rem',
      height: '14rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.9rem',
      color: sharedColors.softBlack,
      border: `0.1rem dashed ${!isDragActive ? sharedColors.inputBorder : '#2152ff'}`,
      borderRadius: '0.5rem',
      cursor: 'pointer',
      boxShadow: isDragActive ? sharedShadow.dropzone : 'none',
      backgroundImage: `url(${img})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    };
  },
  dropzoneText: { maxWidth: '70%' },
  form: { width: '100%' },
  formLabel: {
    mb: '0.5rem',
    ml: '0.3rem',
    fontFamily: sharedFonts.main,
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '1.2',
    letterSpacing: '0.05rem',
    color: sharedColors.black,
  },
  input: sharedInputs.main,
  select: sharedInputs.select,
  buttonsBox: {
    gridColumn: {
      xs: '1 / 2',
      md: '1 / 3',
    },
    display: 'flex',
    justifyContent: 'end',
  },
  backButton: { ...sharedButton.back, mr: '1rem' },
  buttonNext: sharedButton.dark,
};
