import { Fragment, ReactElement, useState } from 'react';
import { Box } from '@mui/material';
import TemplateCreatePopup from '../../components/template-create-popup';
import TemplateCreateSection from '../../components/template-create-section';
import { ETemplateType } from '../../types/@template';

import { styles } from './templates-create.styles';

const TemplateCreate = (): ReactElement => {
  const [isPopupOpen, setIpPopupOpen] = useState<boolean>(true);
  const [typeOfTemplate, setTypeOfTempalte] = useState<ETemplateType>(0);

  const handleChoice = (value: number) => {
    setTypeOfTempalte(value);
    setIpPopupOpen(!isPopupOpen);
  };

  return (
    <Fragment>
      <Box sx={styles.container}>
        <TemplateCreatePopup isOpen={isPopupOpen} handleClose={handleChoice} />
        {typeOfTemplate === ETemplateType.singleQR && <TemplateCreateSection type={ETemplateType.singleQR} />}
        {typeOfTemplate === ETemplateType.coupleQR && <TemplateCreateSection type={ETemplateType.coupleQR} />}
      </Box>
    </Fragment>
  );
};
export default TemplateCreate;
