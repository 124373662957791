import { sharedPage } from '../../assets/shared.styles';

export const styles = {
  mainBox: {
    position: 'absolute',
    right: '1rem',
    top: '5.5rem',
    bgcolor: 'white',
    padding: '0.5rem',
    borderRadius: '0.75rem',
    boxShadow:
      'rgba(20, 20, 20, 0.15) 0rem 0.5rem 1.625rem -0.25rem, rgba(20, 20, 20, 0.06) 0rem 0.5rem 0.5625rem -0.3125rem',
  },
  placeholder: sharedPage.placeholder,
  placeholderText: {
    color: 'rgba(0, 0, 0, 0.25)',
    textAlign: 'center',
  },
};
