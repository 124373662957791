import {
  sharedTitle,
  sharedColors,
  sharedPage,
  sharedShadow,
  sharedButton,
  sharedFonts,
} from '../../assets/shared.styles';

export const styles = {
  mainBox: {
    ...sharedPage.mainBox,
    width: { xs: '100%', md: '80%' },
    boxShadow: sharedShadow.form,
  },
  mainTitleBox: {
    ...sharedPage.mainTitleBox,
    mb: 0,
  },
  mainTitle: {
    ...sharedTitle.categoryTitle,
    fontSize: '1rem',
    lineHeight: '1.6rem',
  },
  mainSubTitle: {
    ...sharedTitle.categorySubTitle,
    fontSize: '0.875rem',
    color: sharedColors.softBlack,
  },
  divider: sharedPage.divider,
  formLabel: {
    mb: '0.5rem',
    ml: '0.3rem',
    fontFamily: sharedFonts.main,
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '1.2',
    letterSpacing: '0.05rem',
    color: sharedColors.black,
  },
  dropzoneBox: {
    mb: '1rem',
  },
  dropzone: (img: string, isDragActive: boolean) => {
    return {
      width: '100%',
      height: '14rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.9rem',
      color: sharedColors.softBlack,
      border: `0.1rem dashed ${!isDragActive ? sharedColors.inputBorder : '#2152ff'}`,
      borderRadius: '0.5rem',
      cursor: 'pointer',
      boxShadow: isDragActive ? sharedShadow.dropzone : 'none',
      backgroundImage: `url(${img})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    };
  },
  dropzoneText: {
    maxWidth: '70%',
  },
  footerBox: {
    display: 'flex',
    justifyContent: 'end',
  },
  backButton: { ...sharedButton.back, mr: '1.5rem' },
  addButton: { ...sharedButton.blue },
};
