import { ReactElement, SyntheticEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

import { authActions } from '../../../redux/slices/auth.slice';
import { Box, Typography, InputBase, Button, Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { ROUTES } from '../../../helpers/constans';
import { styles } from './registration.styles';
import { useFormik } from 'formik';
import { registrationSchema } from '../../../helpers/formik-shemas/auth.schema';

interface IFormValue {
  name: string;
  email: string;
  password: string;
}

const Registration = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authToken: string | undefined = useSelector((state: RootState) => state.auth.token);
  const formik = useFormik<IFormValue>({
    initialValues: {
      name: '',
      email: '',
      password: '',
    },
    validationSchema: registrationSchema,
    validateOnChange: false,
    onSubmit: () => {},
  });

  useEffect((): void => {
    if (authToken) {
      navigate(ROUTES.home);
    }
  }, [authToken, navigate]);

  const handleSignUp = (event: any): void => {
    event.preventDefault();
    dispatch(authActions.registration(formik.values));
    formik.resetForm();
  };
  const handleSignIn = (event: SyntheticEvent<HTMLSpanElement>): void => {
    event.preventDefault();
    navigate(ROUTES.login);
  };

  return (
    <Box sx={styles.wrapper}>
      <Box
        sx={styles.container}
        component="form"
        onSubmit={handleSignUp}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      >
        <Typography variant="h4" sx={styles.singIn}>
          Sing Up
        </Typography>
        <Typography sx={styles.title}>Enter your email and password to register</Typography>
        <Box sx={styles.inputBox}>
          <Typography sx={styles.inputLabe}>Name</Typography>
          <InputBase
            sx={styles.input}
            id="name"
            type="name"
            placeholder="Name"
            value={formik.values.name}
            error={Boolean(formik.values.name) && Boolean(formik.errors.name)}
          />
          <Typography sx={styles.inputErrorLabel}>{formik.errors.name}</Typography>
        </Box>
        <Box sx={styles.inputBox}>
          <Typography sx={styles.inputLabe}>Email</Typography>
          <InputBase
            sx={styles.input}
            id="email"
            placeholder="Email"
            value={formik.values.email}
            error={Boolean(formik.values.email) && Boolean(formik.errors.email)}
          />
          <Typography sx={styles.inputErrorLabel}>{formik.errors.email}</Typography>
        </Box>
        <Box sx={styles.inputBox}>
          <Typography sx={styles.inputLabe}>Password</Typography>
          <InputBase
            sx={styles.input}
            id="password"
            type="password"
            placeholder="Password"
            value={formik.values.password}
            error={Boolean(formik.values.password) && Boolean(formik.errors.password)}
          />
          <Typography sx={styles.inputErrorLabel}>{formik.errors.password}</Typography>
        </Box>
        <FormControl sx={styles.checkboxContainer}>
          <FormControlLabel
            sx={styles.checkboxLabel}
            label="I agree the Terms and Conditions"
            control={<Checkbox sx={styles.checkbox} defaultChecked />}
          />
        </FormControl>
        <Button sx={styles.button} onClick={handleSignUp}>
          sign up
        </Button>
        <Box sx={styles.signUpBlock}>
          <Typography sx={styles.signUpTitle}>Already have an account?</Typography>
          <Box component="span" sx={styles.link} onClick={handleSignIn}>
            Sign in
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Registration;
