export interface ISystemEventState {
  isSystemEventBadgeCardOpen: boolean;
  all: ISystemEventInformation[];
  pages: number | undefined;
  isLoaded: boolean;
}

export enum ESystemEventType {
  info = 'info',
  urgent = 'urgent',
  admin = 'admin',
}

export enum ESystemEventName {
  systemEventInit = 'system-event:init',
  systemEventUpdate = 'system-event:update',
}

export interface ISystemEventInformation {
  _id: string;
  title: string;
  message: string;
  isChecked: boolean;
  type: ESystemEventType;
  userId: string;
  createdAt: string;
  updatedAt: string;
}
export type TSystemEventGetAllPayload =
  | {
      page: number;
    }
  | undefined;

export interface ISystemEventGetAllResponseOk {
  data: ISystemEventInformation[];
  pages: number;
}
