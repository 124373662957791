import {
  sharedButton,
  sharedColors,
  sharedFonts,
  sharedShadow,
  sharedSizes,
} from '../../assets/shared.styles';

export const styles = {
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '1.3rem',
    width: '80%',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    boxShadow: sharedShadow.box,
  },
  buttonsBox: {
    width: '100%',
    gridColumn: {
      xs: '1 / 2',
      md: '1 / 3',
    },
    display: 'flex',
    justifyContent: 'end',
  },
  backButton: { ...sharedButton.back, mr: '1rem' },
  buttonNext: sharedButton.dark,
  contentBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2 ,1fr) 1rem repeat(2 ,1fr)',
    width: '100%',
    height: '15rem',
    mb: '1rem',
  },
  titleBox: {
    fontFamily: sharedFonts.main,
    fontSize: '1.25rem',
    lineHeight: '1.7',
    fontWeight: 500,
    letterSpacing: '0.05rem',
    color: sharedColors.black,
    mb: '1.5rem',
  },
  listBox: {
    gridColumn: 'span 2',
    overflowY: 'scroll',
    fontSize: '0.875rem',
    lineHeight: 1.5,
    letterSpacing: '0.03rem',
    fontWeight: 500,
    color: sharedColors.black,
  },
  divider: {
    width: '0.08rem',
    opacity: '0.25',
    borderWidth: '0 0 medium',
    borderStyle: 'solid solid none',
    borderColor: 'rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12) currentcolor',
    backgroundColor: 'transparent',
    backgroundImage:
      'linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.5), rgba(52, 71, 103, 0)) !important',
  },
  reflectBox: {
    gridColumn: 'span 2',
    justifySelf: 'center',
    alignSelf: 'center',
    fontFamily: sharedFonts.main,
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: '1.4',
    color: sharedColors.black,
  },
};
