import { Fragment, ReactElement, SyntheticEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../helpers/constans';
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Pagination,
} from '@mui/material';

import { styles } from './thing-table-qdos.styles';
import { thingQdosActions } from '../../redux/slices/thing-qdos';

const ThingTableQdos = (): ReactElement => {
  const { all: things, pages } = useSelector((state: RootState) => state.thingsQdos);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(thingQdosActions.getAllThings());
  }, [dispatch]);

  const handleClick = ({ currentTarget }: SyntheticEvent<HTMLTableRowElement>) => {
    navigate(`${ROUTES.thignsQdosMedia}/${currentTarget.dataset.id}`);
  };

  const handlePagination = (event: any, page: number) => {
    dispatch(thingQdosActions.getAllThings(page));
  };

  return (
    <Fragment>
      <TableContainer sx={styles.tableContainer}>
        <Table>
          <TableHead sx={styles.tableHead}>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>Item Id</TableCell>
              <TableCell>Event Id</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={styles.tableBody}>
            {things.map((el, idx) => {
              return (
                <TableRow
                  key={el._id}
                  sx={styles.tableRow}
                  data-id={el._id}
                  hover={true}
                  onClickCapture={handleClick}
                >
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>{el._id}</TableCell>
                  <TableCell>{el.eventId}</TableCell>
                  <TableCell>{el.status}</TableCell>
                  <TableCell>{el.createdAt}</TableCell>
                </TableRow>
              );
            })}
            <TableRow></TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={styles.paginationBox}>
        <Pagination count={pages} onChange={handlePagination} />
      </Box>
    </Fragment>
  );
};

export default ThingTableQdos;
