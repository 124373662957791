import { sharedSizes, sharedShadow, sharedColors, sharedButton } from '../../assets/shared.styles';
export const styles = {
  container: {
    width: '100vw',
    height: {
      xs: 'auto',
      md: '100vh',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainBox: {
    width: {
      xs: '100%',
      md: '80%',
      lg: '60%',
    },
    display: 'grid',
    columnGap: '1rem',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr',
    },
    p: '1.5rem',
    bgcolor: '#fff',
    boxShadow: sharedShadow.box,
    borderRadius: sharedSizes.borderRadius,
  },
  mainTitle: {
    gridColumn: {
      xs: 1,
      md: '1 / 3',
    },
    fontSize: '1.875rem',
    lineHeight: '1.375',
    fontWeight: '700',
    color: sharedColors.black,
    mb: '2rem',
    textAlign: 'center',
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  targetImage: (img: string | ArrayBuffer | null) => {
    if (img) {
      return {
        width: '100%',
        maxWidth: '19.7rem',
        height: '17.5rem',
        backgroundImage: `url(${img})`,
        backgroundSize: 'cover',
        borderRadius: sharedSizes.borderRadiusImg,
      };
    }
    return {};
  },
  descriptionsBox: {
    mb: '1.5rem',
  },
  title: {
    fontSize: '0.65rem',
    lineHeight: '1.25',
    fontWeight: '700',
    letterSpacing: '0.05rem',
    color: sharedColors.grey,
    textTransform: 'uppercase',
    opacity: 0.7,
    mb: '0.3rem',
  },
  status: {
    mb: '1rem',
  },
  descriptions: {
    mb: '1rem',
    fontSize: '1rem',
    lineHeight: '1.6',
    fontWeight: 500,
    letterSpacing: '0.02rem',
    color: sharedColors.black,
  },
  buttonBecomeOwnerBox: {
    display: 'flex',
    justifyContent: 'center',
    gridColumn: {
      xs: 'span 1',
      md: 'span 2',
    },
    pt: {
      xs: '1rem',
      md: '3rem',
    },
  },
  buttonBecomeOwner: {
    ...sharedButton.green,
    width: {
      xs: '85%',
      md: '50%',
    },
    pt: '1rem',
    pb: '1rem',
  },
};
