import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITargetPayload, ITargetState, ITargetGetResponseOk } from '../../types/@target';

const initialState: ITargetState = {
  isLoaded: false,
};

const targetSlice = createSlice({
  name: 'target',
  initialState,
  reducers: {
    getTarget: (state: ITargetState, { payload }: PayloadAction<ITargetPayload>) => {
      return {
        ...state,
        isLoaded: false,
      };
    },
    'getTarget/ok': (state: ITargetState, { payload }: PayloadAction<ITargetGetResponseOk>) => {
      return {
        isLoaded: true,
        info: payload,
      };
    },
    'getTarget/err': (state: ITargetState) => {
      return {
        isLoaded: true,
      };
    },
  },
});

export const targetActions = targetSlice.actions;
export const targetReducer = targetSlice.reducer;
