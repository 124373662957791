import {
  sharedColors,
  sharedButton,
  sharedFonts,
  sharedSizes,
  sharedShadow,
  sharedPage,
  sharedList,
} from '../../assets/shared.styles';

export const styles = {
  container: {
    ...sharedPage.container,
  },
  mainBox: {
    padding: '1.3rem 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  spinnerBox: { ...sharedPage.spinnerBox, width: '100%', paddingTop: '2.75rem' },
  mainTitleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: {
      xs: '0 1.3rem 1.3rem 1.3rem',
      md: '0 1.3rem 0',
    },
    minHeight: '6.25rem',
  },
  mainTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '1.5rem',
    lineHeight: '1.4',
    fontWeight: 500,
    letterSpacing: '0.05rem',
    color: sharedColors.black,
    mb: '0.5rem',
  },
  mainSubTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.6',
    letterSpacing: '0.05rem',
    color: sharedColors.grey,
    mb: '1rem',
  },
  buttonBox: {},
  backButton: { ...sharedButton.back, maxHeight: '2.6rem' },
  mainContentBox: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr',
    },
    rowGap: {
      xs: '2rem',
      md: '4rem',
    },
    padding: '3rem 1.5rem 0',
    width: '100%',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    boxShadow: sharedShadow.box,
  },
  contentImage: {
    display: 'flex',
    justifyContent: { xs: 'center', md: 'start' },
    alignItems: 'center',
  },
  mainImage: (img: string | undefined) => {
    return {
      width: '80%',
      aspectRatio: '1 / 0.73',
      minHeight: '14rem',
      borderRadius: '0.5rem',
      boxShadow: sharedShadow.image,
      backgroundImage: `url(${img})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    };
  },
  contentInfo: {},
  contentInfoTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '1.875rem',
    lineHeight: 1.375,
    fontWeight: 700,
    textTransform: 'capitalize',
    color: sharedColors.black,
    marginBottom: '2.5rem',
  },
  contentInfoSubTitleSmall: {
    fontFamily: sharedFonts.main,
    fontSize: '0.75rem',
    lineHeight: 1.25,
    fontWeight: 700,
    textTransform: 'capitalize',
    color: sharedColors.black,
    marginBottom: '0.5rem',
  },
  contentInfoDescription: {
    fontFamily: sharedFonts.main,
    fontSize: '1rem',
    lineHeight: 1.6,
    fontWeight: 400,
    textTransform: 'capitalize',
    color: sharedColors.softBlack,
    overflow: 'hidden',
    maxHeight: '5rem',
    paddingLeft: '1rem',
    marginBottom: '1.5rem',
  },
  informationItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: '0.5rem 0.5rem 0.5rem 1rem',
    mb: '1rem',
    borderRadius: sharedSizes.borderRadiusImg,
    background: 'rgb(248, 249, 250) none repeat scroll 0% 0%',
  },
  informationItemText: {
    fontSize: '0.875rem',
    lineHeight: '1.5',
    letterSpacing: '0.03rem',
    fontWeight: 500,
    color: sharedColors.softBlack,
  },
  downloadQrButton: {
    textDecoration: 'unset',
    padding: '0.5rem 1.6rem',
    borderRadius: sharedSizes.borderRadiusButtonSmall,
    backgroundImage: sharedColors.gradientSuccess,
    color: 'white',
    fontSize: '0.75rem',
    lineHeight: '1.4',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  generateExternalKeyButton: {
    textDecoration: 'unset',
    padding: '0.5rem 1.6rem',
    borderRadius: sharedSizes.borderRadiusButtonSmall,
    backgroundImage: sharedColors.gradientInfo,
    color: 'white',
    fontSize: '0.75rem',
    lineHeight: '1.4',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  tableContainer: {
    ...sharedList.tableContainer,
    gridColumn: {
      xs: 'span 1',
      md: 'span 2',
    },
  },
  tableHead: sharedList.tableHead,
  tableBody: sharedList.tableBody,
  tableRow: {},
  pdfContainer: {
    width: '100%',
    height: '40rem',
    gridColumn: {
      xs: 'span 1',
      md: 'span 2',
    },
  },
};
