import { Box } from '@mui/system';
import { ReactElement } from 'react';

import { styles } from './logo.styles';

const Logo = (): ReactElement => {
  return (
    <Box sx={styles.mainBox}>
      <Box sx={styles.textAccent}>X</Box>
      <Box sx={styles.text}>
        DOS
        <sup>&reg;</sup>
      </Box>
    </Box>
  );
};

export default Logo;
