import { ReactElement, Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../helpers/constans';
import { Box, Button, Typography } from '@mui/material';
import ThingTableXdos from '../../components/thing-table-xdos/thing-table-xdos';
import ThingTableQdos from '../../components/thing-table-qdos/thing-table-qdos';

import { styles } from './things-list.styles';

const accentColor = {
  xdos: '#ea0606',
  qdos: '#2185d0'
}

const ThingsList = (): ReactElement => {
  const [isQdosItems, setIsQdosItems] = useState(false)
  const navigate = useNavigate();
  const handleNewObject = (): void => {
    navigate(ROUTES.thingsCreate);
  };

  return (
    <Fragment>
      <Box sx={styles.container}>
        <Box sx={styles.mainBox}>
          <Box sx={styles.mainTitleBox}>
            <Box>
              <Box sx={styles.tabsBox}>
                <Button
                  sx={styles.tabsButton({isSelected:!isQdosItems, accentColor: accentColor.xdos})}
                  disableRipple={true}
                  onClick={() => setIsQdosItems(false)}
                >
                  <span>X</span>dos Objects
                </Button>
                <Button
                  sx={styles.tabsButton({isSelected:isQdosItems, accentColor: accentColor.qdos})}
                  disableRipple={true}
                  onClick={() => setIsQdosItems(true)}
                >
                  <span>Q</span>dos Items
                </Button>
                <Box component="span" sx={styles.tabsIndicator(isQdosItems)}></Box>
              </Box>
              <Typography sx={styles.mainSubTitle}>
                An actual information about your {isQdosItems ? 'items' : 'objects'}
              </Typography>
            </Box>
            {!isQdosItems
              ? <Button sx={styles.addButton} onClick={handleNewObject}>+ new object</Button>
              : <></>
            }
          </Box>
          {isQdosItems
            ? <ThingTableQdos/>
            : <ThingTableXdos />
          }
        </Box>
      </Box>
    </Fragment>
  );
};
export default ThingsList;
