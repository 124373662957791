import { ReactElement, SyntheticEvent } from 'react';
import { Box, Typography, FormControl, FormLabel, InputBase } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { teamActions } from '../../redux/slices/team.slice';
import { RootState } from '../../redux/store';

import { styles } from './team-create-form.styles';

const TeamCreateForm = (): ReactElement => {
  const dispatch = useDispatch();
  const formValues: any = useSelector((state: RootState) => state.teams.control);

  const handleChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    const target = event.target as typeof event.target & {
      name: string;
      value: string;
    };
    dispatch(
      teamActions.teamControl({
        ...formValues,
        [target.name]: target.value,
      })
    );
  };
  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
  };

  return (
    <Box component="form" sx={styles.contentForms} onChange={handleChange} onSubmit={handleSubmit}>
      <Typography sx={styles.contentTitle2}>Team Details</Typography>
      <FormControl>
        <FormLabel sx={styles.formLabel}>Name</FormLabel>
        <InputBase sx={styles.input} name="name" value={formValues.name} />
      </FormControl>
      <FormControl>
        <FormLabel sx={styles.formLabel}>Twitter ID</FormLabel>
        <InputBase sx={styles.input} name="twitterId" value={formValues.twitterId} />
      </FormControl>
      <FormControl>
        <FormLabel sx={styles.formLabel}>Instagram ID</FormLabel>
        <InputBase sx={styles.input} name="instagramId" value={formValues.instagramId} />
      </FormControl>
    </Box>
  );
};

export default TeamCreateForm;
