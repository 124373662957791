import { Theme } from '@mui/material';

export const styles = {
  body: {
    p: '1rem',
  },
  title: {
    m: 0,
    p: '1rem 4rem 1rem 1rem',
    '&:first-letter': { textTransform: 'uppercase' },
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme: Theme) => theme.palette.grey[500],
  },
};
