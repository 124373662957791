import { ReactElement, Fragment, useState, SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Box, FormControl, FormLabel, InputBase, Button } from '@mui/material';

import { styles } from './template-externalkey-more.styles';
import { templateActions } from '../../redux/slices/template.slice';

interface IProps {
  handleClose: (isLoading?: boolean) => void;
  templateId: string;
}

interface IFormValues {
  amount: number | null;
}

const TemplateExternalkeyMore = ({ handleClose, templateId }: IProps): ReactElement => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState<IFormValues>({
    amount: null,
  });

  const handleChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    const target = event.target as typeof event.target & { name: string; value: string };
    setFormValues(() => {
      return {
        ...formValues,
        [target.name]: target.value,
      };
    });
  };

  const handleOk = (): void => {
    if (formValues.amount) {
      dispatch(
        templateActions.putTemplateExternalKey({
          amount: formValues.amount,
          templateId: templateId,
        })
      );
      handleClose(true);
    }
    handleClose();
  };

  return (
    <Fragment>
      <Box component="form" onChange={handleChange}>
        <FormControl sx={styles.form}>
          <FormLabel sx={styles.formLabel}>Amount</FormLabel>
          <InputBase sx={styles.input} name="amount" value={formValues.amount} />
        </FormControl>
        <Box sx={styles.footer}>
          <Button sx={styles.button} onClick={handleOk}>
            ok
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

export default TemplateExternalkeyMore;
