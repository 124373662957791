import axios from 'axios';
import { URI_CONTACTS } from '../helpers/constans';
import { IContactGetResponseOk, IContactPayload, IContactPutResponseOk } from '../types/@contact';

export const fetchContactGet = (): Promise<IContactGetResponseOk> => {
  return axios.get(URI_CONTACTS);
};

export const fetchContactPut = (payload: IContactPayload): Promise<IContactPutResponseOk> => {
  return axios.post(URI_CONTACTS, payload);
};
