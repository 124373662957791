import {  } from './../types/@team';
import axios from 'axios';
import { URI_TEAMS } from '../helpers/constans';
import { 
  ITeamGetResponseOk, 
  TTeamGetAllPayload, 
  ITeamPayload, 
  ITeamPutResponseOk,
} from '../types/@team';

export const fetchTeamGet = (page:TTeamGetAllPayload): Promise<ITeamGetResponseOk> => {
  return axios.get(`${URI_TEAMS}?page=${page}`);
};

export const fetchTeamPut = (payload: ITeamPayload): Promise<ITeamPutResponseOk> => {
  return axios.post(URI_TEAMS, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
