import {
  sharedPage,
  sharedTitle,
  sharedButton,
  sharedList,
} from '../../assets/shared.styles';

export const styles = {
  container: sharedPage.container,
  mainBox: sharedList.mainBox,
  mainTitleBox: sharedList.mainTitleBox,
  mainTitle: sharedTitle.categoryTitle,
  mainSubTitle: sharedTitle.categorySubTitle,
  addButton: sharedButton.dark,
  tableHead: sharedList.tableHead,
  tableBody: sharedList.tableBody,
  tableContainer: sharedList.tableContainer,
  paginationBox: sharedList.paginationBox,
};
