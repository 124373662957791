import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';
import { all } from 'redux-saga/effects';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import { authReducer } from './slices/auth.slice';
import { contactReducer } from './slices/contact.slice';
import { organisationReducer } from './slices/organisation.slice';
import { templateReducer } from './slices/template.slice';
import { thingReducer } from './slices/thing.slice';
import { targetReducer } from './slices/target.slice';
import { notificationReducer } from './slices/notification.slice';
import { eventReducer } from './slices/event.slice';
import { userReducer } from './slices/user.slice';
import { globalReducer } from './slices/global.slice';
import { systemEventReducer } from './slices/system-event.slice';
import { associationReducer } from './slices/association.slice';
import { teamReducer } from './slices/team.slice';
import { thingQdosReducer } from './slices/thing-qdos';

import { watchAuth } from './sagas/auth.saga';
import { watchContact } from './sagas/contact.saga';
import { watchOrganisation } from './sagas/organisation.saga';
import { watchThing } from './sagas/thing.saga';
import { watchTarget } from './sagas/target.saga';
import { watchTemplate } from './sagas/template.saga';
import { watchEvent } from './sagas/event.saga';
import { watchUser } from './sagas/user.saga';
import { watchSystemEvent } from './sagas/system-event.saga';
import { watchAssociation } from './sagas/association.saga';
import { watchTeam } from './sagas/team.saga';
import { watchThingQdos } from './sagas/thing-qdos.saga';

function* rootSaga() {
  yield all([
    watchAuth(),
    watchContact(),
    watchOrganisation(),
    watchThing(),
    watchThingQdos(),
    watchTarget(),
    watchTemplate(),
    watchEvent(),
    watchUser(),
    watchSystemEvent(),
    watchAssociation(),
    watchTeam(),
  ]);
}
const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  global: globalReducer,
  auth: authReducer,
  contacts: contactReducer,
  organisations: organisationReducer,
  template: templateReducer,
  things: thingReducer,
  thingsQdos: thingQdosReducer,
  target: targetReducer,
  notifications: notificationReducer,
  events: eventReducer,
  users: userReducer,
  systemEvents: systemEventReducer,
  associations: associationReducer,
  teams: teamReducer,
});

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    whitelist: ['auth'],
  },
  rootReducer
);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware),
});
const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export { store, persistor, type RootState, type AppDispatch };
