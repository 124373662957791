import {
  sharedButton,
  sharedColors,
  sharedFonts,
  sharedPage,
  sharedShadow,
  sharedSizes,
} from '../../assets/shared.styles';

export const styles = {
  container: {
    pt: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
  },
  mainBox: {
    padding: '1.3rem 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainTitleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 1.3rem 1.3rem 1.5rem',
  },
  mainTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '1.5rem',
    lineHeight: '1.4',
    fontWeight: 500,
    letterSpacing: '0.05rem',
    color: sharedColors.black,
    mb: '0.5rem',
  },
  mainSubTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.6',
    letterSpacing: '0.05rem',
    color: sharedColors.grey,
    mb: '1rem',
  },
  buttonBox: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr',
    },
    gridTemplateRows: '1fr 1fr',
    rowGap: '1rem',
    columnGap: '1rem',
  },
  backButton: { ...sharedButton.back, maxHeight: '2.6rem' },
  addButton: { ...sharedButton.blue, maxHeight: '2.6rem' },
  mainContentBox: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: 'repeat(3, 1fr)',
    },
    gap: '1.5rem',
  },
  contentInformation: {
    padding: '1.3rem',
    width: '100%',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: sharedShadow.box,
  },
  contentSettings: {
    gridColumn: { xs: 'unset', md: '2 / 4' },
    padding: '1.3rem',
    width: '100%',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: sharedShadow.box,
  },
  contentTitle: {
    fontSize: '1rem',
    lineHeight: '1.65',
    letterSpacing: '0.01rem',
    fontWeight: 500,
    color: sharedColors.black,
    mb: '1rem',
  },
  divider: { ...sharedPage.divider, height: '0.06rem' },
  informationItem: {
    display: 'flex',
    mb: '1rem',
  },
  informationItemName: {
    fontSize: '0.875rem',
    lineHeight: '1.5',
    letterSpacing: '0.03rem',
    fontWeight: 700,
    color: sharedColors.black,
    mr: '1rem',
  },
  informationItemText: {
    fontSize: '0.875rem',
    lineHeight: '1.5',
    letterSpacing: '0.03rem',
    fontWeight: 400,
    color: sharedColors.softBlack,
  },
  settingsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: '1.5rem',
  },
  settingsNameBox: {},
  settingsNameTitle: {
    fontSize: '1.25rem',
    lineHeight: '1.325',
    fontWeight: 500,
    color: sharedColors.black,
  },
  settingsNameSubTitle: {
    fontSize: '0.875rem',
    lineHeight: '1.5',
    letterSpacing: '0.03rem',
    fontWeight: 400,
    color: sharedColors.softBlack,
    mr: '1rem',
  },
  settingSelectBox: {},
  switchBox: {},
  switchLabel: {
    '&.MuiFormControlLabel-root': {
      m: 0,
    },
    '& .MuiFormControlLabel-label': {
      fontFamily: sharedFonts.main,
      fontSize: '0.85rem',
      lineHeight: '1.5',
      letterSpacing: '0.03rem',
      fontWeight: 400,
      color: sharedColors.softBlack,
      mr: '0.3rem',
      userSelect: 'none',
    },
  },
  settingButtonBox: {},
  buttonLabel: {
    display: 'inline-block',
    fontSize: '0.75rem',
    lineHeight: '1.5',
    letterSpacing: '0.03rem',
    fontWeight: 400,
    color: sharedColors.info,
    mr: '1rem',
  },
};
