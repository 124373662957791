import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchAssociationGet, fetchAssociationPut } from '../../services/association.service';
import { 
  IAssociationPutPayload, 
  IAssociationPutResponseOk, 
  TAssociationGetAllPayload, 
  IAssociationGetResponseOk,
} from '../../types/@association';
import { associationActions } from '../slices/association.slice';
import { notificationActions } from '../slices/notification.slice';

function* associationGetData({ payload }: PayloadAction<TAssociationGetAllPayload>) {
  try {
    const fetchResult: IAssociationGetResponseOk = yield call(fetchAssociationGet, payload);
    yield put({ type: associationActions['getAssociation/ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: associationActions['getAssociation/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* associationPutData({ payload }: PayloadAction<IAssociationPutPayload>) {
  try {
    const fetchResult: IAssociationPutResponseOk = yield call(fetchAssociationPut, payload);
    yield put({ type: associationActions['putAssociation/ok'].type, payload: fetchResult });
    yield put({ type: associationActions.isCreated.type, payload: true });
  } catch (err) {
    yield put({ type: associationActions['putAssociation/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

export function* watchAssociation() {
  yield takeLatest(associationActions.getAssociation, associationGetData);
  yield takeLatest(associationActions.putAssociation, associationPutData);
}
