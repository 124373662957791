import { sharedSizes, sharedColors } from '../../assets/shared.styles';
export const styles = {
  drawer: (isPermanent: boolean) =>
    isPermanent
      ? {
          minWidth: sharedSizes.drawerSize,
          pt: '1.5rem',
          bgcolor: sharedColors.bgWhite,
          border: 'none',
          textTransform: 'capitalize',
        }
      : {},
  item: (isActive: boolean) => {
    return {
      fontSize: '0.875rem',
      fontWeight: '500',
      letterSpacing: '0.01rem',
      color: sharedColors.softBlack,
      mb: '0.4rem',
      borderBottom: isActive ? '1px solid #344767' : 'none',
      borderTop: isActive ? '1px solid #344767' : 'none',
      backgroundColor: isActive ? '#f8f9fa' : 'none',
    };
  },
  itemIcon: {
    color: sharedColors.black,
    mr: '0.4rem',
  },
};
