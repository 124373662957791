export interface IResponseError {
  statusCode: number;
  message: string[];
  error: string;
}

export interface IImage {
  path: string;
  key: string;
  bucket?: string;
  filename?: string;
  mimetype?: string;
}

export interface IFiles {
  [key: string]: any;
}

export enum EPermission {
  NONE = 0,
  USER = 1,
  PRODUCER = 5,
}

export enum EHttpStatus {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
}
