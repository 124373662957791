import {
  sharedTitle,
  sharedColors,
  sharedSizes,
  sharedShadow,
  sharedButton,
  sharedPage,
  sharedList,
} from '../../assets/shared.styles';

export const styles = {
  container: {
    ...sharedPage.container,
  },
  mainBox: {
    ...sharedPage.mainBox,
  },
  mainTitleBox: {
    ...sharedPage.mainTitleBox,
  },
  mainTitle: {
    ...sharedTitle.categoryTitle,
  },
  mainSubTitle: {
    ...sharedTitle.categorySubTitle,
  },
  addButton: {
    ...sharedButton.dark,
  },
  mainContentList: {
    listStyle: 'none',
    p: '0',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.75rem',
    bgcolor: sharedColors.bgMain,
    p: '1.5rem',
    mb: '1.5rem',
  },
  listItemTitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: '1rem',
  },
  titleText: {
    fontSize: '0.875rem',
    lineHeight: '1.5',
    fontWeight: 500,
    letterSpacing: '0.1rem',
    color: sharedColors.black,
  },
  buttonEdit: { color: sharedColors.black },
  buttonText: { color: sharedColors.black },
  listItemContent: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      sm: '1fr 1fr',
      md: '2fr 1fr',
    },
    columnGap: '1rem',
  },
  contentDesctiption: {
    fontSize: '0.75rem',
    lineHeight: '1.5',
    letterSpacing: '0.05rem',
    color: sharedColors.black,
  },
  contentImageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentImage: (img: string | ArrayBuffer | null) => {
    return {
      width: '5.7rem',
      height: '4.5rem',
      backgroundImage: `url(${img})`,
      backgroundSize: 'cover',
      borderRadius: sharedSizes.borderRadiusImg,
      boxShadow: sharedShadow.image,
    };
  },
  paginationBox: sharedList.paginationBox,
};
