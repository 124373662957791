import {
  sharedPage,
  sharedTitle,
  sharedButton,
  sharedList,
  sharedSizes,
} from '../../assets/shared.styles';

export const styles = {
  container: sharedPage.container,
  mainBox: sharedList.mainBox,
  mainTitleBox: sharedList.mainTitleBox,
  mainTitle: sharedTitle.categoryTitle,
  mainSubTitle: sharedTitle.categorySubTitle,
  addButton: sharedButton.dark,
  tableHead: sharedList.tableHead,
  tableBody: sharedList.tableBody,
  tableContainer: sharedList.tableContainer,
  thingImage: (img: string | ArrayBuffer | null) => {
    return {
      width: '3.7rem',
      height: '3.1rem',
      backgroundImage: `url(${img})`,
      backgroundSize: 'cover',
      borderRadius: sharedSizes.borderRadiusImgTiny,
    };
  },
  paginationBox: sharedList.paginationBox,
};
