import { ReactElement } from 'react';
import { Dialog, Box, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { styles } from './modal.styles';

interface IProps {
  name: string;
  isOpen: boolean;
  handleClose: () => void;
  children?: ReactElement | ReactElement[];
}
const Modal = (props: IProps): ReactElement => {
  const { name, isOpen, handleClose, children } = props;
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Box>
        <DialogTitle sx={styles.title}>
          {name}
          <IconButton aria-label="close" onClick={handleClose} sx={styles.closeIcon}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box sx={styles.body}>{children}</Box>
      </Box>
    </Dialog>
  );
};
export default Modal;
