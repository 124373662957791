import { TTemplateGetAllPayload } from './../types/@template';
import axios from 'axios';
import { URI_TEMPLATE } from '../helpers/constans';
import {
  ITemplateGetAllResponseOk,
  ITemplatePutExternalKeyPayload,
} from '../types/@template';

export const fetchTemplatePut = (payload: FormData): Promise<any> => {
  return axios.post(URI_TEMPLATE, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const fetchTemplateGetAll = (page: TTemplateGetAllPayload): Promise<ITemplateGetAllResponseOk> => {
  return axios.get(`${URI_TEMPLATE}?page=${page}`);
};

export const fetchTemplateGet = (templateId: string): Promise<any> => {
  return axios.get(`${URI_TEMPLATE}?id=${templateId}`);
};

export const fetchTemplatePutExternalKey = (payload: ITemplatePutExternalKeyPayload): Promise<any> => {
  return axios.post(`${URI_TEMPLATE}/${payload.templateId}`, { amount: payload.amount });
};
