import { Fragment, ReactElement } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { systemEventActions } from '../../redux/slices/system-event.slice';

import { styles } from './system-event-badge-card.styles';
import PortalWrapper from '../portal-wrapper';

const SystemEventBadgeCard = (): ReactElement => {
  const dispatch = useDispatch();

  const handleSystemEventCardOpen = () => {
    dispatch(systemEventActions.setSystemEventBadgeCardOpen(false));
  };

  return (
    <Fragment>
      <PortalWrapper onClose={handleSystemEventCardOpen}>
        <Box sx={styles.mainBox}>
          <Box sx={styles.placeholder}></Box>
          <Box sx={styles.placeholderText}>No Data</Box>
        </Box>
      </PortalWrapper>
    </Fragment>
  );
};
export default SystemEventBadgeCard;
