import { IAssociation } from './@association';
import { IOrganisation } from './@organisation';
import { ITeam } from './@team';

export interface IEventState {
  all: IEvent[] | [];
  eventCreate: IEventCreate;
  isLoaded: { organisations: boolean };
  isCreated: boolean;
  pages: number | undefined;
}

export interface IEventCreate {
  association: IAssociation;
  organisation: IOrganisation;
  team: ITeam;
  eventForm: IEventCreateForm;
}

export interface IEvent {
  _id?: string;
  name: string;
  slogan?: string;
  twitterId?: string;
  instagramId?: string;
}

export interface IEventCreateForm {
  [key: string]: string;
}

export interface IEventPutPayload {
  [key: string]: any;
}

export type TEventGetAllPayload = number | undefined;

export interface IEventGetResponseOk {
  data: IEvent[];
  pages: number;
}

export interface IEventPutResponseOk extends IEvent {}

export enum EEventCreateSteps {
  association = 0,
  organisation = 1,
  team = 2,
  event = 3,
}
