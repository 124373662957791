import emptyPNG from './img/empty.png';

export const sharedColors = {
  white: '#fff',
  black: '#344767' /* rgb(52, 71, 103) */,
  softBlack: '#67748e' /* rgb(103, 116, 142) */,
  grey: '#8392ab' /* rgb(131, 146, 171) */,
  light: '#e9ecef',
  info: '#17c1e8',
  inputBorder: '#dee2e6',
  inputColor: '#495057',
  inputErrorLabel: 'rgb(234, 6, 6)',
  bgMain: '#f8f9fa',
  bgWhite: '#fff',
  bgSecondary: '#fbfcfd',
  gradientInfo: 'linear-gradient(310deg, #2152ff, #21d4fd)',
  gradientSecondary: 'linear-gradient(310deg, #627594, #a8b8d8)',
  gradientError: 'linear-gradient(310deg, #ea0606, #ff667c)',
  gradientDark: 'linear-gradient(310deg, #141727, #3a416f)',
  gradientSuccess: 'linear-gradient(310deg, #17ad37, #98ec2d)',
  gradientGray: 'linear-gradient(310deg, #ced4da, #ebeff4)',
  gradientWarning: 'linear-gradient(310deg, #f53939, #fbcf33)',
};
export const sharedFonts = {
  main: 'inherit',
};
export const sharedSizes = {
  drawerSize: '16rem',
  borderRadius: '0.875rem',
  borderRadiusImg: '0.75rem',
  borderRadiusImgTiny: '0.25rem',
  borderRadiusButtonSmall: '0.5rem',
};
export const sharedShadow = {
  box: 'rgba(255, 255, 255, 0.9) 0rem 0rem 0.0625rem 0.0625rem inset, rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem',
  button:
    'rgb(0 0 0 / 11%) 0rem 0.25rem 0.4375rem -0.0625rem, rgb(0 0 0 / 7%) 0rem 0.125rem 0.25rem -0.0625rem',
  image:
    'rgba(20, 20, 20, 0.15) 0rem 0.5rem 1.625rem -0.25rem, rgba(20, 20, 20, 0.06) 0rem 0.5rem 0.5625rem -0.3125rem',
  form: 'rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem',
  dropzone: 'inset rgba(33, 82, 255, 0.05) 0rem 1.25rem 1.6875rem 0rem',
  postQdos: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px'
};
export const sharedInputs = {
  main: [
    {
      width: '100%',
      fontFamily: sharedFonts.main,
      border: `0.07rem solid ${sharedColors.inputBorder}`,
      borderRadius: '0.5rem',
      padding: '0.5rem 0.75rem',
      fontSize: '0.875rem',
      letterSpacing: 0.5,
      fontWeight: 400,
      lineHeight: 1.4,
      input: { padding: 0 },
      marginBottom: '1rem',
      color: sharedColors.inputColor,
      transition: 'all linear 200ms',
    },
    {
      '&.Mui-focused': {
        borderColor: 'rgb(53, 209, 245)',
        boxShadow: 'rgb(129,227,249) 0rem 0rem 0rem 0.125rem',
      },
    },
    {
      '&.Mui-disabled': {
        border: 'transparent',
        fontSize: '0.9rem',
      },
    },
    {
      '.MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: sharedColors.softBlack,
        color: sharedColors.softBlack,
      },
    },
    {
      '&.Mui-error': {
        borderColor: 'rgb(253,92,112)',
      },
    },
    {
      '&.Mui-error.Mui-focused': {
        borderColor: 'rgb(253,92,112)',
        boxShadow: 'rgba(253,92,112, 0.6) 0rem 0rem 0rem 0.125rem',
      },
    },
  ],
  select: [
    {
      width: '100%',
      fontFamily: sharedFonts.main,
      border: `0.07rem solid ${sharedColors.inputBorder}`,
      borderRadius: '0.5rem',
      fontSize: '0.8rem',
      letterSpacing: 0.5,
      fontWeight: 500,
      marginBottom: '1rem',
      color: sharedColors.inputColor,
      '.MuiSelect-select': {
        padding: '0.5rem',
        lineHeight: 1.4,
      },
    },
  ],
};
export const sharedButton = {
  dark: {
    fontSize: '0.75rem',
    lineHeight: '1.1rem',
    fontWeight: 700,
    borderRadius: '0.6rem',
    backgroundImage: sharedColors.gradientDark,
    boxShadow: sharedShadow.button,
    padding: '0.75rem 1.5rem',
    color: sharedColors.white,
  },
  darkOutline: {
    fontSize: '0.75rem',
    lineHeight: '1.1rem',
    fontWeight: 700,
    borderRadius: '0.6rem',
    border: `1px solid ${sharedColors.black}`,
    boxShadow: sharedShadow.button,
    padding: '0.75rem 1.5rem',
    color: sharedColors.black,
  },
  back: {
    fontSize: '0.75rem',
    lineHeight: '1.1rem',
    fontWeight: 700,
    borderRadius: '0.6rem',
    backgroundImage: sharedColors.gradientSecondary,
    boxShadow: sharedShadow.button,
    padding: '0.75rem 1.5rem',
    color: sharedColors.white,
  },
  blue: {
    fontSize: '0.75rem',
    lineHeight: '1.1rem',
    fontWeight: 700,
    borderRadius: '0.6rem',
    backgroundImage: sharedColors.gradientInfo,
    boxShadow: sharedShadow.button,
    padding: '0.75rem 1.5rem',
    color: sharedColors.white,
  },
  green: {
    fontSize: '0.75rem',
    lineHeight: '1.1rem',
    fontWeight: 700,
    borderRadius: '0.6rem',
    backgroundImage: sharedColors.gradientSuccess,
    boxShadow: sharedShadow.button,
    padding: '0.75rem 1.5rem',
    color: sharedColors.white,
  },
  gray: {
    fontSize: '0.75rem',
    lineHeight: '1.1rem',
    fontWeight: 700,
    borderRadius: '0.6rem',
    backgroundImage: sharedColors.gradientGray,
    boxShadow: sharedShadow.button,
    padding: '0.75rem 1.5rem',
    color: sharedColors.black,
  },
  red: {
    fontSize: '0.75rem',
    lineHeight: '1.1rem',
    fontWeight: 700,
    borderRadius: '0.6rem',
    backgroundImage: sharedColors.gradientError,
    boxShadow: sharedShadow.button,
    padding: '0.75rem 1.5rem',
    color: sharedColors.white,
  },
};
export const sharedTitle = {
  categoryTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: '1.4',
    color: sharedColors.black,
  },
  categorySubTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.5',
    letterSpacing: '0.03rem',
    color: sharedColors.grey,
  },
};
export const sharedPage = {
  container: {
    pt: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
  },
  mainBox: {
    padding: '1.3rem',
    width: '100%',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: sharedShadow.box,
  },
  mainTitleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    pb: '1.5rem',
    mb: '1.5rem',
  },
  divider: {
    height: '0.08rem',
    margin: '0 0 1rem',
    opacity: '0.25',
    borderWidth: '0 0 medium',
    borderStyle: 'solid solid none',
    borderColor: 'rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12) currentcolor',
    backgroundColor: 'transparent',
    backgroundImage:
      'linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.5), rgba(52, 71, 103, 0)) !important',
  },
  spinnerBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '20rem',
  },
  placeholder: {
    minHeight: '4rem',
    minWidth: '25rem',
    backgroundImage: `url(${emptyPNG})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
};
export const sharedList = {
  mainBox: {
    width: '100%',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: sharedShadow.box,
  },
  mainTitleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    p: '1.3rem 1.3rem 0',
    mb: '1.5rem',
  },
  tableContainer: {
    'overflow-y': 'hidden',
    '.MuiTableCell-root': {
      borderBottomColor: '#e9ecef',
    },
  },
  tableHead: {
    '.MuiTableCell-head': {
      p: ' 0.75rem 1.5rem',
      fontSize: '0.65rem',
      fontWeight: 700,
      color: sharedColors.grey,
      textTransform: 'uppercase',
    },
  },
  tableBody: {
    '.MuiTableCell-body': {
      p: ' 0.75rem 1.5rem',
      fontSize: '0.875rem',
      lineHeight: 1.5,
      letterSpacing: '0.03rem',
      fontWeight: 500,
      color: sharedColors.black,
    },
  },
  paginationBox: {
    padding: '1rem 0.5rem',
    display: 'flex',
    justifyContent: 'end',
  },
};
