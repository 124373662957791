import { Fragment, ReactElement, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ROUTES } from '../../helpers/constans';
import { Box, Button, Typography } from '@mui/material';

import { styles } from './terms.styles';

const Terms = (): ReactElement => {
  const navigate = useNavigate();

  const handleHome = ({ currentTarget }: SyntheticEvent<HTMLElement>): void => {
    navigate(`${currentTarget.dataset.route?.toLowerCase()}`, { replace: true });
  };

  const handlePrivacy = () => {
    navigate(ROUTES.privacy);
  };

  return (
    <Fragment>
      <Box sx={styles.container}>
        <Button sx={styles.homeLink} data-route={ROUTES.home} onClick={handleHome}>
          <ArrowBackIosIcon /> home
        </Button>

        <Box sx={styles.mainBox}>
          <Box sx={styles.mainTitleBox}>
            <Box>
              <Typography sx={styles.mainTitle}>Mintfinity</Typography>
              <Typography sx={styles.mainSubTitle}>User Terms and Conditions</Typography>
            </Box>
          </Box>
          <Box sx={styles.contentBox}>
            <Typography sx={styles.paragraph}>Last updated October 2023</Typography>

            <Box sx={styles.contentTitleBox}>
              <Typography sx={styles.contentTitle}>PARTIES</Typography>
            </Box>
            <Typography sx={styles.paragraphNormal}>(1) QDOS Mintfinity (“Mintfinity”) whose office is at 22B Nightingale Road, Southsea, United Kingdom, PO5 3JL; and</Typography>
            <Typography sx={styles.paragraphNormal}>(2) The person or business whose details are set out in the Subscription Particulars (“You”, “User”).</Typography>
            <Box sx={styles.contentTitleBox}>
              <Typography sx={styles.contentTitle}>AGREED TERMS</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>1.</Typography></Box>
              <Typography sx={styles.mainContentText}>Acceptance of Terms and Conditions</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box>
                <Typography sx={styles.number}>1.1</Typography>
              </Box>
              <Typography sx={styles.paragraphNormal}>Thank you for selecting the services offered by Mintfinity and its affiliates (referred to throughout as “Mintfinity”, “we”, “our”, or “us”) on this Website and its related applications. These Terms and Conditions (this “Agreement” or the “Terms”) are a legal agreement between you and Mintfinity and shall govern your use of and access to the Website and to the Application (as defined below). </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box>
                <Typography sx={styles.number}>1.2</Typography>
              </Box>
              <Typography sx={styles.paragraphNormal}>
                Please review this document along with the Mintfinity
                <Box component="span" sx={styles.textLink} onClick={handlePrivacy}> Privacy Policy</Box>
                , which describes what information we collect from our users and how we may use it, the terms of which are posted on our Website and incorporated within this Agreement by reference (the “
                <Box component="span" sx={styles.textLink} onClick={handlePrivacy}>Privacy Policy</Box>”).
              </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box>
                <Typography sx={styles.number}>1.3</Typography>
              </Box>
              <Typography sx={styles.paragraphNormal}>By signing up to our Services you hereby expressly allow Mintfinity to share your information with our affiliates and you consent to receive information from Mintfinity’s affiliated websites. You hereby confirm that you agree to abide by the Privacy Policy. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box>
                <Typography sx={styles.number}>1.4</Typography>
              </Box>
              <Typography sx={styles.paragraphNormal}>By clicking “AGREE” and indicating acceptance of these Terms electronically and by accessing and/or using our Service, you hereby confirm that you agree to these Terms. If you do not agree to these Terms and you do not wish to enter into an Agreement with us, then do not click “AGREE” and do not access or use our Service. BY USING OUR SERVICE, YOU ARE INDICATING YOUR AGREEMENT TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS CONTAINED IN THIS AGREEMENT. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box>
                <Typography sx={styles.number}>1.5</Typography>
              </Box>
              <Typography sx={styles.paragraphNormal}>Mintfinity may amend this Agreement from time to time and each such amendments shall be deemed to be effective 2 days after the updated Agreement is posted on the Website or within the Application. You are required to check the Website, the Application and this Agreement regularly to ensure you remain current on the terms and agreements set out within.</Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>2.</Typography></Box>
              <Typography sx={styles.mainContentText}>Purpose</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>2.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>The purpose of this Agreement is to set out the Terms and Conditions under which Mintfinity will license to you certain use of its products, technology, software, data feeds and/or Service, so that you may utilise the Application (as defined below) through your mobile device and so that you may access and/or use our Website (together, the “Purpose”). </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>2.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Mintfinity does not exert any control over any merchants, retailers, commercial ventures or other third parties and shall therefore not be liable or responsible for any actions taken or not taken by any such party at any time. </Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>3.</Typography></Box>
              <Typography sx={styles.mainContentText}>License</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>3.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Mintfinity hereby grants you a non-transferable, non-exclusive, revocable, limited license to access and use Mintfinity’s loyalty software platform (the “Application”) which is made available through our Website and/or via mobile application during the Term (as defined below) solely for the Purpose. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>3.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>The Application may be updated and modified from time to time and Mintfinity may release new versions of the Application or create new modules, each of which may at Mintfinity’s discretion, be included within the license. You may not sublicense or transfer any of your rights under this Agreement, without limitation, including access the Application. </Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>4.</Typography></Box>
              <Typography sx={styles.mainContentText}>User Responsibilities & Limitations </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Typography sx={styles.numberEmpty}></Typography>
              <Typography sx={styles.paragraphNormal}>By consenting to this Agreement, you agree: </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Typography sx={styles.numberEmpty}></Typography>
              <Typography sx={styles.paragraph}>Account registration and use</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>That you are NOT under the age of 13, that the Service shall NOT be accessed by children under the age of 13 at any time and that Mintfinity does NOT intent to collect information from children;</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>That you will create an account with Mintfinity in order to use our Service and that you are solely responsible for the activity that occurs on your account, that you will keep your account username, password and security questions and answers confidential and that you will notify us immediately of any breach of security or unauthorised use of your account. Although we will not be liable for any losses caused by any unauthorised use of your account, you may be liable for any losses of we or others suffer due to such unauthorised use; </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.3</Typography></Box>
              <Typography sx={styles.paragraphNormal}>To provide accurate and complete information to Mintfinity and other users of our services, including, but not limited to, information required to register to receive the Service and information posted in public areas of the Service; </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.4</Typography></Box>
              <Typography sx={styles.paragraphNormal}>To use reasonable endeavours to ensure that your data is at all times up to date and accurate;</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.5</Typography></Box>
              <Typography sx={styles.paragraphNormal}>o accurately represent only yourself and no other person or persons, living, dead or imagined and that the Service may only be used by natural persons (i.e. individuals) on their own behalf and not by or on behalf of any commercial entity, unless with our explicit permission; </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.6</Typography></Box>
              <Typography sx={styles.paragraphNormal}>That you will NOT use the account of another individual without their permission;</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.7</Typography></Box>
              <Typography sx={styles.paragraphNormal}>That you are personally responsible for ensuring you can access the Service including but not limited to obtaining and maintaining all telephone, computer hardware and software, and other equipment and paying all related charges;</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Typography sx={styles.numberEmpty}></Typography>
              <Typography sx={styles.paragraph}>Restrictions on use</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.8</Typography></Box>
              <Typography sx={styles.paragraphNormal}>To NOT use the Service to modify, reproduce, upload, duplicate, copy, sell, resell, distribute, publish, transmit or exploit for any commercial purpose the material, software, products, or services provided by Mintfinity or obtained through the Service without the express, prior written consent of Mintfinity. This restriction includes any attempt to incorporate any information from the Service into any other directory, product or service including the sale of access  to the Service and the sale of advertising, sponsorships or promotions placed on or within the Service. Any use of the Service for public or commercial purposes is strictly prohibited;</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.9</Typography></Box>
              <Typography sx={styles.paragraphNormal}>To NOT access our Website and Application content through any technology or means other than the internet service link provided by the Service, the Application, or other explicitly authorised means designated by us;</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.10</Typography></Box>
              <Typography sx={styles.paragraphNormal}>To NOT access or attempt to access data not intended for you, including, but not limited to, logging into a server, an account, or a part of our Service, or to any other protected materials and information which you are not authorised to access;</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.11</Typography></Box>
              <Typography sx={styles.paragraphNormal}>To NOT tamper or interfere, or attempt to tamper or interfere with the proper functioning of any section, part, page or area of the Service and all functions and services provided by Mintfinity including, but not limited to, submitting a virus to our Service to overload, "flood", "spam", "mail bomb" or "crash" the Website or Application; </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.12</Typography></Box>
              <Typography sx={styles.paragraphNormal}>To NOT resell or repurpose or attempt to resell or repurpose your access to the Services or any purchases made through the Service;  </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.13</Typography></Box>
              <Typography sx={styles.paragraphNormal}>To NOT use the Service or any of its resources to solicit other Users, Merchants or other third parties of Mintfinity to become users or partners of other online or offline services that are in direct or indirect competition with Mintfinity;</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.14</Typography></Box>
              <Typography sx={styles.paragraphNormal}>To NOT access, monitor or copy, or attempt to do so, any content or information from the Website or Application using any robot, spider, scraper or other automated or non-automated means or process for any purpose, express with our express written permission; </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.15</Typography></Box>
              <Typography sx={styles.paragraphNormal}>To NOT attempt to decipher, decompile, disassemble or reverse engineer any of the software comprising the Service or made available through the Service;</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.16</Typography></Box>
              <Typography sx={styles.paragraphNormal}>To NOT act illegally, immorally or maliciously against the business interests and/or reputation of Mintfinity and to NOT engage in any conduct which in the reasonable opinion of Mintfinity is prejudicial to the business and/or goodwill of Mintfinity, our Merchants or our Service;</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Typography sx={styles.numberEmpty}></Typography>
              <Typography sx={styles.paragraph}>Mobile Application</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.17</Typography></Box>
              <Typography sx={styles.paragraphNormal}>That you will be solely responsible for all posted information you input into the Application and for ensuring that all posted information is accurate and appropriate in tone;</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.18</Typography></Box>
              <Typography sx={styles.paragraphNormal}>That the Application may automatically download and install updates from time to time. These updates are designed to improve, enhance and further develop the Application and may take the form of bug fixes, enhanced functions, new software modules and completely new versions. You agree to receive such updates (and permit us to deliver these to you) as part of your use of the Application; and</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Typography sx={styles.numberEmpty}></Typography>
              <Typography sx={styles.paragraph}>General</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.19</Typography></Box>
              <Typography sx={styles.paragraphNormal}>To comply with all applicable laws in your use of our Service. Since this Website and Application may be accessed internationally, you hereby agree to comply with all local laws, rules and regulations in respect of your use of our Website, Application and Service without limitation, including all laws, rules, codes, and regulations of the country in which you reside and the country from which you access this website, including without limitation, all laws, rules, codes, regulations, decrees, acts, orders, directives, legislation, bills, and statutes pertaining to tax, contracts, intellectual property, securities, e-commerce, banking, technology, computers, fraud, and privacy.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.20</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We reserve the right to discontinue any aspect of the Service at any time and shall be entitled, at our sole discretion, to suspend, restrict and/or terminate, without notice of any kind, your access to your Mintfinity account and to the Application for any reason. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>4.21</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Notwithstanding Clause 4.22, Mintfinity shall not be required to review or monitor any posted information entered into the Application or otherwise submitted by you and you shall be solely responsible for the veracity and accuracy of all such data and information. </Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>5.</Typography></Box>
              <Typography sx={styles.mainContentText}>Provision of services</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>5.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>We shall, during the Term (as defined below), use reasonable endeavours to provide the Service on and subject to these Terms however we reserve the right to change, suspend or discontinue all or part of our Service at any time, with or without reason. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>5.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>You hereby acknowledge that the operation of the Service may from time to time encounter technical or other problems and may not necessarily continue uninterrupted or without technical or other errors and Mintfinity shall not be in any way responsible to you or to any other party for any such interruptions, errors or problems or an outright discontinuance of the Service. Mintfinity has no obligation to continue producing or releasing new versions of the Service. </Typography>
            </Box><Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>5.3</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Subject to clause 5.2, Mintfinity shall use reasonable endeavour to keep the Service operational and available 24 hours a day, 7 days a week, except for planned maintenance, in relation to which we shall we use reasonable endeavours to provide prior notice and unavailability of the Service by virtue of circumstances beyond Mintfinity’s control, including, but not limited to, acts of God, acts of government, flood, fire, earthquakes, civil unrest, acts of terror, strikes or other labour problems and internet service provider failures or delays. </Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>6.</Typography></Box>
              <Typography sx={styles.mainContentText}>Disclaimer of warranties </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>6.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>MINTFINITY PROVIDES THE SERVICE AND ALL RELATED SOFTWARE AND CONTENT TO YOU "AS IS," WITHOUT ANY WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT; ANY WARRANTY THAT THE SERVICE OPERATES ERROR FREE OR WITHOUT INTERRUPTION; ANY WARRANTY THAT INFORMATION OBTAINED THROUGH THE SERVICE IS ACCURATE OR RELIABLE. THE ENTIRE RISK ARISING OUT OF THE USE OF THE SERVICE AND TRANSACTIONS WITH THE VENDORS REMAINS WITH YOU.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>6.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>You understand and hereby agree that any material and/or data downloaded or otherwise obtained through your use of our Service is done at your own discretion and risk and you will be solely responsible for any damage to your computer system or loss of data that results from the download of such material and/or data. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>6.3</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Mintfinity makes no warranty regarding any goods or services purchased or obtained through the Service or any transactions entered into through the Service. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>6.4</Typography></Box>
              <Typography sx={styles.paragraphNormal}>No advice or information, whether written or oral, obtained by you from Mintfinity or through the Service shall create any warranty not expressly made in this Agreement. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>6.5</Typography></Box>
              <Typography sx={styles.paragraphNormal}>The information provided on this Website and Application is not intended for access or use by any person or entity in any jurisdiction or country where such access or use would be contrary to law or regulation or which would subject Mintfinity to any registration requirement within such jurisdiction or country. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>6.6</Typography></Box>
              <Typography sx={styles.paragraphNormal}>The Website and Application and the content within is for informational purposes only and should not be considered a solicitation, advice, offer or a recommendation for any fund, security, or service in any jurisdiction where such offer, solicitation or recommendation would be unlawful or unauthorised. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>6.7</Typography></Box>
              <Typography sx={styles.paragraphNormal}>All content on the Website and Application is presented only as of the date published or indicated and may be superseded by subsequent market events or other circumstance. Mintfinity disclaims any responsibility to update the content. </Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>7.</Typography></Box>
              <Typography sx={styles.mainContentText}>Limitation of liability </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>7.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>MINTFINITY SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT OR SPECULATIVE DAMAGES ARISING FROM INTERRUPTION, SUSPENSION OR TERMINATION OF SERVICE, INCLUDING, WITHOUT LIMITING THE FOREGOING, CONSEQUENTIAL, INCIDENTAL AND SPECIAL DAMAGES, WHETHER SUCH INTERRUPTION, SUSPENSION OR TERMINATION WAS JUSTIFIED OR NOT, NEGLIGENT OR INTENTIONAL, INADVERTENT OR ADVERTENT, REGARDLESS OF WHETHER MINTFINITY PROVIDED ADVANCE NOTICE OF THE POSSIBILITY OF ANY SUCH DAMAGES OR NOT. MINTFINITY SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT OR SPECULATIVE DAMAGES ARISING FROM THE USE OR INABILITY TO USE THE SERVICE OR FOR THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES OR RESULTING FROM ANY GOODS OR SERVICES PURCHASED OR OTHERWISE OBTAINED OR MESSAGE RECEIVED OR TRANSACTIONS ENTERED INTO, THROUGH OR FROM THE SERVICE OR RESULTING FROM UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSION OF DATA, INCLUDING WITHOUT LIMITING THE FOREGOING, CONSEQUENTIAL, INCIDENTAL, AND SPECIAL DAMAGES, REGARDLESS WHETHER MINTFINITY HAD ADVANCE NOTICE OF THE POSSIBILITY OF ANY SUCH DAMAGES.</Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>8.</Typography></Box>
              <Typography sx={styles.mainContentText}>Indemnification</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>8.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>You agree to indemnify and hold Mintfinity, our affiliates, officers, directors and employees from any and all claims or demands, including the payment of reasonable attorney's fees, arising out of or relating to your use of the Service.</Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>9.</Typography></Box>
              <Typography sx={styles.mainContentText}>Charges and payment</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>9.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>You are not required to pay subscription fees for Mintfinity in order to receive the Service.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>9.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>The subscription fees, where applicable, are payable monthly or yearly in advance on the date of each month/year corresponding to the date of the month when service commenced. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>9.3</Typography></Box>
              <Typography sx={styles.paragraphNormal}>If Mintfinity has not received payment of the subscription fees within 30 days after the due date and without prejudice to any other rights and remedies, Mintfinity may, without liability to the User, reduce service to free level, disable the User’s password, account and access to all or part of the Services and Mintfinity shall be under no obligation to provide any or all of the Services while the outstanding fees remain unpaid. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>9.4</Typography></Box>
              <Typography sx={styles.paragraphNormal}>All amounts and fees stated or referred to in this Agreement are non-cancellable and non-refundable and are inclusive of value added tax or other applicable sales taxes, which shall be added to Mintfinity’s invoice at the appropriate rate. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>9.5</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Users may be allowed to save their payment information in their account profile. If a User’s account is set up to pay by credit card, via a bank account or through PayPal, the User hereby authorises Mintfinity to charge such credit card, bank account or PayPal account for the appropriate fees and amounts stated. Users may remove payment information from their account profile at any time, however the Service will not be provided until full payment is made. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>9.6</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Mintfinity shall at no time be responsible to any User for any delays in receiving the Service due to untimely payments. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>9.7</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Users who save their payment information in their account profile hereby authorise Mintfinity to collect certain credit card and/or other financial institution information such as bank account numbers or PayPal email ID and to use such information to bill the User. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>9.8</Typography></Box>
              <Typography sx={styles.paragraphNormal}>
                Mintfinity’s use of personal information is governed by Mintfinity’s
                <Box component="span" sx={styles.textLink} onClick={handlePrivacy}> Privacy Policy</Box>
                , these Terms and all other policies posted on its Website and/or Application.
              </Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>10.</Typography></Box>
              <Typography sx={styles.mainContentText}>Confidentiality</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>10.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Each party may be given access to Confidential Information from the other party in order to perform its obligation under these Terms. Confidential information does not include information that is: </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.numberLarge}>10.1.1 </Typography></Box>
                <Typography sx={styles.paragraphNormal}>Publicly available, or becomes publicly available other than through any act or omission of the receiving party;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.numberLarge}>10.1.2 </Typography></Box>
                <Typography sx={styles.paragraphNormal}>In the other party’s lawful possession before the disclosure;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.numberLarge}>10.1.3 </Typography></Box>
                <Typography sx={styles.paragraphNormal}>Lawfully disclosed to the receiving party by a third party without restriction on disclosure;</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.numberLarge}>10.1.4 </Typography></Box>
                <Typography sx={styles.paragraphNormal}>Independently developed by the receiving party, which development can be demonstrated by written evidence; or</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Box sx={styles.paragraphBox}>
                <Box><Typography sx={styles.numberLarge}>10.1.5 </Typography></Box>
                <Typography sx={styles.paragraphNormal}>Is required to be disclosed by law, by any court of competent jurisdiction or by any administrative or regulatory body.</Typography>
              </Box>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>10.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Each party shall hold the other’s Confidential Information in confidence and shall not make the other party’s Confidential Information available to any third party, or use the other party’s Confidential Information for any purpose other than the implementation of these Terms, unless otherwise required by law. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>10.3</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Each party shall take all reasonable steps to ensure that the other party’s Confidential Information to which it has access is not disclosed or distributed by its employees, agents or contractors in violation of these Terms. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>10.4</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Neither party shall be responsible for any loss, destruction, alteration or disclosure of Confidential Information caused by any third party. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>10.5</Typography></Box>
              <Typography sx={styles.paragraphNormal}>You hereby acknowledge that details of the Service and the results of any performance tests of the Services constitute Mintfinity’s Confidential Information. Mintfinity hereby acknowledges that the User’s customer data is the Confidential Information of the User. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>10.6</Typography></Box>
              <Typography sx={styles.paragraphNormal}>This Clause 10 shall survive the termination of this Agreement, howsoever arising. </Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>11.</Typography></Box>
              <Typography sx={styles.mainContentText}>Privacy</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>11.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>You understand that Mintfinity and Merchants will collect information from you and they may use that information for marketing purposes. Please ensure that you read our
                <Box component="span" sx={styles.textLink} onClick={handlePrivacy}> Privacy Policy </Box>
                carefully before using this Website, Application and/or Services and before providing any registration information.
              </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>11.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Mintfinity and each Merchant have agreed to operate in accordance with the Privacy Policy at all times. </Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>12.</Typography></Box>
              <Typography sx={styles.mainContentText}>Termination of right to use Services</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>12.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Mintfinity reserves the right to terminate your right to use the Service for any reason whatsoever, including, but not limited to, your violation of any of these Terms or if your account has been inactive for more than one year, without prior notice. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>12.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>he disclaimers, limitations of liabilities, releases and waivers set out in these Terms shall survive any such termination. </Typography>
            </Box>

            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberBold}>13.</Typography></Box>
              <Typography sx={styles.mainContentText}>Miscellaneous</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>13.1</Typography></Box>
              <Typography sx={styles.paragraphNormal}>A waiver of any right under these Terms shall only be effective if it is in writing and shall apply only to the party to which the waiver is addressed and to the circumstances for which it is granted. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>13.2</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Unless specifically provided otherwise, rights arising under these Terms and cumulative and do not exclude rights provided by law. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>13.3</Typography></Box>
              <Typography sx={styles.paragraphNormal}>If any provision or part of a provision of these Terms is found by any court or administrative body of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall remain in force. If any invalid, unenforceable or illegal provision would be valid, enforceable or legal if some part of it were deleted, then that provision shall apply with whatever modification is necessary to give effect to the commercial intention of the parties. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>13.4</Typography></Box>
              <Typography sx={styles.paragraphNormal}>These Terms and all document referred to in it, constitute the whole agreement between the parties and supersedes any previous arrangement, understanding or agreement made between them relating to the subject matter they covert. Both parties acknowledge and agree that by entering into this Agreement, it does not rely on any undertaking, promise, assurance, statement, representation, warranty or understanding, whether in writing or not, of any person, whether party to these Terms or not, relating to the subject matter of these Terms, other than expressly set out in these Terms. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>13.5</Typography></Box>
              <Typography sx={styles.paragraphNormal}>You may not, unless with the express prior written consent of Mintfinity, assign, transfer, charge, sub-contract or deal in any other manner with all or any of your rights or obligations under these Terms. Mintfinity may at any time assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under these Terms. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>13.6</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Nothing in these Terms is intended to or shall operate to create a partnership between the parties, or authorise either party to act as agent for the other and neither party shall have the authority to act in the name or on behalf of or otherwise bind the other in any way, including, but not limited to, the making of any representation or warranty, the assumption of any obligation or liability and the exercise of any right or power. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>13.7</Typography></Box>
              <Typography sx={styles.paragraphNormal}>These Terms do not confer any rights on any person or party other than the parties to this Agreement and, where applicable, their successor and permitted assigns, pursuant to the Contracts (Rights of Third Parties) Act 1999. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>13.8</Typography></Box>
              <Typography sx={styles.paragraphNormal}>Any notice required to be given under these Terms by you shall be in writing and shall be sent by email to the support@mintfinity.com. Any notice sent by Mintfinity to you shall be sent to the email address provided in the Subscription Particulars. A notice delivered by email shall be deemed to have been received at the time of transmission. </Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.number}>13.9</Typography></Box>
              <Typography sx={styles.paragraphNormal}>These Terms and any disputes or claims arising out of it in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of England and Wales. The parties irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with these Terms or its subject matter or formation, including non-contractual disputes or claims.</Typography>
            </Box>
            <Box sx={styles.paragraphBox}>
              <Box><Typography sx={styles.numberEmpty}></Typography></Box>
              <Typography sx={styles.mainContentText}>You hereby agree that these Terms are binding on you. Your agreement is effective when you use any of the Services or when you on the box in which you agree to these Terms, whichever occurs first.  </Typography>
            </Box>
          </Box>

        </Box>
      </Box >
    </Fragment >
  );
};

export default Terms;
