import { Fragment, ReactElement, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { IOrganisation } from '../../types/@organisation';
import { Box, FormControl, FormLabel, InputBase, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { templateActions } from '../../redux/slices/template.slice';
import { ETemplateType } from '../../types/@template';
import { IEvent } from '../../types/@event';

import { styles } from './template-create-form.styles';

interface IProps {
  type: ETemplateType;
}

const TemplateCreateForm = ({ type }: IProps): ReactElement => {
  const dispatch = useDispatch();
  const organisationsList: IOrganisation[] | [] = useSelector((state: RootState) => state.organisations.all);
  const eventsList: IEvent[] | [] = useSelector((state: RootState) => state.events.all);
  const formValues = useSelector((state: RootState) => state.template.control);

  const handleChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    const target = event.target as typeof event.target & { name: string; value: string };
    dispatch(
      templateActions.templateControl({
        ...formValues,
        [target.name]: target.value,
      })
    );
  };

  const handleSelect = ({ target }: SelectChangeEvent): void => {
    dispatch(
      templateActions.templateControl({
        ...formValues,
        [target.name]: target.value,
      })
    );
  };

  return (
    <Fragment>
      <Box component="form" sx={styles.formBox} onChange={handleChange}>
        <FormControl>
          {type === ETemplateType.singleQR ? (
            <Fragment>
              <FormLabel sx={styles.formLabel}>Organisations</FormLabel>
              <Select sx={styles.select} name="organisationId" defaultValue="" onChange={handleSelect}>
                {organisationsList.map((el, idx) => {
                  return (
                    <MenuItem key={idx} value={el._id}>
                      {el.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Fragment>
          ) : (
            <Fragment>
              <FormLabel sx={styles.formLabel}>Events</FormLabel>
              <Select sx={styles.select} name="eventId" defaultValue="" onChange={handleSelect}>
                {eventsList.map((el, idx) => {
                  return (
                    <MenuItem key={idx} value={el._id}>
                      {el.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Fragment>
          )}
        </FormControl>
        <FormControl>
          <FormLabel sx={styles.formLabel}>Template's Name</FormLabel>
          <InputBase sx={styles.input} name="name" value={formValues.name} />
        </FormControl>
        <FormControl>
          <FormLabel sx={styles.formLabel}>Description</FormLabel>
          <InputBase
            sx={styles.input}
            name="description"
            value={formValues.description}
            multiline
            minRows={4}
          />
        </FormControl>
        <FormControl>
          <FormLabel sx={styles.formLabel}>Amount of external key</FormLabel>
          <InputBase sx={styles.input} name="externalKeyAmount" value={formValues.externalKeyAmount} />
        </FormControl>
      </Box>
    </Fragment>
  );
};
export default TemplateCreateForm;
