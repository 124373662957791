import { Fragment, ReactElement, useState } from 'react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import EventCreateAssociation from '../../components/event-create-association';
import EventCreateOrganisation from '../../components/event-create-organisation';

import { styles } from './events-create.styles';
import EventCreateForm from '../../components/event-create-form';
import EventCreateTeam from '../../components/event-create-team/event-create-team';
import { EEventCreateSteps } from '../../types/@event';

const steps = ['Association', 'Organisation', 'Team', 'Event'];

const EventsCreate = (): ReactElement => {
  const [step, setStep] = useState<number>(0);

  return (
    <Fragment>
      <Box sx={styles.container}>
        <Box sx={styles.stepperBox}>
          <Stepper alternativeLabel activeStep={step}>
            {steps.map((label) => (
              <Step key={label} sx={styles.step}>
                <StepLabel sx={styles.label}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {step === EEventCreateSteps.association && <EventCreateAssociation setStep={setStep} />}
        {step === EEventCreateSteps.organisation && <EventCreateOrganisation setStep={setStep} />}
        {step === EEventCreateSteps.team && <EventCreateTeam setStep={setStep} />}
        {step === EEventCreateSteps.event && <EventCreateForm setStep={setStep} />}
      </Box>
    </Fragment>
  );
};

export default EventsCreate;
