import io, { Socket } from 'socket.io-client';
import { URI_SOCKET } from '../helpers/constans';
import { ESystemEventName } from '../types/@system-event';

export class SystemEventSocket {
  socket: Socket | undefined = undefined;
  handler: ((type: string, payload: any) => void) | undefined = undefined;

  init = (token: string) => {
    this.socket = io(URI_SOCKET, {
      auth: {
        token: token,
      },
    });
  };

  disconnect = () => {
    this.socket?.disconnect();
  };

  addGlobalHandler = (handler: (type: string, payload: any) => void) => {
    this.handler = handler;
  };

  addListener = (listenerName: ESystemEventName, handler: (data: any) => void) => {
    this.socket?.on(listenerName, handler);
  };

  emit = (type: string, payload: any) => {
    this.socket?.emit(type, payload);
  };
}
