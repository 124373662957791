import { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from '../../helpers/constans';

interface IProps {
  authToken: string;
  path: string;
}

const ProtectedRoute = ({ authToken, path = ROUTES.login }: IProps): ReactElement => {
  return !authToken ? <Navigate to={path} replace /> : <Outlet />;
};
export default ProtectedRoute;
