import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchUserGet, fetchUserGetAll, fetchUserUpdateSettings } from '../../services/user.service';
import {
  IUserGetAllResponseOk,
  IUserGetResponseOk,
  IUserUpdateSettingsPayload,
  IUserUpdateSettingsResponseOk,
  TUserGetAllPayload,
  TUserGetPayload,
} from '../../types/@user';
import { notificationActions } from '../slices/notification.slice';
import { userActions } from '../slices/user.slice';

function* getAllUsers({ payload }: PayloadAction<TUserGetAllPayload>) {
  try {
    const fetchResult: IUserGetAllResponseOk = yield call(fetchUserGetAll, payload);
    yield put({ type: userActions['getAll/ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: userActions['getAll/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* getUser({ payload }: PayloadAction<TUserGetPayload>) {
  try {
    const fetchResult: IUserGetResponseOk = yield call(fetchUserGet, payload);
    yield put({ type: userActions['getUser/ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: userActions['getAll/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* updateUserSettings({ payload }: PayloadAction<IUserUpdateSettingsPayload>) {
  try {
    const fetchResult: IUserUpdateSettingsResponseOk = yield call(fetchUserUpdateSettings, payload);
    yield put({ type: userActions['updateUserSettings/ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: userActions['updateUserSettings/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

export function* watchUser() {
  yield takeLatest(userActions.getAll, getAllUsers);
  yield takeLatest(userActions.getUser, getUser);
  yield takeLatest(userActions.updateUserSettings, updateUserSettings);
}
