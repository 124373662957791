import axios from 'axios';
import { URI_AUTH_LOGIN, URI_AUTH_REGISTRATION } from '../helpers/constans';
import {
  ILoginPayload,
  ILoginResponseOk,
  IRegistrationPayload,
  IRegistrationResponseOk,
} from '../types/@auth';

export const fetchLogin = (payload: ILoginPayload): Promise<ILoginResponseOk> => {
  return axios.post(URI_AUTH_LOGIN, payload);
};

export const fetchRegistration = (payload: IRegistrationPayload): Promise<IRegistrationResponseOk> => {
  return axios.post(URI_AUTH_REGISTRATION, payload);
};
