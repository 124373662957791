import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGlobalState } from '../../types/@global';

const initialState: IGlobalState = {
  pendingThingId: undefined,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setPendingThingId: (state: IGlobalState, { payload }: PayloadAction<string | undefined>) => {
      return {
        ...state,
        pendingThingId: payload,
      };
    },
  },
});

export const globalActions = globalSlice.actions;
export const globalReducer = globalSlice.reducer;
