import { ReactElement, Fragment } from 'react';
import { IInterceptorError } from '../../types/@interceptor';
import { Box } from '@mui/system';

interface IProps {
  body: IInterceptorError;
}

const Notification = ({ body }: IProps): ReactElement => {
  let message: ReactElement | ReactElement[];
  typeof body.message === 'object'
    ? (message = body.message.map((el, idx) => <Box key={idx}>{el}</Box>))
    : (message = <Box>${body.message}</Box>);
  return (
    <Fragment>
      <Box>{message}</Box>
    </Fragment>
  );
};
export default Notification;
