import { SyntheticEvent } from 'react';
import { organisationActions } from '../../redux/slices/organisation.slice';
import { RootState } from '../../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, FormControl, FormLabel, InputBase } from '@mui/material';

import { styles } from './organisation-control-form.styles';

const OrganisationControlForm = () => {
  const dispatch = useDispatch();
  const formValues = useSelector((state: RootState) => state.organisations.control);

  const handleChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    const target = event.target as typeof event.target & {
      name: string;
      value: string;
    };
    dispatch(
      organisationActions.organisationControl({
        ...formValues,
        [target.name]: target.value,
      })
    );
  };
  const handleSubmit = (event: SyntheticEvent): void => {
    event.preventDefault();
  };
  return (
    <Box component="form" sx={styles.contentForms} onChange={handleChange} onSubmit={handleSubmit}>
      <Typography sx={styles.contentTitle2}>Organisation Details</Typography>
      <FormControl>
        <FormLabel sx={styles.formLabel}>Name</FormLabel>
        <InputBase sx={styles.input} name="name" value={formValues.name} />
      </FormControl>
      <FormControl>
        <FormLabel sx={styles.formLabel}>Description</FormLabel>
        <InputBase
          sx={styles.input}
          name="description"
          value={formValues.description}
          multiline
          minRows={4}
        />
      </FormControl>
      <FormControl>
        <FormLabel sx={styles.formLabel}>Twitter ID</FormLabel>
        <InputBase sx={styles.input} name="twitterId" value={formValues.twitterId} />
      </FormControl>
      <FormControl>
        <FormLabel sx={styles.formLabel}>Instagram ID</FormLabel>
        <InputBase sx={styles.input} name="instagramId" value={formValues.instagramId} />
      </FormControl>
    </Box>
  );
};
export default OrganisationControlForm;
