import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { teamActions } from '../slices/team.slice';
import { ITeamPayload, ITeamPutResponseOk, ITeamControl, TTeamGetAllPayload, ITeamGetResponseOk } from '../../types/@team';
import { fetchTeamGet, fetchTeamPut } from '../../services/team.service';
import { RootState } from '../store';
import { notificationActions } from '../slices/notification.slice';

function* teamGetData({ payload }: PayloadAction<TTeamGetAllPayload>) {
  try {
    const fetchResult: ITeamGetResponseOk = yield call(fetchTeamGet, payload);
    yield put({ type: teamActions['getTeams/ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: teamActions['getTeams/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* teamPutData({ payload }: PayloadAction<ITeamPayload>) {
  const contentForms: ITeamControl = yield select((state: RootState) => state.teams.control);
  for (const key in contentForms) {
    payload.append(key, contentForms[`${key}`]);
  }
  try {
    const fetchResult: ITeamPutResponseOk = yield call(fetchTeamPut, payload);
    yield put({ type: teamActions['putTeams/ok'].type, payload: fetchResult });
    yield put({ type: teamActions.isCreated.type, payload: true });
  } catch (err) {
    yield put({ type: teamActions['putTeams/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

export function* watchTeam() {
  yield takeLatest(teamActions.putTeams, teamPutData);
  yield takeLatest(teamActions.getTeams, teamGetData);
}
