import { EUserPermission } from '../types/@user';

enum URN {
  AUTH_LOGIN = '/auth/login',
  AUTH_REGISTRATION = '/auth/registration',
  CONTACTS = '/contacts',
  ASSOCIATIONS = '/associations',
  ORGANISATIONS = '/organisations',
  TEAMS = '/teams',
  THING = '/things',
  THING_QDOS = '/things-qdos',
  EXTERNAL_KEY = `/external-key`,
  TARGET = '/things',
  TEMPLATE = '/templates',
  EVENT = '/events',
  USER = '/users',
  EMAIL_VALIDATION = '/email/validation/',
  SYSTEM_EVENT = '/system-events',
}

export const URI_TARGET_PAGE = `${process.env.REACT_APP_URI_TARGET_PAGE}`;
export const URI_AUTH_LOGIN = `${process.env.REACT_APP_URL}${URN.AUTH_LOGIN}`;
export const URI_AUTH_REGISTRATION = `${process.env.REACT_APP_URL}${URN.AUTH_REGISTRATION}`;
export const URI_CONTACTS = `${process.env.REACT_APP_URL}${URN.CONTACTS}`;
export const URI_ASSOCIATIONS = `${process.env.REACT_APP_URL}${URN.ASSOCIATIONS}`;
export const URI_ORGANISATIONS = `${process.env.REACT_APP_URL}${URN.ORGANISATIONS}`;
export const URI_TEAMS = `${process.env.REACT_APP_URL}${URN.TEAMS}`;
export const URI_THING = `${process.env.REACT_APP_URL}${URN.THING}`;
export const URI_THING_QDOS = `${process.env.REACT_APP_URL}${URN.THING_QDOS}`;
export const URI_THING_EXTERNAL_KEY = `${process.env.REACT_APP_URL}${URN.EXTERNAL_KEY}`;
export const URI_TARGET = `${process.env.REACT_APP_URL}${URN.TARGET}`;
export const URI_TEMPLATE = `${process.env.REACT_APP_URL}${URN.TEMPLATE}`;
export const URI_EVENT = `${process.env.REACT_APP_URL}${URN.EVENT}`;
export const URI_USER = `${process.env.REACT_APP_URL}${URN.USER}`;
export const URI_EMAIL_VALIDATION = `${process.env.REACT_APP_URL}${URN.EMAIL_VALIDATION}`;
export const URI_SYSTEM_EVENT = `${process.env.REACT_APP_URL}${URN.SYSTEM_EVENT}`;
export const URI_SOCKET = `${process.env.REACT_APP_URL}`;
export const URI_QDOS = `${process.env.REACT_APP_URL_QDOS}`;
export const URN_QDOS_S3 = `${process.env.REACT_APP_URL_QDOS_S3}`;

export enum ROUTES {
  home = '/',
  login = '/login',
  registration = '/registration',
  terms = '/terms',
  privacy = '/privacy',
  users = '/users',
  usersControl = '/users/:id',
  contacts = '/contacts',
  associations = '/associations',
  associationsCreate = '/associations/create',
  organisations = '/organisations',
  organisationsCreate = '/organisations/create',
  organisationsControl = `/organisations/:id`,
  teams = '/teams',
  teamsCreate = '/teams/create',
  templates = '/templates',
  templatesCreate = '/templates/create',
  templatesControl = '/templates/:id',
  things = '/things',
  thingsCreate = '/things/create',
  thignsControl = '/things/:id',
  thignsQdosMedia = '/things/qdos-media',
  thignsQdosMediaShow = '/things/qdos-media/:id',
  events = '/events',
  eventsCreate = '/events/create',
  target = '/:targetId',
  emailValidation = '/email/validation',
  system = '/system',
}

export const TABS = [
  {
    name: 'home',
    permission: EUserPermission.PUBLIC,
    route: ROUTES.home,
    icon: 'dashboard',
  },
  {
    name: 'users',
    permission: EUserPermission.ADMIN,
    route: ROUTES.users,
    icon: 'group',
  },
  {
    name: 'contact details',
    permission: EUserPermission.USER,
    route: ROUTES.contacts,
    icon: 'contact_page',
  },
  {
    name: 'Association',
    permission: EUserPermission.PRODUCER,
    route: ROUTES.associations,
    icon: 'public',
  },
  {
    name: 'organisations',
    permission: EUserPermission.PRODUCER,
    route: ROUTES.organisations,
    icon: 'apartment',
  },
  {
    name: 'teams',
    permission: EUserPermission.PRODUCER,
    route: ROUTES.teams,
    icon: 'diversity_3',
  },
  {
    name: 'templates',
    permission: EUserPermission.PRODUCER,
    route: ROUTES.templates,
    icon: 'architecture',
  },
  {
    name: 'objects',
    permission: EUserPermission.USER,
    route: ROUTES.things,
    icon: 'category',
  },
  {
    name: 'events',
    permission: EUserPermission.PRODUCER,
    route: ROUTES.events,
    icon: 'emoji_events',
  },
  {
    name: 'system',
    permission: EUserPermission.PRODUCER,
    route: ROUTES.system,
    icon: 'engineering',
  },
];

export const CARDS = [
  {
    permission: EUserPermission.ADMIN,
    route: ROUTES.users,
    icon: 'group',
    name: 'Users',
    description: 'Change users settings',
  },
  {
    permission: EUserPermission.USER,
    route: ROUTES.contacts,
    icon: 'contact_page',
    name: 'Contact details',
    description: 'Add Your Information',
  },
  {
    permission: EUserPermission.PRODUCER,
    route: ROUTES.associations,
    icon: 'public',
    name: 'Associations',
    description: 'Association',
  },
  {
    permission: EUserPermission.PRODUCER,
    route: ROUTES.organisations,
    icon: 'business',
    name: 'Organisations',
    description: 'Add new organisation',
  },
  {
    permission: EUserPermission.PRODUCER,
    route: ROUTES.teams,
    icon: 'diversity_3',
    name: 'Teams',
    description: 'Add new team',
  },
  {
    permission: EUserPermission.PRODUCER,
    route: ROUTES.templates,
    icon: 'architecture',
    name: 'Templates',
    description: 'Create new template',
  },
  {
    permission: EUserPermission.USER,
    route: ROUTES.things,
    icon: 'category',
    name: 'Objects',
    description: 'Manage all  objects',
  },
  {
    permission: EUserPermission.PRODUCER,
    route: ROUTES.events,
    icon: 'emoji_events',
    name: 'Events',
    description: 'Create new event',
  },
  {
    permission: EUserPermission.PRODUCER,
    route: ROUTES.system,
    icon: 'engineering',
    name: 'System',
    description: 'System maintenance',
  },
];

export const USER_PERMISSION = {
  '1': 'user',
  '2': 'user',
  '5': 'producer',
  '10': 'admin',
};
