import { ReactElement, Fragment } from 'react';
import { Outlet } from 'react-router-dom';

const System = (): ReactElement => {
  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};
export default System;
