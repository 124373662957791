import { IImage } from './@shared';

export enum EThingStatuses {
  owned = 'owned',
  unowned = 'unowned',
  lost = 'lost',
  stole = 'stolen',
}
export const ThingStatuses: string[] = [...Object.values(EThingStatuses)];

export interface IThingState {
  all: IThingInformation[] | [];
  current: IThingInformation | undefined;
  obtainedExternalKey: string | undefined;
  thingCreateForm: IThingCreateForm;
  isCreated: boolean;
  isUpdated: boolean;
  pages: number | undefined;
}

export interface IThingCreateForm {
  name: string;
  description: string;
  status: EThingStatuses;
}

export interface IThingInformation {
  _id: string;
  name: string;
  description: string;
  externalKey: string;
  images: IImage[];
  status: EThingStatuses;
  ownerId: string;
  templateId?: string;
  qdosItemId?: string;
  qdosItemAlternativeId?: string;
  createdAt: string;
  updatedAt: string;
}

export interface IThingGetExternalKeyPayload {
  amount: number;
}
export interface IThingPutPayload extends FormData {}
export type TThingGetAllPayload = number | undefined;

export type TThingGetPayload = string;
export interface IThingPayload {
  name: string;
  description: string;
  image: any;
  status: EThingStatuses;
  externalKey?: string;
}
export interface IThingUpdatePayload extends FormData {}
export interface IThingResolvePendingPayload {
  pendingThingId: string;
}

export type IThingGetExternalKeyResponseOk = string;
export type TThingGetAllResponseOk = IThingInformation[];
export interface IThingGetResponseOk extends IThingInformation {}
export interface IThingPutResponseOk extends IThingInformation {}
export interface IThingUpdateResponseOk {
  acknowledged: boolean;
  modifiedCount: number;
  upsertedId: null;
  upsertedCount: number;
  matchedCount: number;
}

export interface IThingGetAllResponseOk {
  data: IThingInformation[];
  pages: number;
}