import { ReactElement, Fragment, useEffect, SyntheticEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Typography, Divider, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { ROUTES, USER_PERMISSION } from '../../helpers/constans';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../redux/slices/user.slice';
import { RootState } from '../../redux/store';
import Modal from '../../components/modal';
import { EPermission } from '../../types/@shared';

import { styles } from './users-control.styles';
import UserPasswordReset from '../../components/user-password-reset';

interface ISettingValues {
  permission: number;
  password?: string;
  userId: string;
}

const UsersControl = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.users.current);
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [settingValues, setSettingValues] = useState<ISettingValues>({
    permission: EPermission.NONE,
    userId: '',
  });

  useEffect(() => {
    dispatch(userActions.getUser(id));
    id &&
      setSettingValues((state) => {
        return { ...state, userId: id };
      });

    return () => {
      dispatch(userActions.clearCurrentUser());
    };
  }, [dispatch, id]);

  useEffect(() => {
    user?.permission &&
      setSettingValues((state) => {
        return { ...state, permission: +user.permission };
      });
  }, [user]);

  const handleClose = () => {
    setIsModalOpen(() => false);
  };

  const handlePruducer = ({ currentTarget }: SyntheticEvent<HTMLInputElement>) => {
    setSettingValues((state) => {
      return {
        ...state,
        permission: +currentTarget.value !== EPermission.PRODUCER ? EPermission.PRODUCER : EPermission.USER,
      };
    });
  };

  const handlePasswordResetModal = () => {
    setIsModalOpen(() => true);
  };

  const handlePassword = (value: string) => {
    setSettingValues((state) => {
      return {
        ...state,
        password: value,
      };
    });
  };

  const handleBack = (): void => {
    navigate(ROUTES.users);
  };

  const handleSave = () => {
    dispatch(userActions.updateUserSettings(settingValues));
    navigate(ROUTES.users);
  };

  return (
    <Fragment>
      <Box sx={styles.container}>
        <Box sx={styles.mainBox}>
          <Box sx={styles.mainTitleBox}>
            <Box>
              <Typography sx={styles.mainTitle}>User settings</Typography>
              <Typography sx={styles.mainSubTitle}>
                Here are all accessible users params you can change
              </Typography>
            </Box>
            <Box sx={styles.buttonBox}>
              <Button sx={styles.backButton} onClick={handleBack}>
                back
              </Button>
              <Button sx={styles.addButton} onClick={handleSave}>
                save
              </Button>
            </Box>
          </Box>
          <Box sx={styles.mainContentBox}>
            <Box sx={styles.contentInformation}>
              {user && (
                <Fragment>
                  <Typography sx={styles.contentTitle}>Information</Typography>
                  <Divider sx={styles.divider} />
                  <Box sx={styles.informationItem}>
                    <Typography sx={styles.informationItemName}>Name:</Typography>
                    <Typography sx={styles.informationItemText}>{user.contacts[0]?.name}</Typography>
                  </Box>
                  <Box sx={styles.informationItem}>
                    <Typography sx={styles.informationItemName}>Email:</Typography>
                    <Typography sx={styles.informationItemText}>{user.email}</Typography>
                  </Box>
                  <Box sx={styles.informationItem}>
                    <Typography sx={styles.informationItemName}>Status:</Typography>
                    <Typography sx={styles.informationItemText}>
                      {USER_PERMISSION[user.permission]}
                    </Typography>
                  </Box>
                  <Box sx={styles.informationItem}>
                    <Typography sx={styles.informationItemName}>Phone:</Typography>
                    <Typography sx={styles.informationItemText}>{user.contacts[0]?.phone}</Typography>
                  </Box>
                  <Box sx={styles.informationItem}>
                    <Typography sx={styles.informationItemName}>Twitter:</Typography>
                    <Typography sx={styles.informationItemText}>{user.contacts[0]?.twitterId}</Typography>
                  </Box>
                  <Box sx={styles.informationItem}>
                    <Typography sx={styles.informationItemName}>Instagram:</Typography>
                    <Typography sx={styles.informationItemText}>{user.contacts[0]?.instagramId}</Typography>
                  </Box>
                  <Box sx={styles.informationItem}>
                    <Typography sx={styles.informationItemName}>Created at:</Typography>
                    <Typography sx={styles.informationItemText}>
                      {new Date(user.createdAt).toLocaleDateString()}
                    </Typography>
                  </Box>
                </Fragment>
              )}
            </Box>
            <Box sx={styles.contentSettings}>
              <Typography sx={styles.contentTitle}>Settings</Typography>
              <Divider sx={styles.divider} />
              <Box sx={styles.settingsItem}>
                <Box sx={styles.settingsNameBox}>
                  <Typography sx={styles.settingsNameTitle}>Producer</Typography>
                  <Typography sx={styles.settingsNameSubTitle}>
                    Activation allow to create templates and events
                  </Typography>
                </Box>
                <Box sx={styles.settingSelectBox}>
                  <FormGroup sx={styles.switchBox}>
                    <FormControlLabel
                      sx={styles.switchLabel}
                      control={
                        <Switch
                          name="isProducer"
                          onChange={handlePruducer}
                          value={settingValues.permission}
                          checked={settingValues.permission >= EPermission.PRODUCER ? true : false}
                        />
                      }
                      label={settingValues.permission >= EPermission.PRODUCER ? 'Enabled' : 'Disabled'}
                      labelPlacement="start"
                    />
                  </FormGroup>
                </Box>
              </Box>
              <Box sx={styles.settingsItem}>
                <Box sx={styles.settingsNameBox}>
                  <Typography sx={styles.settingsNameTitle}>Password</Typography>
                  <Typography sx={styles.settingsNameSubTitle}>Reset current password</Typography>
                </Box>
                <Box sx={styles.settingButtonBox}>
                  {settingValues.password && (
                    <Typography sx={styles.buttonLabel}>press save to save the password</Typography>
                  )}
                  <Button onClick={handlePasswordResetModal}>reset</Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal name="new password" isOpen={isModalOpen} handleClose={handleClose}>
        <UserPasswordReset handleClose={handleClose} handlePassword={handlePassword} />
      </Modal>
    </Fragment>
  );
};
export default UsersControl;
