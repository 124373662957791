import { ReactElement, Fragment } from 'react';
import { Outlet } from 'react-router-dom';

const Teams = (): ReactElement => {
  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};

export default Teams;
