import { sharedColors, sharedFonts, sharedInputs, sharedShadow } from '../../../assets/shared.styles';
export const styles = {
  wrapper: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: {
      md: '0.6',
      sm: '75%',
      xs: 0.9,
    },
    padding: '1.7rem',
    maxWidth: '430px',
  },
  singIn: {
    fontFamily: sharedFonts.main,
    fontSize: '1.5rem',
    lineHeight: 1.375,
    letterSpacing: 0.1,
    color: sharedColors.black,
    fontWeight: '700',
    marginBottom: '8px',
  },
  title: {
    fontFamily: sharedFonts.main,
    fontSize: '0.9rem',
    lineHeight: 1.6,
    letterSpacing: 0.8,
    color: sharedColors.softBlack,
    fontWeight: 500,
    marginBottom: '1.7rem',
  },
  inputBox: {
    position: 'relative',
    mb: '1.5rem',
  },
  input: [...sharedInputs.main, { padding: '0.75rem', mb: '0' }],
  inputLabe: {
    fontSize: '0.75rem',
    lineHeight: '1.25',
    letterSpacing: '0.1rem',
    fontWeight: 700,
    color: sharedColors.black,
    mb: '0.5rem',
    ml: '0.25rem',
  },
  inputErrorLabel: {
    width: '100%',
    position: 'absolute',
    bottom: '-1.3rem',
    right: '0',
    textAlign: 'right',
    fontSize: '0.75rem',
    lineHeight: '1.25',
    letterSpacing: '0.01rem',
    fontWeight: 400,
    color: sharedColors.inputErrorLabel,
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  switchBox: {
    mb: '2rem',
  },
  switchLabel: {
    '&.MuiFormControlLabel-root': {
      m: 0,
    },
    '& .MuiFormControlLabel-label': {
      fontFamily: sharedFonts.main,
      fontSize: '0.85rem',
      fontWeight: '500',
      letterSpacing: 0.5,
    },
  },
  button: {
    color: '#fff',
    backgroundImage: sharedColors.gradientInfo,
    boxShadow: sharedShadow.button,
    padding: '0.85rem',
    lineHeight: 1.4,
    fontWeight: 700,
    borderRadius: '0.6rem',
    mb: '1.5rem',
  },
  signUpBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signUpTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '0.85rem',
    mr: '0.4rem',
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'transparent',
    fontFamily: sharedFonts.main,
    fontSize: '0.85rem',
    fontWeight: '600',
    backgroundImage: sharedColors.gradientInfo,
    backgroundClip: 'text',
  },
};
