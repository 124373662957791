import { Fragment, ReactElement, useEffect } from 'react';
import { Box, Typography, Button, Pagination } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

import { styles } from './organisations-list.styles';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../helpers/constans';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { organisationActions } from '../../redux/slices/organisation.slice';

const OrganisationList = (): ReactElement => {
  const {
    all: organisationsList,
    pages,
  } = useSelector((state: RootState) => state.organisations);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNewOrganisation = (): void => {
    navigate(ROUTES.organisationsCreate);
  };
  useEffect(() => {
    dispatch(organisationActions.getOrganisations());
  }, [dispatch]);

  const handlePagination = (page: number) => {
    dispatch(organisationActions.getOrganisations(page));
  };

  return (
    <Fragment>
      <Box sx={styles.container}>
        <Box sx={styles.mainBox}>
          <Box sx={styles.mainTitleBox}>
            <Box>
              <Typography sx={styles.mainTitle}>Organisations</Typography>
              <Typography sx={styles.mainSubTitle}>
                Add your own organisations to create new templates.
              </Typography>
            </Box>
            <Button sx={styles.addButton} onClick={handleNewOrganisation}>
              + add new organisation
            </Button>
          </Box>
          <Box component="ul" sx={styles.mainContentList}>
            {organisationsList.map((el: any) => {
              return (
                <Box key={el._id} component="li" sx={styles.listItem}>
                  <Box sx={styles.listItemTitle}>
                    <Typography sx={styles.titleText}>{el.name}</Typography>
                    <Button sx={styles.buttonEdit} size="small" startIcon={<EditRoundedIcon />}>
                      <Box sx={styles.buttonText} component="span">
                        edit
                      </Box>
                    </Button>
                  </Box>
                  <Box sx={styles.listItemContent}>
                    <Box sx={styles.contentDesctiption}>{el.description}</Box>
                    <Box sx={styles.contentImageBox}>
                      <Box sx={styles.contentImage(el.image?.path)} />
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box sx={styles.paginationBox}>
            <Pagination count={pages} onChange={(event, page) => handlePagination(page)} />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
export default OrganisationList;
