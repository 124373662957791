import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInterceptorError } from '../../types/@interceptor';
import {
  ITeamState,
  ITeamPayload,
  ITeamPutResponseOk,
  ITeamControl,
  TTeamGetAllPayload,
  ITeamGetResponseOk,
} from '../../types/@team';

const initialState: ITeamState = {
  all: [],
  control: {
    name: '',
    twitterId: '',
    instagramId: '',
  },
  isCreated: false,
  pages: undefined,
};

const teamSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    teamControl: (state: ITeamState, { payload }: PayloadAction<ITeamControl>) => {
      return {
        ...state,
        control: payload,
      };
    },
    getTeams: (state: ITeamState, { payload }: PayloadAction<TTeamGetAllPayload>) => {},
    'getTeams/ok': (state: ITeamState, { payload }: PayloadAction<ITeamGetResponseOk>): ITeamState => {
      return {
        ...state,
        all: payload.data,
        pages: payload.pages,
      };
    },
    'getTeams/err': (state: ITeamState, action: PayloadAction<IInterceptorError>) => {},
    putTeams: (state: ITeamState, { payload }: PayloadAction<ITeamPayload>) => {},
    'putTeams/ok': (state: ITeamState, { payload }: PayloadAction<ITeamPutResponseOk>) => {},
    'putTeams/err': (state: ITeamState, { payload }: PayloadAction<IInterceptorError>) => {},
    isCreated: (state: ITeamState, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        isCreated: payload,
      };
    },
  },
});

export const teamActions = teamSlice.actions;
export const teamReducer = teamSlice.reducer;
