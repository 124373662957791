import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthState, ILoginPayload, IRegistrationPayload } from '../../types/@auth';
import { IInterceptorError } from '../../types/@interceptor';

const initialState: IAuthState = {
  id: '',
  permission: '',
  token: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state: IAuthState, { payload }: PayloadAction<ILoginPayload>) => {},
    'login/ok': (state: IAuthState, { payload }: PayloadAction<IAuthState>): IAuthState => {
      return payload;
    },
    'login/err': (state: IAuthState, action: PayloadAction<IInterceptorError>) => {},
    logout: (state: IAuthState) => initialState,
    registration: (state: IAuthState, { payload }: PayloadAction<IRegistrationPayload>) => {},
    'registration/ok': (state: IAuthState, { payload }: PayloadAction<IAuthState>): IAuthState => {
      return payload;
    },
    'registration/err': (state: IAuthState, action: PayloadAction<IInterceptorError>) => {},
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
