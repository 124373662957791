import { ReactElement, Fragment, useEffect, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  Typography,
  TableCell,
  Pagination,
} from '@mui/material';

import { styles } from './users-list.styles';
import { userActions } from '../../redux/slices/user.slice';
import { useNavigate } from 'react-router-dom';
import { ROUTES, USER_PERMISSION } from '../../helpers/constans';

const UsersList = (): ReactElement => {
  const users = useSelector((store: RootState) => store.users.all);
  const pages = useSelector((state: RootState) => state.users.pages);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(userActions.getAll());
  }, [dispatch]);

  const handleClick = ({ currentTarget }: SyntheticEvent<HTMLTableRowElement>) => {
    navigate(`${ROUTES.users}/${currentTarget.dataset.id}`);
  };

  const handlePagination = (page: number) => {
    dispatch(userActions.getAll(page));
  };

  return (
    <Fragment>
      <Box sx={styles.container}>
        <Box sx={styles.mainBox}>
          <Box sx={styles.mainTitleBox}>
            <Box>
              <Typography sx={styles.mainTitle}>Users</Typography>
              <Typography sx={styles.mainSubTitle}>Select the user and change it permission</Typography>
            </Box>
          </Box>
          <TableContainer sx={styles.tableContainer}>
            <Table>
              <TableHead sx={styles.tableHead}>
                <TableRow>
                  <TableCell>№</TableCell>
                  <TableCell>e-mail</TableCell>
                  <TableCell>status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={styles.tableBody}>
                {users.map((el, idx) => {
                  return (
                    <TableRow
                      key={el._id}
                      sx={styles.tableRow}
                      data-id={el._id}
                      hover={true}
                      onClickCapture={handleClick}
                    >
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>{el.email}</TableCell>
                      <TableCell>{USER_PERMISSION[el.permission]}</TableCell>
                    </TableRow>
                  );
                })}
                <TableRow></TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={styles.paginationBox}>
            <Pagination count={pages} onChange={(event, page) => handlePagination(page)} />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
export default UsersList;
