import { ReactElement } from 'react';
import { Dialog, Box, Button } from '@mui/material';
import { ETemplateType } from '../../types/@template';

import { styles } from './template-create-popup.styles';

interface IProps {
  isOpen: boolean;
  handleClose: (value: ETemplateType) => void;
}

const TemplateCreatePopup = ({ isOpen, handleClose }: IProps): ReactElement => {
  return (
    <Dialog open={isOpen}>
      <Box>
        <Box sx={styles.body}>
          <Button sx={styles.popupButton} onClick={() => handleClose(ETemplateType.singleQR)}>
            1qr
          </Button>
          <Button sx={styles.popupButton} onClick={() => handleClose(ETemplateType.coupleQR)}>
            2qr
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default TemplateCreatePopup;
