import { Fragment, ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import {
  Box,
  InputBase,
  Typography,
  Button,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { styles } from './contacts.styles';
import { useDispatch, useSelector } from 'react-redux';
import { contactActions } from '../../redux/slices/contact.slice';
import { RootState } from '../../redux/store';

interface IFormValues {
  name: string;
  phone: string;
  twitterId: string;
  instagramId: string;
  isPrivate: boolean;
}

const Contacts = (): ReactElement => {
  const dispatch = useDispatch();
  const contacts = useSelector((state: RootState) => state.contacts);
  const [formValues, setFormValues] = useState<IFormValues>({
    name: '',
    phone: '',
    twitterId: '',
    instagramId: '',
    isPrivate: true,
  });
  const [isEditMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    dispatch(contactActions.getContacts());
  }, [dispatch]);

  useEffect(() => {
    setFormValues(() => {
      return {
        name: contacts.name,
        phone: contacts.phone,
        twitterId: contacts.twitterId,
        instagramId: contacts.instagramId,
        isPrivate: contacts.isPrivate,
      };
    });
    if (!contacts.phone && !contacts.instagramId && !contacts.twitterId) {
      setEditMode(() => {
        return true;
      });
    } else {
      setEditMode(() => {
        return false;
      });
    }
  }, [contacts]);

  const handleSubmit = (event: any): void => {
    event.preventDefault();
    if (isEditMode) {
      dispatch(contactActions.putContacts(formValues));
      setEditMode(() => {
        return false;
      });
    } else {
      setEditMode(() => {
        return true;
      });
    }
  };

  const handleChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    const target = event.target as typeof event.target & {
      name: string;
      value: string;
      checked: boolean;
      type: string;
    };
    setFormValues((state: IFormValues) => {
      return {
        ...state,
        [target.name]: target.type === 'checkbox' ? !target.checked : target.value,
      };
    });
  };

  return (
    <Fragment>
      <Box sx={styles.container}>
        <Box component="form" sx={styles.mainBox(isEditMode)} onSubmit={handleSubmit} onChange={handleChange}>
          <Box sx={styles.mainTitleBox}>
            <Typography sx={styles.mainTitle}>About me</Typography>
            <Typography sx={styles.mainSubTitle}>Mandatory informations</Typography>
          </Box>
          <FormControl>
            <FormLabel sx={styles.formLabel}>Full Name</FormLabel>
            <InputBase sx={styles.input} name="name" value={formValues.name} disabled={!isEditMode} />
          </FormControl>
          <FormControl>
            <FormLabel sx={styles.formLabel}>Phone</FormLabel>
            <InputBase sx={styles.input} name="phone" value={formValues.phone} disabled={!isEditMode} />
          </FormControl>
          <FormControl>
            <FormLabel sx={styles.formLabel}>Twitter ID</FormLabel>
            <InputBase
              sx={styles.input}
              name="twitterId"
              value={formValues.twitterId}
              disabled={!isEditMode}
            />
          </FormControl>
          <FormControl>
            <FormLabel sx={styles.formLabel}>Instagram ID</FormLabel>
            <InputBase
              sx={styles.input}
              name="instagramId"
              value={formValues.instagramId}
              disabled={!isEditMode}
            />
          </FormControl>
          <Box sx={styles.buttonsBox}>
            <FormGroup sx={styles.switchBox}>
              <FormControlLabel
                sx={styles.switchLabel}
                control={<Switch name="isPrivate" checked={!formValues.isPrivate} />}
                label="Allow to show my contact's data"
                disabled={!isEditMode}
              />
            </FormGroup>
            <Button sx={styles.buttonEditSave(isEditMode)} type="submit">
              {isEditMode ? 'Save' : 'Edit'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
export default Contacts;
