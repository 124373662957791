import { ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { fetchEmailValidation } from '../../services/email.service';
import { Box } from '@mui/material';

import { styles } from './email.styles';

interface IIsValidated {
  isRequestDone: boolean;
  isValidate: boolean;
}

const Email = (): ReactElement => {
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const [isValidated, setIsValidated] = useState<IIsValidated>({ isRequestDone: false, isValidate: false });

  const requestValidation = async (validationKey: string) => {
    const result = await fetchEmailValidation(validationKey);
    !result
      ? setIsValidated(() => {
          return {
            isRequestDone: true,
            isValidate: false,
          };
        })
      : setIsValidated(() => {
          return {
            isRequestDone: true,
            isValidate: true,
          };
        });
  };

  useEffect(() => {
    key && requestValidation(key);
  }, [key]);

  return (
    <Box sx={styles.container}>
      {isValidated.isRequestDone && isValidated.isValidate ? (
        <Box sx={styles.text}>Your email address is verified!</Box>
      ) : (
        <Box sx={styles.text}>Validation ERROR!</Box>
      )}
    </Box>
  );
};

export default Email;
