import {
  sharedPage,
  sharedTitle,
  sharedButton,
  sharedList,
} from '../../assets/shared.styles';

export const styles = {
  container: sharedPage.container,
  spinnerBox: sharedPage.spinnerBox,
  mainBox: sharedList.mainBox,
  mainTitleBox: sharedList.mainTitleBox,
  mainTitle: sharedTitle.categoryTitle,
  buttonBox: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr',
    },
    gridTemplateRows: '1fr 1fr',
    rowGap: '1rem',
    columnGap: '1rem',
  },
  checkAll: {
    ...sharedButton.dark,
    maxHeight: '2.4rem',
    mr: {
      xs: 0,
      md: '1rem',
    },
  },
  deleteAll: { ...sharedButton.red, maxHeight: '2.4rem' },
  tableHead: sharedList.tableHead,
  tableBody: sharedList.tableBody,
  tableContainer: sharedList.tableContainer,
  paginationBox: sharedList.paginationBox,
};
