import { Fragment, ReactElement, useEffect, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../helpers/constans';
import { useDispatch, useSelector } from 'react-redux';
import { templateActions } from '../../redux/slices/template.slice';
import { RootState } from '../../redux/store';
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Pagination,
} from '@mui/material';

import { styles } from './templates-list.styles';

const TemplateList = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { all: templates, pages } = useSelector((state: RootState) => state.template);

  useEffect(() => {
    dispatch(templateActions.getAllTemplate());
  }, [dispatch]);

  const handleAddTemplate = (): void => {
    navigate(ROUTES.templatesCreate);
  };

  const handleClick = ({ currentTarget }: SyntheticEvent<HTMLTableRowElement>): void => {
    navigate(`${ROUTES.templates}/${currentTarget.dataset.id}`);
  };

  const handlePagination = (page: number) => {
    dispatch(templateActions.getAllTemplate(page));
  };

  return (
    <Fragment>
      <Box sx={styles.container}>
        <Box sx={styles.mainBox}>
          <Box sx={styles.mainTitleBox}>
            <Box>
              <Typography sx={styles.mainTitle}>Templates</Typography>
              <Typography sx={styles.mainSubTitle}>Create new templates for your products</Typography>
            </Box>
            <Button sx={styles.addButton} onClick={handleAddTemplate}>
              + create template
            </Button>
          </Box>
          <TableContainer sx={styles.tableContainer}>
            <Table>
              <TableHead sx={styles.tableHead}>
                <TableRow>
                  <TableCell>№</TableCell>
                  <TableCell>image</TableCell>
                  <TableCell>name</TableCell>
                  <TableCell>description</TableCell>
                  <TableCell>organisations name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={styles.tableBody}>
                {templates.map((el, idx) => {
                  return (
                    <TableRow
                      key={el._id}
                      sx={styles.tableRow}
                      data-id={el._id}
                      hover={true}
                      onClickCapture={handleClick}
                    >
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>
                        <Box sx={styles.thingImage(el.image?.path)} />
                      </TableCell>
                      <TableCell>{el.name}</TableCell>
                      <TableCell>{el.description}</TableCell>
                      <TableCell>{el.organisationsInfo.name}</TableCell>
                    </TableRow>
                  );
                })}
                <TableRow></TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={styles.paginationBox}>
            <Pagination count={pages} onChange={(event, page) => handlePagination(page)} />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
export default TemplateList;
