import { ReactElement, Fragment } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';

interface IProps {
  buttonName: string;
  styles: Record<string, any>;
  isLoading: boolean;
  handler: () => void;
}

const ButtonWithLoading = ({ buttonName, styles, isLoading, handler }: IProps): ReactElement => {
  return (
    <Fragment>
      {isLoading ? (
        <LoadingButton sx={styles} loading={isLoading}>
          loading
        </LoadingButton>
      ) : (
        <Button sx={styles} onClick={() => handler()}>
          {buttonName}
        </Button>
      )}
    </Fragment>
  );
};

export default ButtonWithLoading;
