import { Fragment, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

import { Box, useMediaQuery } from '@mui/material';
import Navbar from '../../components/navbar';
import Sidebar from '../../components/sidebar';
import { Outlet } from 'react-router-dom';

import { styles } from './home.styles';

const Home = (): ReactElement => {
  const authToken: string = useSelector((state: RootState) => state.auth.token);
  const isPermanent = useMediaQuery('(min-width:1200px)');

  return (
    <Fragment>
      {authToken && (
        <Fragment>
          <Box sx={styles.main(isPermanent)}>
            <Navbar />
            <Outlet />
          </Box>
          <Sidebar isPermanent={isPermanent} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Home;
