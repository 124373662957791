import axios from 'axios';
import { URI_SYSTEM_EVENT } from '../helpers/constans';
import { ISystemEventGetAllResponseOk } from '../types/@system-event';

export const fetchSystemEventGetAll = (
  page: number | undefined = 1
): Promise<ISystemEventGetAllResponseOk[]> => {
  return axios.get(`${URI_SYSTEM_EVENT}?page=${page}`);
};

export const fetchSystemEventCheckAll = () => {
  return axios.put(`${URI_SYSTEM_EVENT}`);
};

export const fetchSystemeventDeleteAll = () => {
  return axios.delete(`${URI_SYSTEM_EVENT}`);
};
