import { ReactElement } from 'react';
import { Box, Button, Icon } from '@mui/material';

import { styles } from './thing-create-save.styles';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../helpers/constans';
import Spinner from '../spinner';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const ThingCreateSave = (): ReactElement => {
  const navigate = useNavigate();
  const isCreated = useSelector((state: RootState) => state.things.isCreated);

  const handleOk = (): void => {
    navigate(ROUTES.things);
  };

  return (
    <Box sx={styles.container}>
      {!isCreated ? (
        <Spinner />
      ) : (
        <Box sx={styles.mainBox}>
          <Icon sx={styles.icon}>check</Icon>
          <Box sx={styles.title}>Object add to database</Box>
          <Button sx={styles.button} onClick={handleOk}>
            ok
          </Button>
        </Box>
      )}
    </Box>
  );
};
export default ThingCreateSave;
