import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInterceptorError } from '../../types/@interceptor';
import {
  IThingGetExternalKeyPayload,
  IThingInformation,
  IThingState,
  IThingGetExternalKeyResponseOk,
  IThingPutPayload,
  TThingGetAllPayload,
  EThingStatuses,
  IThingCreateForm,
  TThingGetPayload,
  IThingGetResponseOk,
  IThingUpdateResponseOk,
  IThingUpdatePayload,
  IThingResolvePendingPayload,
  IThingGetAllResponseOk,
} from '../../types/@thing';

const initialState: IThingState = {
  all: [],
  current: undefined,
  obtainedExternalKey: undefined,
  thingCreateForm: {
    name: '',
    description: '',
    status: EThingStatuses.owned,
  },
  isCreated: false,
  isUpdated: false,
  pages: undefined,
};

const thingSlice = createSlice({
  name: 'things',
  initialState,
  reducers: {
    isCreated: (state: IThingState, { payload }: PayloadAction<boolean>) => {
      return { ...state, isCreated: payload };
    },
    isUpdated: (state: IThingState, { payload }: PayloadAction<boolean>) => {
      return { ...state, isUpdated: payload };
    },
    thingCreateForm: (state: IThingState, { payload }: PayloadAction<IThingCreateForm>) => {
      return {
        ...state,
        thingCreateForm: payload,
      };
    },
    thingCreateFormClear: (state: IThingState) => {
      return {
        ...state,
        thingCreateForm: {
          name: '',
          description: '',
          status: EThingStatuses.owned,
        },
      };
    },
    thingControlForm: (state: IThingState, { payload }: PayloadAction<IThingInformation | undefined>) => {
      return {
        ...state,
        current: payload,
      };
    },
    thingControlClear: (state: IThingState) => {
      return {
        ...state,
        current: undefined,
      };
    },
    getExternalKey: (state: IThingState, { payload }: PayloadAction<IThingGetExternalKeyPayload>) => {},
    'getExternalKey/ok': (state: IThingState, { payload }: PayloadAction<IThingGetExternalKeyResponseOk>) => {
      return {
        ...state,
        obtainedExternalKey: payload,
      };
    },
    'getExternalKey/err': (state: IThingState, { payload }: PayloadAction<IInterceptorError>) => {},
    putThing: (state: IThingState, { payload }: PayloadAction<IThingPutPayload>) => {},
    'putThing/ok': (state: IThingState, { payload }) => {},
    'putThing/err': (state: IThingState, { payload }: PayloadAction<IInterceptorError>) => {},
    getAllThings: (state: IThingState, { payload }: PayloadAction<TThingGetAllPayload>) => {},
    'getAllThings/ok': (state: IThingState, { payload }: PayloadAction<IThingGetAllResponseOk>) => {
      return {
        ...state,
        all: payload.data,
        pages: payload.pages,
      };
    },
    'getAllThings/err': (state: IThingState, { payload }: PayloadAction<IInterceptorError>) => {},
    getThing: (state: IThingState, action: PayloadAction<TThingGetPayload>) => {},
    'getThing/ok': (state: IThingState, { payload }: PayloadAction<IThingGetResponseOk>) => {
      return {
        ...state,
        current: payload,
      };
    },
    'getThing/err': (state: IThingState, action: PayloadAction<IInterceptorError>) => {},
    updateThing: (state: IThingState, { payload }: PayloadAction<IThingUpdatePayload>) => {},
    'updateThing/ok': (state: IThingState, { payload }: PayloadAction<IThingUpdateResponseOk>) => {},
    'updateThing/err': (state: IThingState, { payload }: PayloadAction<IInterceptorError>) => {},
    resolvePendingThing: (state: IThingState, { payload }: PayloadAction<IThingResolvePendingPayload>) => {},
    'resolvePendingThing/Ok': (state: IThingState, { payload }: PayloadAction<any>) => {},
    'resolvePendingThing/Err': (state: IThingState, action: PayloadAction<IInterceptorError>) => {},
  },
});

export const thingActions = thingSlice.actions;
export const thingReducer = thingSlice.reducer;
