import { sharedSizes } from '../../assets/shared.styles';
export const styles = {
  main: (isPermanent: boolean) =>
    isPermanent
      ? {
          width: `calc(100%-${sharedSizes.drawerSize})`,
          ml: `${sharedSizes.drawerSize}`,
          p: '1.5rem',
          transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        }
      : {
          width: `100%`,
          p: '1.5rem',
          transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
};
