import { ReactElement, Fragment, SyntheticEvent, useEffect } from 'react';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { ThingStatuses } from '../../types/@thing';
import { Box, FormControl, FormLabel, InputBase, Select, MenuItem, SelectChangeEvent } from '@mui/material';

import { styles } from './thing-control-form.styles';
import { thingActions } from '../../redux/slices/thing.slice';

const ThingControlForm = (): ReactElement => {
  const formValues = useSelector((state: RootState) => state.things.current);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(thingActions.thingControlClear());
    };
  }, [dispatch]);

  const handleChange = (event: SyntheticEvent<HTMLFormElement>): void => {
    if (!formValues) return;
    const target = event.target as typeof event.target & { name: string; value: string };
    dispatch(
      thingActions.thingControlForm({
        ...formValues,
        [target.name]: target.value,
      })
    );
  };
  const handleSelect = ({ target }: SelectChangeEvent): void => {
    if (!formValues) return;
    dispatch(
      thingActions.thingControlForm({
        ...formValues,
        [target.name]: target.value,
      })
    );
  };

  return (
    <Fragment>
      {formValues && (
        <Box component="form" sx={styles.contentForms} onChange={handleChange} data-id={formValues._id}>
          <FormControl sx={styles.form}>
            <FormLabel sx={styles.formLabel}>Name</FormLabel>
            <InputBase sx={styles.input} name="name" value={formValues.name} />
          </FormControl>
          <FormControl sx={styles.form}>
            <FormLabel sx={styles.formLabel}>Description</FormLabel>
            <InputBase
              sx={styles.input}
              name="description"
              value={formValues.description}
              multiline
              minRows={4}
            />
          </FormControl>
          <FormControl sx={styles.form}>
            <FormLabel sx={styles.formLabel}>Status</FormLabel>
            <Select sx={styles.select} name="status" defaultValue={formValues.status} onChange={handleSelect}>
              {ThingStatuses.map((el, idx) => {
                return (
                  <MenuItem key={idx} value={el}>
                    {el}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      )}
    </Fragment>
  );
};
export default ThingControlForm;
