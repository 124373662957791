import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInterceptorError } from '../../types/@interceptor';
import {
  IUserGetAllResponseOk,
  TUserGetPayload,
  IUserGetResponseOk,
  IUserState,
  IUserUpdateSettingsPayload,
  TUserGetAllPayload,
} from '../../types/@user';

const initialState: IUserState = {
  all: [],
  current: undefined,
  pages: undefined,
  isLoaded: false,
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getAll: (state: IUserState, { payload }: PayloadAction<TUserGetAllPayload>) => {},
    'getAll/ok': (state: IUserState, { payload }: PayloadAction<IUserGetAllResponseOk>) => {
      return {
        ...state,
        all: payload.data,
        pages: payload.pages,
      };
    },
    'getAll/err': (state: IUserState, { payload }: PayloadAction<IInterceptorError>) => {},
    getUser: (state: IUserState, { payload }: PayloadAction<TUserGetPayload>) => {
      return {
        ...state,
        current: undefined,
      };
    },
    'getUser/ok': (state: IUserState, { payload }: PayloadAction<IUserGetResponseOk>) => {
      return { ...state, current: payload };
    },
    'getUser/err': (state: IUserState, { payload }: PayloadAction<IInterceptorError>) => {},
    clearCurrentUser: (state: IUserState) => {
      return { ...state, current: undefined };
    },
    updateUserSettings: (state: IUserState, { payload }: PayloadAction<IUserUpdateSettingsPayload>) => {},
    'updateUserSettings/ok': () => {},
    'updateUserSettings/err': (state: IUserState, { payload }: PayloadAction<IInterceptorError>) => {},
  },
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;
