import { Fragment, ReactElement } from 'react';
import { Box, Button, InputBase, Typography, FormControl, FormLabel } from '@mui/material';
import { useFormik } from 'formik';
import { userPasswordResetSchema } from '../../helpers/formik-shemas/user-password-reset.schema';

import { styles } from './user-password-reset.styles';

interface IProps {
  handleClose: () => void;
  handlePassword: (value: string) => void;
}
interface IFormValue {
  password: string;
}

const UserPasswordReset = (props: IProps): ReactElement => {
  const formik = useFormik<IFormValue>({
    initialValues: {
      password: '',
    },
    validationSchema: userPasswordResetSchema,
    validateOnChange: true,
    onSubmit: () => {},
  });

  const handleOk = (event: any) => {
    event.preventDefault();
    if (formik.isValid) {
      formik.resetForm();
      props.handlePassword(formik.values.password);
      props.handleClose();
    }
  };

  return (
    <Fragment>
      <Box>
        <Box
          component="form"
          sx={styles.form}
          onSubmit={handleOk}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <FormControl sx={styles.formControl}>
            <FormLabel sx={styles.formLabel}>Password</FormLabel>
            <InputBase
              sx={styles.input}
              id="password"
              type="password"
              placeholder="Password"
              value={formik.values.password}
              error={Boolean(formik.values.password) && Boolean(formik.errors.password)}
            />
            <Typography sx={styles.inputErrorLabel}>{formik.errors.password}</Typography>
          </FormControl>
        </Box>
        <Box sx={styles.footer}>
          <Button sx={styles.button} onClick={handleOk}>
            ok
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

export default UserPasswordReset;
