import { IContact } from './@contact';

export interface IUserState {
  all: IUserInformation[] | [];
  current: IUserGetResponseOk | undefined;
  pages: number | undefined;
  isLoaded: boolean;
}

export enum EUserPermission {
  PUBLIC = 1,
  USER = 1,
  PRODUCER = 5,
  ADMIN = 10,
}

export interface IUser {
  email: string;
  permission: EUserPermission;
}

export type TUserGetPayload = string | undefined;
export type TUserGetAllPayload = number | undefined;

export interface IUserUpdateSettingsPayload {
  password?: string;
  permission: number;
  userId: string;
}

export interface IUserInformation extends IUser {
  _id: string;
  createdAt: string;
  updatedAt: string;
}

export interface IUserGetAllResponseOk {
  data: IUserInformation[];
  pages: number;
}
export interface IUserGetResponseOk extends IUser {
  _id: string;
  createdAt: string;
  updatedAt: string;
  contacts: IContact[];
}

export interface IUserUpdateSettingsResponseOk {}
