import { Fragment, ReactElement, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './redux/store';
import { CssBaseline } from '@mui/material';
import { ROUTES } from './helpers/constans';
import useNotification from './helpers/useNotification';

import './app.css';

import ProtectedRoute from './components/protected-route';
import Login from './pages/auth/login';
import Registration from './pages/auth/registration';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import Home from './pages/home';
import Dashboard from './pages/dashboard';
import Contacts from './pages/contacts';
import Error from './pages/error';
import Organisations from './pages/organisations';
import OrganisationsList from './pages/organisations-list';
import OrganisationsControl from './pages/organisations-control';
import Templates from './pages/templates';
import TemplatesList from './pages/templates-list';
import TemplatesCreate from './pages/templates-create';
import TemplatesControl from './pages/templates-control';
import Things from './pages/things';
import ThingsList from './pages/things-list';
import ThingCreate from './pages/things-create';
import ThingsControl from './pages/things-control';
import ThingsQdosMedia from './pages/things-qdos-media';
import Target from './pages/target';
import Events from './pages/events';
import EventsList from './pages/events-list';
import EventsCreate from './pages/events-create';
import Users from './pages/users';
import UsersList from './pages/users-list';
import UsersControl from './pages/users-control';
import Email from './pages/email';
import System from './pages/system';

import SystemEventList from './components/system-event-list';
import { thingActions } from './redux/slices/thing.slice';
import Associations from './pages/associations';
import AssociationsList from './pages/associations-list';
import AssociationsCreate from './pages/associations-create';
import Teams from './pages/teams';
import TeamsList from './pages/teams-list';
import TeamsCreate from './pages/teams-create';

const App = (): ReactElement => {
  const authToken: string = useSelector((state: RootState) => state.auth.token);
  const pendingThingId: string | undefined = useSelector((state: RootState) => state.global.pendingThingId);
  const dispatch = useDispatch();
  useNotification();

  useEffect(() => {
    if (authToken && pendingThingId) {
      dispatch(thingActions.resolvePendingThing({ pendingThingId }));
    }
  }, [authToken, pendingThingId, dispatch]);

  return (
    <Fragment>
      <CssBaseline />
      <Routes>
        <Route path={ROUTES.login} element={<Login />} />
        <Route path={ROUTES.registration} element={<Registration />} />
        <Route path={ROUTES.terms} element={<Terms />} />
        <Route path={ROUTES.privacy} element={<Privacy />} />
        <Route element={<ProtectedRoute authToken={authToken} path={ROUTES.login} />}>
          <Route path={ROUTES.home} element={<Home />}>
            <Route index element={<Dashboard />} />
            <Route path={ROUTES.contacts} element={<Contacts />} />
            <Route path={ROUTES.users} element={<Users />}>
              <Route index element={<UsersList />} />
              <Route path={ROUTES.usersControl} element={<UsersControl />} />
            </Route>
            <Route path={ROUTES.associations} element={<Associations />}>
              <Route index element={<AssociationsList />} />
              <Route path={ROUTES.associationsCreate} element={<AssociationsCreate />} />
            </Route>
            <Route path={ROUTES.organisations} element={<Organisations />}>
              <Route index element={<OrganisationsList />} />
              <Route path={ROUTES.organisationsCreate} element={<OrganisationsControl />} />
              <Route path={ROUTES.organisationsControl} element={<OrganisationsControl />} />
            </Route>
            <Route path={ROUTES.teams} element={<Teams />}>
              <Route index element={<TeamsList />} />
              <Route path={ROUTES.teamsCreate} element={<TeamsCreate />} />
            </Route>
            <Route path={ROUTES.templates} element={<Templates />}>
              <Route index element={<TemplatesList />} />
              <Route path={ROUTES.templatesCreate} element={<TemplatesCreate />} />
              <Route path={ROUTES.templatesControl} element={<TemplatesControl />} />
            </Route>
            <Route path={ROUTES.things} element={<Things />}>
              <Route index element={<ThingsList />} />
              <Route path={ROUTES.thingsCreate} element={<ThingCreate />} />
              <Route path={ROUTES.thignsControl} element={<ThingsControl />} />
              <Route path={ROUTES.thignsQdosMediaShow} element={<ThingsQdosMedia />} />
            </Route>
            <Route path={ROUTES.events} element={<Events />}>
              <Route index element={<EventsList />} />
              <Route path={ROUTES.eventsCreate} element={<EventsCreate />} />
            </Route>
            <Route path={ROUTES.system} element={<System />}>
              <Route index element={<SystemEventList />} />
            </Route>
          </Route>
        </Route>
        <Route path={ROUTES.target} element={<Target />} />
        <Route path={ROUTES.emailValidation} element={<Email />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Fragment>
  );
};
export default App;
