import * as yup from 'yup';

export const loginSchema = yup.object({
  email: yup.string().email().required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

export const registrationSchema = yup.object({
  name: yup.string(),
  email: yup.string().email().required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});
