import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInterceptorError } from '../../types/@interceptor';
import {
  ISystemEventGetAllResponseOk,
  ISystemEventState,
  TSystemEventGetAllPayload,
} from '../../types/@system-event';

const initialState: ISystemEventState = {
  isSystemEventBadgeCardOpen: false,
  all: [],
  pages: undefined,
  isLoaded: false,
};

const systemEventSlice = createSlice({
  name: 'systemEvent',
  initialState,
  reducers: {
    setSystemEventBadgeCardOpen: (state: ISystemEventState, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        isSystemEventBadgeCardOpen: payload,
      };
    },
    setIsLoaded: (state: ISystemEventState, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoaded: payload,
      };
    },
    getAll: (state: ISystemEventState, { payload }: PayloadAction<TSystemEventGetAllPayload>) => {},
    'getAll/Ok': (state: ISystemEventState, { payload }: PayloadAction<ISystemEventGetAllResponseOk>) => {
      return {
        ...state,
        all: payload.data,
        pages: payload.pages,
      };
    },
    'getAll/Err': (state: ISystemEventState, action: PayloadAction<IInterceptorError>) => {},
    checkAll: (state: ISystemEventState) => {},
    'checkAll/Ok': (state: ISystemEventState) => {},
    'checkAll/Err': (state: ISystemEventState, action: PayloadAction<IInterceptorError>) => {},
    deleteAll: (state: ISystemEventState) => {},
    'deleteAll/Ok': (state: ISystemEventState) => {},
    'deleteAll/Err': (state: ISystemEventState, action: PayloadAction<IInterceptorError>) => {},
  },
});

export const systemEventActions = systemEventSlice.actions;
export const systemEventReducer = systemEventSlice.reducer;
