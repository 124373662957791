import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAssociation } from '../../types/@association';
import {
  IEventCreateForm,
  IEventGetResponseOk,
  IEventPutPayload,
  IEventState,
  TEventGetAllPayload,
} from '../../types/@event';
import { IInterceptorError } from '../../types/@interceptor';
import { IOrganisation } from '../../types/@organisation';
import { ITeam } from '../../types/@team';

const initialState: IEventState = {
  all: [],
  eventCreate: {
    association: {
      _id: '',
      name: '',
      instagramId: '',
      twitterId: '',
    },
    organisation: {
      _id: '',
      name: '',
      description: '',
      twitterId: '',
      instagramId: '',
    },
    team: {
      _id: '',
      name: '',
      twitterId: '',
      instagramId: '',
    },
    eventForm: {
      name: '',
      slogan: '',
      twitterId: '',
      instagramId: '',
    },
  },
  isLoaded: { organisations: false },
  isCreated: false,
  pages: undefined,
};

const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    getEvents: (state: IEventState, { payload }: PayloadAction<TEventGetAllPayload>) => {},
    'getEvents/ok': (state: IEventState, { payload }: PayloadAction<IEventGetResponseOk>) => {
      return {
        ...state,
        all: payload.data,
        pages: payload.pages,
      };
    },
    'getEvents/err': (state: IEventState, action: PayloadAction<IInterceptorError>) => {},
    putEvent: (state: IEventState, action: PayloadAction<IEventPutPayload>) => {},
    'putEvent/ok': () => {},
    'putEvent/err': (state: IEventState, action: PayloadAction<IInterceptorError>) => {},
    'isLoaded/organisations': (state: IEventState, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoaded: { organisations: payload },
      };
    },
    selectedAssociation: (state: IEventState, { payload }: PayloadAction<IAssociation>) => {
      return {
        ...state,
        eventCreate: {
          ...state.eventCreate,
          association: payload,
        },
      };
    },
    selectedOrganisation: (state: IEventState, { payload }: PayloadAction<IOrganisation>) => {
      return {
        ...state,
        eventCreate: {
          ...state.eventCreate,
          organisation: payload,
        },
      };
    },
    selectedTeam: (state: IEventState, { payload }: PayloadAction<ITeam>) => {
      return {
        ...state,
        eventCreate: {
          ...state.eventCreate,
          team: payload,
        },
      };
    },
    eventCreateForm: (state: IEventState, { payload }: PayloadAction<IEventCreateForm>) => {
      return {
        ...state,
        eventCreate: {
          ...state.eventCreate,
          eventForm: payload,
        },
      };
    },
    isCreated: (state: IEventState, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        isCreated: payload,
      };
    },
  },
});

export const eventActions = eventSlice.actions;
export const eventReducer = eventSlice.reducer;
