import { Fragment, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { eventActions } from '../../redux/slices/event.slice';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Pagination,
} from '@mui/material';
import { ROUTES } from '../../helpers/constans';

import { styles } from './events-list.styles';

const EventsList = (): ReactElement => {
  const { 
    all: events, 
    pages 
  } = useSelector((state: RootState) => state.events);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(eventActions.getEvents());
  }, [dispatch]);

  const handleCreateEvent = () => {
    navigate(ROUTES.eventsCreate);
  };

  const handlePagination = (page: number) => {
    dispatch(eventActions.getEvents(page));
  };

  return (
    <Fragment>
      <Box sx={styles.container}>
        <Box sx={styles.mainBox}>
          <Box sx={styles.mainTitleBox}>
            <Box>
              <Typography sx={styles.mainTitle}>Events</Typography>
              <Typography sx={styles.mainSubTitle}>Create new events</Typography>
            </Box>
            <Button sx={styles.addButton} onClick={handleCreateEvent}>
              + create event
            </Button>
          </Box>
          <TableContainer sx={styles.tableContainer}>
            <Table>
              <TableHead sx={styles.tableHead}>
                <TableRow>
                  <TableCell>№</TableCell>
                  <TableCell>name</TableCell>
                  <TableCell>slogan</TableCell>
                  <TableCell>twitterId</TableCell>
                  <TableCell>instagramId</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={styles.tableBody}>
                {events.map((el, idx) => {
                  return (
                    <TableRow key={el._id}>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>{el.name}</TableCell>
                      <TableCell>{el.slogan}</TableCell>
                      <TableCell>{el.twitterId}</TableCell>
                      <TableCell>{el.instagramId}</TableCell>
                    </TableRow>
                  );
                })}
                <TableRow></TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={styles.paginationBox}>
            <Pagination count={pages} onChange={(event, page) => handlePagination(page)} />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
export default EventsList;
