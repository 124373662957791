import { ReactElement, Fragment, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EThingStatuses, ThingStatuses } from '../../types/@thing';
import { RootState } from '../../redux/store';
import { thingActions } from '../../redux/slices/thing.slice';
import { Box, FormControl, FormLabel, InputBase, Select, MenuItem, SelectChangeEvent } from '@mui/material';

import { styles } from './thing-create-form.styles';

const ThingCreateForm = (): ReactElement => {
  const formValues = useSelector((state: RootState) => state.things.thingCreateForm);
  const dispatch = useDispatch();

  const handleChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    const target = event.target as typeof event.target & {
      name: string;
      value: string;
    };
    dispatch(
      thingActions.thingCreateForm({
        ...formValues,
        [target.name]: target.value,
      })
    );
  };
  const handleSelect = ({ target }: SelectChangeEvent): void => {
    dispatch(
      thingActions.thingCreateForm({
        ...formValues,
        [target.name]: target.value,
      })
    );
  };

  return (
    <Fragment>
      <Box component="form" onChange={handleChange}>
        <FormControl sx={styles.form}>
          <FormLabel sx={styles.formLabel}>Name</FormLabel>
          <InputBase sx={styles.input} name="name" value={formValues.name} />
        </FormControl>
        <FormControl sx={styles.form}>
          <FormLabel sx={styles.formLabel}>Description</FormLabel>
          <InputBase
            sx={styles.input}
            name="description"
            value={formValues.description}
            multiline
            minRows={4}
          />
        </FormControl>
        <FormControl sx={styles.form}>
          <FormLabel sx={styles.formLabel}>Status</FormLabel>
          <Select
            sx={styles.select}
            name="status"
            defaultValue={EThingStatuses.owned}
            onChange={handleSelect}
          >
            {ThingStatuses.map((el, idx) => {
              return (
                <MenuItem key={idx} value={el}>
                  {el}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Fragment>
  );
};
export default ThingCreateForm;
