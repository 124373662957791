import {
  sharedList,
  sharedSizes,
  sharedColors,
  sharedFonts,
} from '../../assets/shared.styles';

export const styles = {
  container: {
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    p: '1.5rem',
  },
  mainBox: {
    width: '100%',
    maxWidth: '720px',
    margin: 'auto',
    bgcolor: sharedColors.bgWhite,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainTitleBox: sharedList.mainTitleBox,
  mainTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '1.4',
    color: sharedColors.black,
    textAlign: 'center'
  },
  mainSubTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '1.5',
    letterSpacing: '0.03rem',
    color: sharedColors.black,
    textAlign: 'center'
  },
  contentBox: {
    width: '100%',
  },
  contentTitleBox: {
    margin: '20px 0 15px', 
  },
  contentTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '21px',
    fontWeight: 500,
    lineHeight: '1.4',
    color: sharedColors.black,
  },
  contentSubTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '21px',
    fontWeight: 500,
    lineHeight: '1.4',
    color: sharedColors.black,
  },
  paragraphBox: {
    display: 'flex',
  },
  paragraph: {
    fontFamily: sharedFonts.main,
    fontSize: '14px',
    fontStyle: 'italic',
    fontWeight: 400,
    mb: '7px'
  },
  paragraphNormal: {
    fontFamily: sharedFonts.main,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    mb: '7px',
  },
  number: {
    fontFamily: sharedFonts.main,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    width: '40px'
  },
  numberLarge: {
    fontFamily: sharedFonts.main,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    width: '50px'
  },
  numberEmpty: {
    fontFamily: sharedFonts.main,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    width: '40px'
  },
  numberBold: {
    fontFamily: sharedFonts.main,
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 500,
    width: '40px',
    mt: '5px'
  },
  mainContentText: {
    fontFamily: sharedFonts.main,
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 500,
    mb: '10px',
    mt: '5px'
  },
  textLink: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'transparent',
    fontFamily: sharedFonts.main,
    fontSize: '14px',
    fontWeight: '400',
    backgroundImage: sharedColors.gradientInfo,
    backgroundClip: 'text',
  },
  homeLink: {
    position: 'fixed',
    fontSize: '0.875rem',
    fontWeight: '500',
    letterSpacing: '0.01rem',
    color: sharedColors.softBlack,
    mb: '0.4rem',
    textTransform: 'lowercase',
  },
};
