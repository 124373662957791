import { sharedSizes, sharedColors, sharedShadow, sharedButton } from '../../assets/shared.styles';
export const styles = {
  container: {
    pt: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.3rem',
    width: '40%',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    boxShadow: sharedShadow.box,
  },
  icon: {
    fontSize: '3.5rem',
    backgroundClip: 'text',
    backgroundImage: sharedColors.gradientSuccess,
    WebkitTextFillColor: 'transparent',
    mb: '1rem',
  },
  title: { mb: '1.5rem' },
  button: { ...sharedButton.gray, minWidth: '20%' },
};
