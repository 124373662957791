import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useSnackbar } from 'notistack';
import Notification from '../components/notification/notification';

const useNotification = (): void => {
  const noto = useSelector((state: RootState) => state.notifications.noto);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    noto && enqueueSnackbar(<Notification body={noto.body} />, { variant: noto.type });
  }, [noto, enqueueSnackbar]);
};

export default useNotification;
