import {
  sharedFonts,
  sharedColors,
  sharedButton,
  sharedSizes,
  sharedShadow,
} from '../../assets/shared.styles';

export const styles = {
  container: {
    pt: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
  },
  mainBox: {
    padding: '1.3rem 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainTitleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: {
      xs: '0 1.3rem 1.3rem 1.3rem',
      md: '0 1.3rem 0',
    },
    minHeight: '6.25rem',
  },
  mainTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '1.5rem',
    lineHeight: '1.4',
    fontWeight: 500,
    letterSpacing: '0.05rem',
    color: sharedColors.black,
    mb: '0.5rem',
  },
  mainSubTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.6',
    letterSpacing: '0.05rem',
    color: sharedColors.grey,
    mb: '1rem',
  },
  buttonBox: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr',
    },
    gridTemplateRows: '1fr 1fr',
    rowGap: '1rem',
    columnGap: '1rem',
  },
  backButton: { ...sharedButton.back, maxHeight: '2.6rem' },
  mainContentBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '2rem 1.2rem',
    padding: '3rem 1.5rem 1.5rem',
    width: '100%',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    boxShadow: sharedShadow.box,
  },
  postImage: {
    width: '100%',
    borderRadius: sharedSizes.borderRadiusImgTiny,
    boxShadow: sharedShadow.postQdos,
  },
};
