import axios from 'axios';
import { URI_USER } from '../helpers/constans';
import {
  IUserGetAllResponseOk,
  IUserGetResponseOk,
  IUserUpdateSettingsPayload,
  IUserUpdateSettingsResponseOk,
  TUserGetAllPayload,
  TUserGetPayload,
} from '../types/@user';

export const fetchUserGetAll = (page: TUserGetAllPayload): Promise<IUserGetAllResponseOk> => {
  return axios.get(`${URI_USER}?page=${page}`);
};

export const fetchUserGet = (id: TUserGetPayload): Promise<IUserGetResponseOk> => {
  return axios.get(`${URI_USER}/${id}`);
};

export const fetchUserUpdateSettings = (
  data: IUserUpdateSettingsPayload
): Promise<IUserUpdateSettingsResponseOk> => {
  return axios.post(`${URI_USER}`, data);
};
