import { ReactElement, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import { authActions } from '../../redux/slices/auth.slice';
import { ROUTES } from '../../helpers/constans';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box, useMediaQuery, useTheme, Icon } from '@mui/material';
import SystemEventBadge from '../system-event-badge';
import SystemEventBadgeCard from '../system-event-badge-card';

import { styles } from './navbar.styles';
import { RootState } from '../../redux/store';

const Navbar = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const isSystemEventBadgeCardOpen: boolean = useSelector(
    (state: RootState) => state.systemEvents.isSystemEventBadgeCardOpen
  );

  const handleLogout = (): void => {
    dispatch(authActions.logout());
  };
  const handleHome = ({ currentTarget }: SyntheticEvent<HTMLElement>): void => {
    navigate(`${currentTarget.dataset.route?.toLowerCase()}`, { replace: true });
  };

  return (
    <AppBar sx={styles.header}>
      <Toolbar sx={styles.toolbar}>
        {!matches && (
          <Button sx={styles.home} data-route={ROUTES.home} onClick={handleHome}>
            <Icon sx={styles.homeIcon}>dashboard</Icon>
          </Button>
        )}
        <Box>
          <SystemEventBadge />
          <Button sx={styles.logout} size="small" endIcon={<LogoutIcon />} onClick={handleLogout}>
            <Box sx={styles.logoutText} component="span">
              Logout
            </Box>
          </Button>
        </Box>
      </Toolbar>
      {isSystemEventBadgeCardOpen && <SystemEventBadgeCard />}
    </AppBar>
  );
};
export default Navbar;
