import { Typography, Button } from '@mui/material';
import { Box } from '@mui/system';
import { Fragment, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { targetActions } from '../../redux/slices/target.slice';
import { RootState } from '../../redux/store';
import Spinner from '../../components/spinner';
import { globalActions } from '../../redux/slices/global.slice';
import { EThingStatuses } from '../../types/@thing';

import { styles } from './target.styles';
import { ROUTES } from '../../helpers/constans';

const Target = (): ReactElement => {
  const { targetId } = useParams();
  const { isLoaded, info } = useSelector((state: RootState) => state.target);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    targetId && dispatch(targetActions.getTarget({ id: targetId }));
  }, [targetId, dispatch]);

  const handleBecomeOwner = () => {
    dispatch(globalActions.setPendingThingId(targetId));
    navigate(ROUTES.registration);
  };

  return (
    <Fragment>
      <Box sx={styles.container}>
        {!isLoaded && <Spinner />}
        {(isLoaded && info && (
          <Box sx={styles.mainBox}>
            <Typography sx={styles.mainTitle}>{info.name}</Typography>
            <Box sx={styles.imageBox}>
              <Box sx={styles.targetImage(info.images[0]?.path)} />
            </Box>
            <Box sx={styles.descriptionsBox}>
              <Typography sx={styles.title}>Status</Typography>
              <Typography sx={styles.status}>{info.status}</Typography>
              <Typography sx={styles.title}>Description</Typography>
              <Typography sx={styles.descriptions}>{info.description}</Typography>
              {info.ownerInfo?.isPrivate && (
                <Fragment>
                  <Typography sx={styles.title}>Owner's name</Typography>
                  <Typography sx={styles.descriptions}>{info.ownerInfo.name}</Typography>
                </Fragment>
              )}
              {info.ownerInfo?.isPrivate === false && (
                <Fragment>
                  <Typography sx={styles.title}>Owner's name</Typography>
                  <Typography sx={styles.descriptions}>{info.ownerInfo.name}</Typography>
                  <Typography sx={styles.title}>Owner's phone number</Typography>
                  <Typography sx={styles.descriptions}>{info.ownerInfo?.phone}</Typography>
                  <Typography sx={styles.title}>Owner's twitter account</Typography>
                  <Typography sx={styles.descriptions}>{info.ownerInfo?.twitterId}</Typography>
                  <Typography sx={styles.title}>Owner's instagram account</Typography>
                  <Typography sx={styles.descriptions}>{info.ownerInfo?.instagramId}</Typography>
                </Fragment>
              )}
            </Box>
            {info.templateId && info.status === EThingStatuses.unowned && (
              <Box sx={styles.buttonBecomeOwnerBox}>
                <Button sx={styles.buttonBecomeOwner} onClick={handleBecomeOwner}>
                  become the owner
                </Button>
              </Box>
            )}
          </Box>
        )) || <Box>Object not found</Box>}
      </Box>
    </Fragment>
  );
};
export default Target;
