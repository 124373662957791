import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInterceptorError } from '../../types/@interceptor';
import { ENotificationType, INotificationState } from '../../types/@notification';

const initialState: INotificationState = {
  noto: undefined,
};

const notificationSlice = createSlice({
  initialState,
  name: 'notification',
  reducers: {
    error: (state: INotificationState, { payload }: PayloadAction<IInterceptorError>) => {
      return {
        noto: {
          type: ENotificationType.error,
          body: payload,
        },
      };
    },
    success: (state: INotificationState, { payload }: PayloadAction<IInterceptorError>) => {
      return {
        noto: {
          type: ENotificationType.success,
          body: payload,
        },
      };
    },
    warning: (state: INotificationState, { payload }: PayloadAction<IInterceptorError>) => {
      return {
        noto: {
          type: ENotificationType.warning,
          body: payload,
        },
      };
    },
    info: (state: INotificationState, { payload }: PayloadAction<IInterceptorError>) => {
      return {
        noto: {
          type: ENotificationType.info,
          body: payload,
        },
      };
    },
  },
});

export const notificationActions = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
