import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchSystemEventCheckAll,
  fetchSystemeventDeleteAll,
  fetchSystemEventGetAll,
} from '../../services/system-event.service';
import { ISystemEventGetAllResponseOk, TSystemEventGetAllPayload } from '../../types/@system-event';
import { notificationActions } from '../slices/notification.slice';
import { systemEventActions } from '../slices/system-event.slice';

function* getAllSystemEvent({ payload }: PayloadAction<TSystemEventGetAllPayload>) {
  try {
    const fetchResult: ISystemEventGetAllResponseOk[] = yield call(fetchSystemEventGetAll, payload?.page);
    yield put({ type: systemEventActions['getAll/Ok'].type, payload: fetchResult });
    yield put({ type: systemEventActions.setIsLoaded.type, payload: true });
  } catch (err) {
    yield put({ type: systemEventActions['getAll/Err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* checkAllSystemEvent() {
  try {
    const fetchResult: string = yield call(fetchSystemEventCheckAll);
    yield put({ type: systemEventActions['checkAll/Ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* deleteAllSystemEvent() {
  try {
    const fetchResult: string = yield call(fetchSystemeventDeleteAll);
    yield put({ type: systemEventActions['deleteAll/Ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

export function* watchSystemEvent() {
  yield takeLatest(systemEventActions.getAll, getAllSystemEvent);
  yield takeLatest(systemEventActions.checkAll, checkAllSystemEvent);
  yield takeLatest(systemEventActions.deleteAll, deleteAllSystemEvent);
}
