import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInterceptorError } from '../../types/@interceptor';
import { IThingQdosGetAllResponseOk, IThingQdosState, TThingQdosGetAllPayload, TThingQdosGetAllPostsByItemIdPayload, TThingQdosGetAllPostsByItemIdResponseOk } from '../../types/@thingQdos';

const initialState: IThingQdosState = {
  all: [],
  posts: [],
  pages: undefined,
};

const thingQdosSlice = createSlice({
  name: 'thingsQdos',
  initialState,
  reducers: {
    getAllThings: (state: IThingQdosState, { payload }: PayloadAction<TThingQdosGetAllPayload>) => {},
    'getAllThings/ok': (state: IThingQdosState, { payload }: PayloadAction<IThingQdosGetAllResponseOk>) => {
      return {
        ...state,
        all: payload.data,
        pages: payload.pages,
      };
    },
    'getAllThings/err': (state: IThingQdosState, { payload }: PayloadAction<IInterceptorError>) => {},

    getAllPostsByItemId: (state: IThingQdosState, { payload }: PayloadAction<TThingQdosGetAllPostsByItemIdPayload>) => {},
    'getAllPostsByItemId/ok': (state: IThingQdosState, { payload }: PayloadAction<TThingQdosGetAllPostsByItemIdResponseOk>) => {
      return {
        ...state,
        posts: payload,
      };
    },
    'getAllPostsByItemId/err': (state: IThingQdosState, { payload }: PayloadAction<IInterceptorError>) => {},
  },
});

export const thingQdosActions = thingQdosSlice.actions;
export const thingQdosReducer = thingQdosSlice.reducer;