import { ReactElement, SyntheticEvent } from 'react';
import { Drawer, List, ListItemButton, Icon } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { TABS } from '../../helpers/constans';
import Logo from '../logo';

import { styles } from './sidebar.styles';

interface IProps {
  isPermanent: boolean;
}

const Sidebar = ({ isPermanent }: IProps): ReactElement => {
  const navigate = useNavigate();
  const permission = useSelector((state: RootState) => state.auth.permission);
  const { pathname } = useLocation();

  const handleTabs = ({ currentTarget }: SyntheticEvent<HTMLElement>): void => {
    navigate(`${currentTarget.dataset.route?.toLowerCase()}`, { replace: true });
  };
  return (
    <Drawer
      hideBackdrop={true}
      variant={isPermanent ? 'permanent' : undefined}
      PaperProps={{ sx: styles.drawer(isPermanent) }}
    >
      <Logo />
      <List>
        {TABS.map((el, idx) => {
          const isActive = pathname.split('/')[1] === el.route.split('/')[1];
          return +el.permission <= +permission ? (
            <ListItemButton key={idx} sx={styles.item(isActive)} data-route={el.route} onClick={handleTabs}>
              <Icon sx={styles.itemIcon}>{el.icon}</Icon>

              {el.name}
            </ListItemButton>
          ) : null;
        })}
      </List>
    </Drawer>
  );
};
export default Sidebar;
