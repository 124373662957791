import { sharedColors, sharedFonts } from '../../assets/shared.styles';

export const styles = {
  mainBox: {
    height: '5.5rem',
    padding: '1rem 2rem',
    display: 'flex',
    alignItems: 'center',
  },
  textAccent: {
    fontFamily: sharedFonts.main,
    fontSize: '2.7rem',
    fontWeight: 700,
    letterSpacing: '0.05rem',
    color: '#ea0606',
  },
  text: {
    fontFamily: sharedFonts.main,
    fontSize: '2rem',
    fontWeight: 500,
    letterSpacing: '0.05rem',
    color: sharedColors.black,
  },
};
