import { sharedColors, sharedShadow, sharedSizes } from '../../assets/shared.styles';

export const styles = {
  header: {
    boxSizing: 'border-box',
    position: 'sticky',
    left: 'auto',
    right: '0px',
    top: '0.75rem',
    mb: '1rem',
    minHeight: '3.7rem',
    border: 'none',
    borderRadius: sharedSizes.borderRadius,
    padding: '0.5rem 0px',
    bgcolor: sharedColors.bgWhite,
    color: 'rgb(52, 71, 103)',
    backdropFilter: 'saturate(200%) blur(1.8rem)',
    boxShadow: sharedShadow.box,
  },
  toolbar: {
    display: 'flex',
    justifyContent: {
      xs: 'space-between',
      lg: 'end',
    },
    '&.MuiToolbar-root': {
      minHeight: '3.6rem',
    },
  },
  home: {
    fontSize: '0.875rem',
    fontWeight: '500',
    letterSpacing: '0.01rem',
    color: sharedColors.softBlack,
    mb: '0.4rem',
  },
  homeIcon: {
    color: sharedColors.black,
    mr: '0.4rem',
  },
  logout: {
    color: sharedColors.black,
  },
  logoutText: {
    fontSize: '0.875rem',
    letterSpacing: '0.02rem',
    fontWeight: 500,
    textTransform: 'none',
  },
};
