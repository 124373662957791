import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './app/redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import { requestInterceptors, responseInterceptors } from './app/helpers/interceptors';
import { ThemeProvider } from '@emotion/react';
import { customTheme } from './app/assets/theme';
import { SnackbarProvider } from 'notistack';
import App from './app/App';

const container: HTMLElement = document.getElementById('root') as HTMLElement;
const root: Root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={customTheme}>
        <Router>
          <SnackbarProvider maxSnack={3}>
            <App />
          </SnackbarProvider>
        </Router>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

responseInterceptors();
requestInterceptors();
