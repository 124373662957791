import { Fragment, ReactElement, SyntheticEvent } from 'react';
import { Alert, AlertTitle, Box, Typography, Icon, Button } from '@mui/material';

import { styles } from './dashboard.styles';
import { CARDS } from '../../helpers/constans';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
// import SystemEventCard from '../../components/system-event-card';

const Dashboard = (): ReactElement => {
  const navigate = useNavigate();
  const permission = useSelector((state: RootState) => state.auth.permission);

  const handleCardsClic = ({ currentTarget }: SyntheticEvent<HTMLButtonElement>) => {
    navigate(`${currentTarget.dataset.route?.toLowerCase()}`, { replace: true });
  };
  return (
    <Fragment>
      <Alert severity="info" sx={styles.alert}>
        <AlertTitle>Info</AlertTitle>
        First of all, add contacts informations
      </Alert>
      <Box sx={styles.cardContainer}>
        {CARDS.map((el, idx) =>
          +el.permission <= +permission ? (
            <Button key={idx} sx={styles.cardBox} data-route={el.route} onClick={handleCardsClic}>
              <Icon sx={styles.cardIcon}>{el.icon}</Icon>
              <Typography sx={styles.cardTitle}>{el.name}</Typography>
              <Typography sx={styles.cardSubTitle}>{el.description}</Typography>
            </Button>
          ) : null
        )}
        {/* <SystemEventCard /> */}
      </Box>
    </Fragment>
  );
};
export default Dashboard;
