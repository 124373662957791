import axios from 'axios';
import { URI_ASSOCIATIONS } from '../helpers/constans';
import {
  IAssociationPutPayload,
  IAssociationPutResponseOk,
  TAssociationGetAllPayload,
  IAssociationGetResponseOk,
} from '../types/@association';

export const fetchAssociationGet = (page: TAssociationGetAllPayload): Promise<IAssociationGetResponseOk> => {
  return axios.get(`${URI_ASSOCIATIONS}?page=${page}`);
};

export const fetchAssociationPut = (payload: IAssociationPutPayload): Promise<IAssociationPutResponseOk> => {
  return axios.post(URI_ASSOCIATIONS, payload);
};
