import { TEventGetAllPayload } from './../types/@event';
import axios from 'axios';
import { URI_EVENT } from '../helpers/constans';
import { IEventGetResponseOk, IEventPutPayload, IEventPutResponseOk } from '../types/@event';

export const fetchEventGet = (page: TEventGetAllPayload): Promise<IEventGetResponseOk> => {
  return axios.get(`${URI_EVENT}?page=${page}`);
};

export const fetchEventPut = (payload: IEventPutPayload): Promise<IEventPutResponseOk> => {
  return axios.post(`${URI_EVENT}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
