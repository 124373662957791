import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchPostsByItemId, fetchThingQdosGetAll } from '../../services/thing-qdos.service';
import { IThingQdosGetAllResponseOk, TThingQdosGetAllPayload, TThingQdosGetAllPostsByItemIdPayload } from '../../types/@thingQdos';
import { notificationActions } from '../slices/notification.slice';
import { thingQdosActions } from '../slices/thing-qdos';

function* getAllThingsQdos({payload}:PayloadAction<TThingQdosGetAllPayload>) {
  try {
    const fetchResult: IThingQdosGetAllResponseOk = yield call(fetchThingQdosGetAll, payload);
    yield put({type: thingQdosActions['getAllThings/ok'].type, payload: fetchResult})
  } catch(err) {
    yield put({ type: thingQdosActions['getAllThings/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* getAllPostsByItemIdQdos({payload}:PayloadAction<TThingQdosGetAllPostsByItemIdPayload>) {
  try {
    const fetchResult: IThingQdosGetAllResponseOk = yield call(fetchPostsByItemId, payload);
    yield put({type: thingQdosActions['getAllPostsByItemId/ok'].type, payload: fetchResult})
  } catch(err) {
    yield put({ type: thingQdosActions['getAllPostsByItemId/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

export function* watchThingQdos() {
  yield takeLatest(thingQdosActions.getAllThings, getAllThingsQdos);
  yield takeLatest(thingQdosActions.getAllPostsByItemId, getAllPostsByItemIdQdos);
}