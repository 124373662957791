import { Fragment, ReactElement } from 'react';
import { CircularProgress } from '@mui/material';
import { ISpinnerProps } from '../../types/@spinner';

import { styles } from './spinner.styles';

const Spinner = (props: ISpinnerProps | undefined): ReactElement => {
  return (
    <Fragment>
      <CircularProgress sx={props?.sx ? props.sx : styles.spinner(props)} />
    </Fragment>
  );
};

export default Spinner;
