import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  fetchThingGetAll,
  fetchThingGetExternalKey,
  fetchThingPut,
  fetchThingGet,
  fetchThingUpdate,
  fetchThingResolvePending,
} from '../../services/thing.service';
import {
  TThingGetAllPayload,
  IThingGetExternalKeyResponseOk,
  IThingGetExternalKeyPayload,
  IThingPutPayload,
  IThingPutResponseOk,
  IThingGetResponseOk,
  IThingInformation,
  IThingUpdateResponseOk,
  IThingUpdatePayload,
  IThingResolvePendingPayload,
  IThingGetAllResponseOk,
} from '../../types/@thing';
import { thingActions } from '../slices/thing.slice';
import { notificationActions } from '../slices/notification.slice';
import { RootState } from '../store';
import { globalActions } from '../slices/global.slice';

function* thingGetExternalKey({ payload }: PayloadAction<IThingGetExternalKeyPayload>) {
  try {
    const [fetchResult]: IThingGetExternalKeyResponseOk[] = yield call(
      fetchThingGetExternalKey,
      payload.amount
    );
    yield put({ type: thingActions['getExternalKey/ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: thingActions['getExternalKey/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* putThing({ payload }: PayloadAction<IThingPutPayload>) {
  try {
    const fetchResult: IThingPutResponseOk = yield call(fetchThingPut, payload);
    yield put({ type: thingActions['putThing/ok'].type, payload: fetchResult });
    yield put({ type: thingActions.thingCreateFormClear.type });
    yield put({ type: thingActions.isCreated.type, payload: true });
  } catch (err) {
    yield put({ type: thingActions['putThing/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* getAllThings({ payload }: PayloadAction<TThingGetAllPayload>) {
  try {
    const fetchResult: IThingGetAllResponseOk = yield call(fetchThingGetAll, payload);
    yield put({ type: thingActions['getAllThings/ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: thingActions['getAllThings/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* getThing({ payload }: PayloadAction<string>) {
  try {
    const fetchResult: IThingGetResponseOk = yield call(fetchThingGet, payload);
    yield put({ type: thingActions['getThing/ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: thingActions['getThing/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* updateThing({ payload }: PayloadAction<IThingUpdatePayload>) {
  const thingInformation: IThingInformation = yield select((state: RootState) => state.things.current);
  if (payload && payload instanceof FormData) {
    payload.append('id', thingInformation._id);
    payload.append('name', thingInformation.name);
    payload.append('description', thingInformation.description);
    payload.append('status', thingInformation.status);
    payload.append('ownerId', thingInformation.ownerId);
  }
  try {
    const fetchResult: IThingUpdateResponseOk = yield call(fetchThingUpdate, payload || thingInformation);
    yield put({ type: thingActions['updateThing/ok'].type, payload: fetchResult });
    yield put({ type: thingActions.isUpdated.type, payload: true });
  } catch (err) {
    yield put({ type: thingActions['updateThing/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* resolvePendingThing({ payload }: PayloadAction<IThingResolvePendingPayload>) {
  try {
    const fetchResult: string = yield call(fetchThingResolvePending, payload);
    yield put({ type: thingActions['resolvePendingThing/Ok'].type, payload: fetchResult });
    yield put({ type: globalActions.setPendingThingId.type, payload: undefined });
  } catch (err) {
    yield put({ type: thingActions['resolvePendingThing/Err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

export function* watchThing() {
  yield takeLatest(thingActions.getExternalKey, thingGetExternalKey);
  yield takeLatest(thingActions.putThing, putThing);
  yield takeLatest(thingActions.getAllThings, getAllThings);
  yield takeLatest(thingActions.getThing, getThing);
  yield takeLatest(thingActions.updateThing, updateThing);
  yield takeLatest(thingActions.resolvePendingThing, resolvePendingThing);
}
