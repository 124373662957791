import { ReactElement, useEffect } from 'react';
import Spinner from '../../components/spinner';
import { Box, Button, Typography } from '@mui/material';
import { ROUTES, URN_QDOS_S3 } from '../../helpers/constans';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { thingQdosActions } from '../../redux/slices/thing-qdos';

import { styles } from './things-qdos-media.styles';

const ThingsQdosMedia = (): ReactElement => {
  const { posts } = useSelector((state: RootState) => state.thingsQdos);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: itemId } = useParams();

  useEffect(() => {
    if (!itemId) return;
    dispatch(thingQdosActions.getAllPostsByItemId(itemId));
  }, [dispatch, itemId]);

  const isCurrentThing = true;

  const handleBack = (): void => {
    navigate(ROUTES.things);
  };

  return (
    <Box sx={styles.container}>
      {!isCurrentThing ? (
        <Spinner mt="2rem" />
      ) : (
        <Box sx={styles.mainBox}>
          <Box sx={styles.mainTitleBox}>
            <Box>
              <Typography sx={styles.mainTitle}>Qdos media items</Typography>
              <Typography sx={styles.mainSubTitle}>
                These are posts from qdos portal
              </Typography>
            </Box>
            <Box sx={styles.buttonBox}>
              <Button sx={styles.backButton} onClick={handleBack}>
                back
              </Button>
            </Box>
          </Box>
          <Box sx={styles.mainContentBox}>
            {posts.map((el) => {
              return (
                <Box
                  component="img"
                  sx={styles.postImage}
                  src={`${URN_QDOS_S3}${el.post.imageId}`}
                />
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ThingsQdosMedia;
