import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactQRCode from 'react-qr-code';
import QRCode from 'qrcode';
import { thingActions } from '../../redux/slices/thing.slice';
import { Box, Button, Typography, InputBase } from '@mui/material';
import { URI_TARGET_PAGE } from '../../helpers/constans';
import { RootState } from '../../redux/store';
import { IFiles } from '../../types/@shared';

import { styles } from './thing-create-qr.styles';

interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  thingBuffer: IFiles | undefined;
  setThingBuffer: (data: any) => void;
}

const ThingCreateQR = ({ setStep, thingBuffer, setThingBuffer }: IProps): ReactElement => {
  const dispatch = useDispatch();
  const obtainedExternalKey: string | undefined = useSelector(
    (state: RootState) => state.things.obtainedExternalKey
  );
  const thingCreateForm = useSelector((state: RootState) => state.things.thingCreateForm);
  const [qrDataURL, setQrDataURL] = useState<string>('');

  const createQrFile = async () => {
    const src = await QRCode.toDataURL(`${URI_TARGET_PAGE}/${obtainedExternalKey}`);
    setQrDataURL(src);
  };

  useEffect(() => {
    dispatch(thingActions.getExternalKey({ amount: 1 }));
  }, [dispatch]);

  useEffect(() => {
    obtainedExternalKey && createQrFile();
  });

  const handleNext = (): void => {
    if (obtainedExternalKey?.length && thingBuffer) {
      dispatch(thingActions.isCreated(false));
      const formData = new FormData();
      formData.append('externalKey', obtainedExternalKey);
      formData.append('name', thingCreateForm.name);
      formData.append('description', thingCreateForm.description);
      formData.append('image', thingBuffer.image);
      formData.append('status', thingCreateForm.status);
      dispatch(thingActions.putThing(formData));
      setStep(() => {
        return 2;
      });
    }
  };
  const handleBack = (): void => {
    setStep(() => {
      return 0;
    });
  };

  const handleCopy = (): void => {
    navigator.clipboard.writeText(`${URI_TARGET_PAGE}/${obtainedExternalKey}`);
  };

  return (
    <Box sx={styles.mainBox}>
      <Box sx={styles.imageBox}>
        <Box sx={styles.qr}>
          <ReactQRCode value={`${URI_TARGET_PAGE}/${obtainedExternalKey}`} size={200} />
        </Box>
        <Box sx={styles.downloadButton} component="a" href={qrDataURL} download="qr.png">
          download
        </Box>
      </Box>
      <Box>
        <Typography sx={styles.contentTitle}>
          Now you have an oportunity to download QR in png format, print it and stik it to the selected item.
          Here is the link which is hidden behind this QR-code. Having pressed the save button, you can use
          this link to test it and to see the details which will be reflected when you use the QR-code.
        </Typography>
        <Box sx={styles.copyBox}>
          <InputBase
            sx={styles.copyLink}
            name="status"
            disabled
            value={`${URI_TARGET_PAGE}/${obtainedExternalKey}`}
          />
          <Button sx={styles.copyButton} onClick={handleCopy}>
            copy
          </Button>
        </Box>
      </Box>
      <Box sx={styles.buttonsBox}>
        <Button sx={styles.backButton} onClick={handleBack}>
          back
        </Button>
        <Button sx={styles.buttonNext} onClick={handleNext}>
          save
        </Button>
      </Box>
    </Box>
  );
};
export default ThingCreateQR;
