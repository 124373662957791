import { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../helpers/constans';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { organisationActions } from '../../redux/slices/organisation.slice';
import { Box, Button, Typography } from '@mui/material';
import OrganisationControlForm from '../../components/organisation-control-form';
import { IFiles } from '../../types/@shared';

import { styles } from './organisations-control.styles';

const OrganisationControl = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = useState<IFiles>({
    image: '',
  });

  const handleDrop = useCallback((acceptedFiles: any) => {
    setFile((state: any) => {
      return {
        image: acceptedFiles[0],
      };
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive, open, acceptedFiles } = useDropzone({
    noClick: true,
    onDrop: handleDrop,
  });

  const handleBack = (): void => {
    navigate(ROUTES.organisations);
  };
  const handleSave = (): void => {
    const formData = new FormData();
    for (const key in file) {
      formData.append(key, file[`${key}`]);
    }
    dispatch(organisationActions.putOrganisations(formData));
    navigate(ROUTES.organisations);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.mainBox}>
        <Box sx={styles.mainTitleBox}>
          <Box>
            <Typography sx={styles.mainTitle}>Make the changes below</Typography>
            <Typography sx={styles.mainSubTitle}>
              Add the actual information about your organisation
            </Typography>
          </Box>
          <Box sx={styles.buttonBox}>
            <Button sx={styles.backButton} onClick={handleBack}>
              back
            </Button>
            <Button sx={styles.addButton} onClick={handleSave}>
              save
            </Button>
          </Box>
        </Box>
        <Box sx={styles.mainContentBox}>
          <Box sx={styles.contentImage}>
            <Typography sx={styles.contentTitle}>Logo or Image</Typography>
            <Box
              sx={styles.dropzone(acceptedFiles[0] && URL.createObjectURL(acceptedFiles[0]), isDragActive)}
              {...getRootProps()}
              onClick={open}
            >
              <input {...getInputProps()} />
              {!acceptedFiles[0] && (
                <Typography sx={styles.dropzoneText}>
                  Drag 'n' drop some files here, or click to select files
                </Typography>
              )}
            </Box>
          </Box>
          <OrganisationControlForm />
        </Box>
      </Box>
    </Box>
  );
};
export default OrganisationControl;
