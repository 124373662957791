import { sharedColors, sharedTitle, sharedShadow, sharedList } from '../../assets/shared.styles';

export const styles = {
  container: {
    pt: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
  },
  mainBox: sharedList.mainBox,
  mainTitleBox: {...sharedList.mainTitleBox, alignItems:'flex-end'},
  mainTitle: sharedTitle.categoryTitle,
  mainSubTitle: {...sharedTitle.categorySubTitle, pl:'.5rem'},
  tabsBox: {
    width: 'fit-content',
    position: 'relative',
    padding: '.45rem .65rem',
    mb: '1rem',
    borderRadius: '0.75rem',
    backgroundColor: '#f8f9fa',
  },
  tabsButton: ({ isSelected, accentColor}:{isSelected: boolean, accentColor:string}) => {
    return {
      width: '125px',
      padding: '4px',
      position: 'relative',
      color: isSelected ? '#344767' : '#67748e',
      fontSize: '0.875rem',
      fontWeight: 500,
      letterSpacing: '0.02rem',
      alignItems: 'flex-end',
      borderRadius: '4px',
      zIndex: 1,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:first-child' : {
        mr: '1.2rem'
      },
      '&>span': isSelected
        ? {
          fontSize: '1rem',
          fontWeight: 900,
          mr: '0.05rem',
          mb: '-.05rem',
          color: isSelected ? accentColor : 'inherits',
          backgroundColor: 'transparent'
        }
        : {
          fontSize: 'inherits',
          fontWeight: 'inherits',
          mr: '0',
          mb: '0',
          color: isSelected ? accentColor : 'inherits',
          backgroundColor: 'transparent'
        }
    }
  },
  tabsIndicator: (isQdosItems: boolean) => {
    return {
      display: 'inline-block',
      height: '81%',
      width: '48%',
      position: 'absolute',
      top: '50%',
      left: !isQdosItems ? '.25rem' : '50%',
      transform: 'translate(0, -50%)',
      borderRadius: '.5rem',
      boxShadow: 'rgb(221, 221, 221) 0rem 0.0625rem 0.3125rem 0.0625rem',
      backgroundColor: '#fff',
      transition: 'all 250ms linear'
  }},
  addButton: {
    color: '#fff',
    backgroundImage: sharedColors.gradientInfo,
    boxShadow: sharedShadow.button,
    minWidth: '6rem',
    height: '3rem',
    padding: '0.6rem 2rem',
    fontSize: '0.75rem',
    lineHeight: 1.4,
    letterSpacing: '0.02rem',
    fontWeight: 700,
    borderRadius: '0.5rem',
  },
};
