import { IInterceptorError } from './@interceptor';

export interface INotificationState {
  noto: INotification | undefined;
}

export enum ENotificationType {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

export interface INotification {
  type: ENotificationType;
  body: IInterceptorError;
}
