import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { organisationActions } from '../slices/organisation.slice';
import {
  IOrganisationGetResponseOk,
  IOrganisationPayload,
  IOrganisationPutResponseOk,
  IOrganisationControl,
  TOrganisationGetAllPayload,
} from '../../types/@organisation';
import { fetchOrganisationGet, fetchOrganisationPut } from '../../services/organisation.service';
import { RootState } from '../store';
import { notificationActions } from '../slices/notification.slice';

function* organisationGetData({ payload }: PayloadAction<TOrganisationGetAllPayload>) {
  try {
    const fetchResult: IOrganisationGetResponseOk = yield call(fetchOrganisationGet, payload);
    yield put({ type: organisationActions['getOrganisations/ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: organisationActions['getOrganisations/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* organisationPutData({ payload }: PayloadAction<IOrganisationPayload>) {
  const contentForms: IOrganisationControl = yield select((state: RootState) => state.organisations.control);
  for (const key in contentForms) {
    payload.append(key, contentForms[`${key}`]);
  }
  try {
    const fetchResult: IOrganisationPutResponseOk = yield call(fetchOrganisationPut, payload);
    yield put({ type: organisationActions['putOrganisations/ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: organisationActions['putOrganisations/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

export function* watchOrganisation() {
  yield takeLatest(organisationActions.putOrganisations, organisationPutData);
  yield takeLatest(organisationActions.getOrganisations, organisationGetData);
}
