import { sharedFonts, sharedColors, sharedInputs } from '../../assets/shared.styles';

export const styles = {
  formBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  formLabel: {
    mb: '0.5rem',
    ml: '0.3rem',
    fontFamily: sharedFonts.main,
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '1.2',
    letterSpacing: '0.05rem',
    color: sharedColors.black,
  },
  input: sharedInputs.main,
  select: sharedInputs.select,
  externalKeyFormControl: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'auto',
    columnGap: {
      xs: 'none',
      md: '1rem',
    },
    mb: '1rem',
  },
  externalKeyFormLabel: {
    gridColumn: '1/ span 4',
    mb: '0.5rem',
    ml: '0.3rem',
    fontFamily: sharedFonts.main,
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '1.2',
    letterSpacing: '0.05rem',
    color: sharedColors.black,
  },
  externalKeyInput: [
    ...sharedInputs.main,
    {
      gridColumn: {
        xs: '1/ span 4',
        md: '1/ 2',
      },
    },
  ],
  externalKeyNotificationBox: {
    display: 'flex',
    gridColumn: {
      xs: '1/ span 4',
      md: '2/ span 3 ',
    },
  },
  notificationIconBox: {
    minWidth: '3rem',
    height: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.5rem',
    backgroundColor: 'rgba(245, 57, 57, 0.03)',
    mr: '0.8rem',
  },
  notificationText: {
    fontSize: '0.875rem',
    lineHeight: '1.5',
    letterSpacing: '0.01rem',
    color: sharedColors.grey,
  },
  notificationIcon: {
    fontSize: '1.5rem',
    backgroundClip: 'text',
    backgroundImage: sharedColors.gradientWarning,
    WebkitTextFillColor: 'transparent',
  },
};
