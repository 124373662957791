import { ReactElement, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Pagination,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../helpers/constans';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

import { styles } from './teams-list.styles';
import { teamActions } from '../../redux/slices/team.slice';

const TeamsList = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { all: teams, pages } = useSelector((state: RootState) => state.teams);

  const handleCreateTeam = () => {
    navigate(ROUTES.teamsCreate);
  };

  useEffect(() => {
    dispatch(teamActions.getTeams());
  }, [dispatch]);

  const handlePagination = (event: any, page: number) => {
    dispatch(teamActions.getTeams(page));
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.mainBox}>
        <Box sx={styles.mainTitleBox}>
          <Box>
            <Typography sx={styles.mainTitle}>Teams</Typography>
            <Typography sx={styles.mainSubTitle}>Create new teams</Typography>
          </Box>
          <Button sx={styles.addButton} onClick={handleCreateTeam}>
            + create team
          </Button>
        </Box>
        <TableContainer sx={styles.tableContainer}>
          <Table>
            <TableHead sx={styles.tableHead}>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>logo</TableCell>
                <TableCell>name</TableCell>
                <TableCell>twitter id</TableCell>
                <TableCell>instagram id</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={styles.tableBody}>
              {teams.map((el, idx) => {
                return (
                  <TableRow key={el._id}>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>
                      <Box sx={styles.thingImage(el.image?.path)} />
                    </TableCell>
                    <TableCell>{el.name}</TableCell>
                    <TableCell>{el.twitterId}</TableCell>
                    <TableCell>{el.instagramId}</TableCell>
                  </TableRow>
                );
              })}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={styles.paginationBox}>
          <Pagination count={pages} onChange={handlePagination} />
        </Box>
      </Box>
    </Box>
  );
};

export default TeamsList;
