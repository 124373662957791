import {
  sharedSizes,
  sharedShadow,
  sharedColors,
  sharedFonts,
  sharedButton,
  sharedInputs,
} from '../../assets/shared.styles';

export const styles = {
  container: {
    pt: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
  },
  mainBox: {
    padding: '1.3rem 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainTitleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 1.3rem 1.3rem 1.5rem',
  },
  mainTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '1.5rem',
    lineHeight: '1.4',
    fontWeight: 500,
    letterSpacing: '0.05rem',
    color: sharedColors.black,
    mb: '0.5rem',
  },
  mainSubTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.6',
    letterSpacing: '0.05rem',
    color: sharedColors.grey,
    mb: '1rem',
  },
  buttonBox: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr',
    },
    gridTemplateRows: '1fr 1fr',
    rowGap: '1rem',
    columnGap: '1rem',
  },
  backButton: { ...sharedButton.back, maxHeight: '2.6rem' },
  addButton: { ...sharedButton.blue, maxHeight: '2.6rem' },
  mainContentBox: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: 'repeat(3, 1fr)',
    },
    gap: '1.5rem',
  },

  contentImage: {
    padding: '1.3rem',
    width: '100%',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: sharedShadow.box,
  },
  contentForms: {
    gridColumn: { xs: 'unset', md: '2 / 4' },
    padding: '1.3rem',
    width: '100%',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: sharedShadow.box,
  },
  contentTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '1.25rem',
    lineHeight: '1.7',
    fontWeight: 700,
    letterSpacing: '0.05rem',
    color: sharedColors.black,
    mb: '1.5rem',
  },
  contentTitle2: {
    fontFamily: sharedFonts.main,
    fontSize: '1.25rem',
    lineHeight: '1.7',
    fontWeight: 500,
    letterSpacing: '0.05rem',
    color: sharedColors.black,
    mb: '1.5rem',
  },
  formLabel: {
    mb: '0.5rem',
    ml: '0.3rem',
    fontFamily: sharedFonts.main,
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '1.2',
    letterSpacing: '0.05rem',
    color: sharedColors.black,
  },
  input: sharedInputs.main,
  dropzone: (img: string, isDragActive: boolean) => {
    return {
      width: '100%',
      height: '14rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.9rem',
      color: sharedColors.softBlack,
      border: `0.1rem dashed ${!isDragActive ? sharedColors.inputBorder : '#2152ff'}`,
      borderRadius: '0.5rem',
      cursor: 'pointer',
      boxShadow: isDragActive ? sharedShadow.dropzone : 'none',
      backgroundImage: `url(${img})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    };
  },
  dropzoneText: {
    maxWidth: '70%',
  },
  dropzoneFile: {
    maxWidht: '4rem',
    maxHeight: '3rem',
  },
};
