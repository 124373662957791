import { IContact } from './@contact';
import { IOrganisation } from './@organisation';
import { IImage } from './@shared';
import { IThingInformation } from './@thing';

export interface ITemplateState {
  all: ITemplateInformationGetAllResponseOk[] | [];
  control: ITemplateControl;
  current: ITemplateInformation | undefined;
  isCreated: boolean;
  isUpdated: boolean;
  isUpdatedExternalKey: boolean;
  pages: number | undefined;
}

export interface ITemplateControl {
  organisationId: string;
  name: string;
  description: string;
  externalKeyAmount: number;
}

export interface ITemplateInformation {
  _id: string;
  name: string;
  description: string;
  image: IImage;
  things: IThingInformation[];
  createdAt: string;
  updatedAt: string;
}

export interface ITemplatePayload {
  [key: string]: any;
}

export type TTemplateGetPayload = string;
export type TTemplateGetAllPayload = number | undefined;

export interface ITemplatePutExternalKeyPayload {
  templateId: string;
  amount: number;
}

export interface ITemplateInformationGetAllResponseOk {
  _id: string;
  name: string;
  description: string;
  image: IImage;
  organisationId: string;
  organisationsInfo: IOrganisation & {
    managerInfo: IContact;
  };
}

export interface ITemplateGetAllResponseOk {
  data: ITemplateInformationGetAllResponseOk[];
  pages: number;
}

export interface ITemplatePutResponseOk {}
export interface ITemplateGetResponseOk extends ITemplateInformation {}
export interface ITemplatePutExternalKeyResponseOk {}

export enum ETemplateType {
  singleQR = 1,
  coupleQR = 2,
}
