import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { targetActions } from '../slices/target.slice';
import { ITargetPayload } from '../../types/@target';
import { fetchTarget } from '../../services/target.service';
import { notificationActions } from '../slices/notification.slice';

function* getTarget({ payload }: PayloadAction<ITargetPayload>) {
  try {
    const fetchResult: string = yield call(fetchTarget, payload.id);
    yield put({ type: targetActions['getTarget/ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: targetActions['getTarget/err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

export function* watchTarget() {
  yield takeLatest(targetActions.getTarget, getTarget);
}
