import {
  sharedButton,
  sharedColors,
  sharedFonts,
  sharedInputs,
  sharedPage,
  sharedShadow,
  sharedSizes,
} from '../../assets/shared.styles';

export const styles = {
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '1.3rem',
    width: '80%',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    boxShadow: sharedShadow.box,
  },
  spinnerBox: sharedPage.spinnerBox,
  form: { width: '100%' },
  formLabel: {
    mb: '0.5rem',
    ml: '0.3rem',
    fontFamily: sharedFonts.main,
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '1.2',
    letterSpacing: '0.05rem',
    color: sharedColors.black,
  },
  input: sharedInputs.main,
  button: sharedButton.blue,
  buttonsBox: {
    width: '100%',
    gridColumn: {
      xs: '1 / 2',
      md: '1 / 3',
    },
    display: 'flex',
    justifyContent: 'end',
  },
  Box: {
    width: '100%',
    gridColumn: {
      xs: '1 / 2',
      md: '1 / 3',
    },
    display: 'flex',
    justifyContent: 'end',
  },
  backButton: { ...sharedButton.back, mr: '1rem' },
  buttonNext: sharedButton.dark,
  contentBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2 ,1fr) 1rem repeat(2 ,1fr)',
    width: '100%',
    minHeight: '15rem',
    mb: '1rem',
  },
  titleBox: {
    fontFamily: sharedFonts.main,
    fontSize: '1.25rem',
    lineHeight: '1.7',
    fontWeight: 500,
    letterSpacing: '0.05rem',
    color: sharedColors.black,
    mb: '1.5rem',
  },
  listBox: {
    gridColumn: 'span 2',
    overflowY: 'scroll',
    fontSize: '0.875rem',
    lineHeight: 1.5,
    letterSpacing: '0.03rem',
    fontWeight: 500,
    color: sharedColors.black,
  },
  divider: {
    width: '0.08rem',
    opacity: '0.25',
    borderWidth: '0 0 medium',
    borderStyle: 'solid solid none',
    borderColor: 'rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12) currentcolor',
    backgroundColor: 'transparent',
    backgroundImage:
      'linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.5), rgba(52, 71, 103, 0)) !important',
  },
  formBox: {
    gridColumn: 'span 2',
    justifySelf: 'center',
    alignSelf: 'center',
    fontFamily: sharedFonts.main,
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: '1.4',
    color: sharedColors.black,
  },
  contentImage: {
    padding: '1.3rem',
    width: '100%',
    borderRadius: sharedSizes.borderRadius,
    bgcolor: sharedColors.bgWhite,
    display: 'flex',
    flexDirection: 'column',
  },
  contentTitle: {
    fontFamily: sharedFonts.main,
    fontSize: '1.25rem',
    lineHeight: '1.7',
    fontWeight: 700,
    letterSpacing: '0.05rem',
    color: sharedColors.black,
    mb: '1.5rem',
  },
  dropzone: (img: string, isDragActive: boolean) => {
    return {
      width: '100%',
      height: '14rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.9rem',
      color: sharedColors.softBlack,
      border: `0.1rem dashed ${!isDragActive ? sharedColors.inputBorder : '#2152ff'}`,
      borderRadius: '0.5rem',
      cursor: 'pointer',
      boxShadow: isDragActive ? sharedShadow.dropzone : 'none',
      backgroundImage: `url(${img})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    };
  },
  dropzoneText: {
    maxWidth: '70%',
  },
  dropzoneFile: {
    maxWidht: '4rem',
    maxHeight: '3rem',
  },
};
