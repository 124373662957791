import { sharedButton, sharedColors } from '../../assets/shared.styles';

export const styles = {
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  error: {
    fontSize: '4rem',
    lineHeight: '4.8rem',
    fontWeight: 700,
    letterSpacing: '0.1rem',
    backgroundImage: sharedColors.gradientError,
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  title: {
    fontSize: '2.25rem',
    lineHeight: '2.9rem',
    fontWeight: 700,
    backgroundImage: sharedColors.gradientDark,
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    mb: '0.5rem',
  },
  subTitle: {
    fontSize: '1.25rem',
    lineHeight: '2rem',
    letterSpecing: '0.1rem',
    color: sharedColors.grey,
    mb: '2rem',
  },
  button: [
    sharedButton.dark,
    {
      maxWidth: '170px',
      mb: '1.5rem',
    },
  ],
};
