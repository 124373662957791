import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../helpers/constans';
import { IFiles } from '../../types/@shared';
import { useDropzone } from 'react-dropzone';
import TeamCreateForm from '../../components/team-create-form';
import { useDispatch, useSelector } from 'react-redux';
import { teamActions } from '../../redux/slices/team.slice';
import ButtonWithLoading from '../../components/button-with-loading';

import { styles } from './teams-create.styles';
import { RootState } from '../../redux/store';

const TeamsCreate = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = useState<IFiles>({
    image: '',
  });
  const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false);
  const isCreated = useSelector((state: RootState) => state.teams.isCreated);

  const handleDrop = useCallback((acceptedFiles: any) => {
    setFile((state: any) => {
      return {
        image: acceptedFiles[0],
      };
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive, open, acceptedFiles } = useDropzone({
    noClick: true,
    onDrop: handleDrop,
  });

  const handleBack = () => {
    navigate(ROUTES.teams);
  };
  const handleSave = () => {
    setIsLoadingBtn(() => true);
    const formData = new FormData();
    for (const key in file) {
      formData.append(key, file[`${key}`]);
    }
    dispatch(teamActions.putTeams(formData));
  };

  useEffect(() => {
    if (isCreated) {
      dispatch(teamActions.isCreated(false));
      setIsLoadingBtn(() => false);
      dispatch(
        teamActions.teamControl({
          name: '',
          twitterId: '',
          instagramId: '',
        })
      );
      navigate(ROUTES.teams);
    }
  }, [isCreated, navigate, dispatch]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.mainBox}>
        <Box sx={styles.mainTitleBox}>
          <Box>
            <Typography sx={styles.mainTitle}>New Team</Typography>
            <Typography sx={styles.mainSubTitle}>Add the actual information about your team</Typography>
          </Box>
          <Box sx={styles.buttonBox}>
            <Button sx={styles.backButton} onClick={handleBack}>
              back
            </Button>
            <ButtonWithLoading
              buttonName="save"
              styles={styles.addButton}
              isLoading={isLoadingBtn}
              handler={handleSave}
            />
          </Box>
        </Box>
        <Box sx={styles.mainContentBox}>
          <Box sx={styles.contentImage}>
            <Typography sx={styles.contentTitle}>Logo or Image</Typography>
            <Box
              sx={styles.dropzone(acceptedFiles[0] && URL.createObjectURL(acceptedFiles[0]), isDragActive)}
              {...getRootProps()}
              onClick={open}
            >
              <input {...getInputProps()} />
              {!acceptedFiles[0] && (
                <Typography sx={styles.dropzoneText}>
                  Drag 'n' drop some files here, or click to select files
                </Typography>
              )}
            </Box>
          </Box>
          <TeamCreateForm />
        </Box>
      </Box>
    </Box>
  );
};

export default TeamsCreate;
