import { sharedFonts, sharedColors, sharedInputs } from '../../assets/shared.styles';

export const styles = {
  form: { width: '100%' },
  formLabel: {
    mb: '0.5rem',
    ml: '0.3rem',
    fontFamily: sharedFonts.main,
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '1.2',
    letterSpacing: '0.05rem',
    color: sharedColors.black,
  },
  input: sharedInputs.main,
  select: sharedInputs.select,
};
