import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchTemplateGet,
  fetchTemplateGetAll,
  fetchTemplatePut,
  fetchTemplatePutExternalKey,
} from '../../services/template.service';
import {
  ITemplateGetAllResponseOk,
  ITemplatePayload,
  ITemplatePutExternalKeyPayload,
  TTemplateGetAllPayload,
} from '../../types/@template';
import { templateActions } from '../slices/template.slice';
import { RootState } from '../store';
import { notificationActions } from '../slices/notification.slice';

function* templatePutData({ payload }: PayloadAction<FormData>) {
  const contentForms: ITemplatePayload = yield select((state: RootState) => state.template.control);
  for (const key in contentForms) {
    payload.append(key, contentForms[key]);
  }
  try {
    const fetchResult: string[] = yield call(fetchTemplatePut, payload);
    yield put({ type: templateActions['putTemplate/Ok'].type, payload: fetchResult });
    yield put({ type: templateActions.isCreated.type, payload: true });
  } catch (err) {
    yield put({ type: templateActions['putTemplate/Err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}
function* templateGetAll({ payload }: PayloadAction<TTemplateGetAllPayload>) {
  try {
    const fetchResult: ITemplateGetAllResponseOk = yield call(fetchTemplateGetAll, payload);
    yield put({ type: templateActions['getAllTemplate/Ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: templateActions['getAllTemplate/Err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* templateGet({ payload }: PayloadAction<string>) {
  try {
    const fetchResult: any[] = yield call(fetchTemplateGet, payload);
    yield put({ type: templateActions['getTemplate/Ok'].type, payload: fetchResult });
  } catch (err) {
    yield put({ type: templateActions['getTemplate'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

function* templatePutExternalKey({ payload }: PayloadAction<ITemplatePutExternalKeyPayload>) {
  try {
    const fetchResult: any[] = yield call(fetchTemplatePutExternalKey, payload);
    yield put({ type: templateActions['putTemplateExternalKey/Ok'].type, payload: fetchResult });
    yield put({ type: templateActions.isUpdatedExternalKey.type, payload: true });
  } catch (err) {
    yield put({ type: templateActions['putTemplateExternalKey/Err'].type, payload: err });
    yield put({ type: notificationActions.error.type, payload: err });
  }
}

export function* watchTemplate() {
  yield takeLatest(templateActions.putTemplate, templatePutData);
  yield takeLatest(templateActions.getAllTemplate, templateGetAll);
  yield takeLatest(templateActions.getTemplate, templateGet);
  yield takeLatest(templateActions.putTemplateExternalKey, templatePutExternalKey);
}
